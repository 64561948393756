// src/components/Admin/Users.js
import React, { useState, useEffect, useRef } from 'react';
import api from '../../../api';
import { useUser } from '../../context';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow } from 'date-fns';
import { useSocket } from '../../../SocketContext';

const Users = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const socket = useSocket();

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('email');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // Ban/unban Modal
  const [showBanModal, setShowBanModal] = useState(false);
  const [userToBan, setUserToBan] = useState(null);
  const [isBanning, setIsBanning] = useState(false);

  // Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Block Access (Chain) Modal
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [blockInfo, setBlockInfo] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);

  // "Grant Immunity" (remove from block) Modal
  const [showImmunityModal, setShowImmunityModal] = useState(false);
  const [userToImmunize, setUserToImmunize] = useState(null);
  const [isGrantingImmunity, setIsGrantingImmunity] = useState(false);

  const previousOnlineStatus = useRef({});

  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchType, searchTerm]);

  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Slide,
    icon: false,
  };

  // Online/Offline toasts
  const showOnlineToast = (userName) => {
    toast(
      <div className="flex items-center">
        <div className="flex-shrink-0 h-8 w-8 rounded-full bg-emerald-100 flex items-center justify-center mr-3">
          <FaCheckCircle className="text-emerald-500" size={20} />
        </div>
        <div>
          <p className="font-semibold text-emerald-700">{userName} is now online</p>
        </div>
      </div>,
      {
        ...toastOptions,
        className: 'border border-emerald-500 bg-emerald-50 rounded-full animate__animated animate__fadeInDown',
      }
    );
  };

  const showOfflineToast = (userName) => {
    toast(
      <div className="flex items-center">
        <div className="flex-shrink-0 h-8 w-8 rounded-full bg-red-100 flex items-center justify-center mr-3">
          <FaTimesCircle className="text-red-500" size={20} />
        </div>
        <div>
          <p className="font-semibold text-red-700">{userName} is now offline</p>
        </div>
      </div>,
      {
        ...toastOptions,
        className: 'border border-red-500 bg-red-50 rounded-full animate__animated animate__fadeInDown',
      }
    );
  };

  // Listen for real-time user status updates
  useEffect(() => {
    if (socket) {
      socket.on('user-status-update', (update) => {
        const previousIsOnline = previousOnlineStatus.current[update.userId];
        previousOnlineStatus.current[update.userId] = update.isOnline;

        if (previousIsOnline === undefined || update.isOnline !== previousIsOnline) {
          if (update.isOnline) {
            showOnlineToast(update.firstName || 'A user');
          } else {
            showOfflineToast(update.firstName || 'A user');
          }
        }

        setUsers((prev) => {
          const idx = prev.findIndex((u) => u._id === update.userId);
          if (idx !== -1) {
            const updatedUser = {
              ...prev[idx],
              isOnline: update.isOnline,
              lastActive: update.lastActive,
              firstName: update.firstName || prev[idx].firstName,
            };
            const newArr = [...prev];
            newArr[idx] = updatedUser;
            return newArr;
          } else {
            return prev;
          }
        });
      });

      return () => {
        socket.off('user-status-update');
      };
    }
  }, [socket]);

  // Fetch all users
  const fetchUsers = async () => {
    const queryParams = { page: currentPage, limit: usersPerPage };
    if (searchTerm) queryParams[searchType] = searchTerm;

    try {
      const response = await api.get('/api/admin/users', {
        params: queryParams,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setUsers(response.data.users);
      const totalItems = response.data.total;
      setTotalPages(Math.ceil(totalItems / usersPerPage));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Searching
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleSearchTypeChange = (e) => setSearchType(e.target.value);
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchUsers();
  };

  // Pagination
  const getPagination = () => {
    const delta = 1;
    const range = [];
    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(totalPages - 1, currentPage + delta);
      i++
    ) {
      range.push(i);
    }
    if (currentPage - delta > 2) {
      range.unshift('...');
    }
    if (currentPage + delta < totalPages - 1) {
      range.push('...');
    }
    range.unshift(1);
    if (totalPages !== 1) range.push(totalPages);

    return range.map((page, idx) =>
      page === '...' ? (
        <span key={idx} className="px-2">...</span>
      ) : (
        <button
          key={idx}
          onClick={() => setCurrentPage(page)}
          className={`px-2 py-1 ${
            page === currentPage ? 'bg-emerald-500 text-white' : 'bg-gray-200'
          }`}
        >
          {page}
        </button>
      )
    );
  };

  // Render user status
  const renderStatus = (usr) => {
    if (usr.isOnline) {
      return <span className="text-green-500">Online</span>;
    } else {
      const lastSeen = usr.lastActive || usr.lastLogin;
      if (lastSeen) {
        const dateVal = new Date(lastSeen);
        if (!isNaN(dateVal)) {
          return (
            <span className="text-gray-500">
              Last active: {formatDistanceToNow(dateVal, { addSuffix: true })}
            </span>
          );
        }
      }
      return <span className="text-gray-500">Unknown</span>;
    }
  };

  // Ban / Unban
  const handleBanToggle = (usr) => {
    setUserToBan(usr);
    setShowBanModal(true);
  };
  const confirmBan = async () => {
    if (!userToBan) return;
    setIsBanning(true);
    try {
      await api.patch(`/api/admin/${userToBan._id}/toggleBan`, {}, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      toast.success(`User ${userToBan.isBanned ? 'unbanned' : 'banned'} successfully`);
      setShowBanModal(false);
      fetchUsers();
    } catch (error) {
      const message = error.response?.data?.message || "Failed to toggle ban status";
      toast.error(message);
    } finally {
      setIsBanning(false);
    }
  };

  // Delete
  const handleDeleteUserClick = (usr) => {
    setUserToDelete(usr);
    setShowDeleteModal(true);
  };
  const confirmDelete = async () => {
    if (!userToDelete) return;
    setIsDeleting(true);
    try {
      await api.delete(`/api/admin/delete-user/${userToDelete._id}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      toast.success("User deleted successfully");
      setShowDeleteModal(false);
      fetchUsers();
    } catch (error) {
      toast.error("Failed to delete user");
    } finally {
      setIsDeleting(false);
    }
  };

  // Block Access (Chain)
  const handleOpenBlockModal = async (usr) => {
    try {
      const { data } = await api.get(`/api/admin/block-info/${usr._id}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setBlockInfo(data);
      setShowBlockModal(true);
    } catch (err) {
      console.error('Error fetching blockable info:', err);
      toast.error('Failed to fetch blockable info.');
    }
  };
  const handleConfirmBlock = async () => {
    if (!blockInfo) return;
    if (!blockInfo.fingerprintId) {
      toast.error('No fingerprint found. Cannot chain-block this user.');
      return;
    }
    setIsBlocking(true);

    let loc = null;
    if (blockInfo.lastLocation) {
      loc = {
        longitude: blockInfo.lastLocation.longitude,
        latitude: blockInfo.lastLocation.latitude,
      };
    }

    try {
      const { data } = await api.post(
        `/api/admin/block-user/${blockInfo.userId}`,
        { fingerprintId: blockInfo.fingerprintId, location: loc },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      toast.success(data.message);
      setShowBlockModal(false);
      fetchUsers();
    } catch (err) {
      console.error(err);
      const msg = err.response?.data?.message || 'Failed to block user.';
      toast.error(msg);
    } finally {
      setIsBlocking(false);
    }
  };

  // ============ Grant Immunity / Remove from block =============
  const handleRemoveBlock = (usr) => {
    setUserToImmunize(usr);
    setShowImmunityModal(true);
  };

  const confirmImmunity = async () => {
    if (!userToImmunize) return;
    setIsGrantingImmunity(true);
    try {
      // e.g. POST /api/admin/grant-immunity/:userId
      const { data } = await api.post(
        `/api/admin/grant-immunity/${userToImmunize._id}`,
        {},
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      toast.success(data.message);
      setShowImmunityModal(false);
      fetchUsers();
    } catch (error) {
      const msg = error.response?.data?.message || 'Failed to grant immunity.';
      toast.error(msg);
    } finally {
      setIsGrantingImmunity(false);
    }
  };

  return (
    <div className="container mx-auto px-4 pb-20">
      <ToastContainer />

      {/* Search form */}
      <form
        onSubmit={handleSearch}
        className="mb-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2"
      >
        <select
          value={searchType}
          onChange={handleSearchTypeChange}
          className="border p-2 rounded text-xs"
        >
          <option value="email">Email</option>
          <option value="payId">Pay ID</option>
          <option value="phoneNumber">Phone Number</option>
        </select>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search..."
          className="border p-2 rounded text-xs flex-grow"
        />
        <button
          type="submit"
          className="border p-2 rounded bg-emerald-500 text-white text-xs"
        >
          Search
        </button>
      </form>

      {/* Users table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse text-xs">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Pay ID</th>
              <th className="border p-2">Email</th>
              <th className="border p-2">Phone Number</th>
              <th className="border p-2 text-center">Status</th>
              <th className="border p-2 text-center">Accounts</th>
              <th className="border p-2 text-center">Toggle Ban</th>
              <th className="border p-2 text-center">Block / Remove</th>
              <th className="border p-2 text-center">View More</th>
              <th className="border p-2 text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((usr) => (
                <tr key={usr._id}>
                  <td className="border p-2">{usr.payId}</td>
                  <td className="border p-2">
                    {usr.email}{' '}
                    {usr.isVerified && (
                      <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} />
                    )}
                  </td>
                  <td className="border p-2">
                    {usr.phoneNumber}{' '}
                    {usr.isPhoneVerified && (
                      <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} />
                    )}
                  </td>
                  <td className="border p-2 text-center">{renderStatus(usr)}</td>
                  <td className="border p-2 text-center">
                    {usr.associatedAccountsCount}
                  </td>

                  {/* Ban / Unban */}
                  <td className="border p-2 text-center">
                    <button
                      onClick={() => handleBanToggle(usr)}
                      className={`px-4 py-2 text-white rounded text-xs ${
                        usr.isBanned ? 'bg-red-500' : 'bg-emerald-500'
                      }`}
                    >
                      {usr.isBanned ? 'Unban' : 'Ban'}
                    </button>
                  </td>

                  {/* If user.accessBlocked => show "Remove" button, else "Block" */}
                  <td className="border p-2 text-center">
                    {usr.accessBlocked ? (
                      <button
                        onClick={() => handleRemoveBlock(usr)}
                        className="px-4 py-2 bg-orange-400 text-white rounded text-xs hover:bg-orange-500"
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        onClick={() => handleOpenBlockModal(usr)}
                        className="px-4 py-2 bg-red-500 text-white rounded text-xs hover:bg-red-600"
                      >
                        Block
                      </button>
                    )}
                  </td>

                  <td className="border p-2 text-center">
                    <button
                      onClick={() => navigate(`/user-details/${usr._id}`)}
                      className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                    >
                      View More
                    </button>
                  </td>
                  <td className="border p-2 text-center">
                    <button
                      onClick={() => handleDeleteUserClick(usr)}
                      className="px-4 py-2 text-white rounded text-xs bg-red-600 hover:bg-red-700"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border p-2 text-center" colSpan={9}>
                  User not found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-center space-x-2 mt-4">
          {getPagination()}
        </div>
      </div>

      {/* Ban Modal */}
      {showBanModal && userToBan && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-lg font-semibold">
              Confirm {userToBan.isBanned ? 'Unban' : 'Ban'}
            </h2>
            <p className="my-4 text-sm">
              Are you sure you want to{' '}
              <strong>{userToBan.isBanned ? 'unban' : 'ban'}</strong>{' '}
              <em>{userToBan.email}</em>?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowBanModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 text-xs"
                disabled={isBanning}
              >
                Cancel
              </button>
              <button
                onClick={confirmBan}
                className={`px-4 py-2 text-white rounded text-xs ${
                  isBanning ? 'bg-red-300 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'
                }`}
                disabled={isBanning}
              >
                {isBanning ? (
                  <div className="flex items-center">
                    <FaSpinner className="animate-spin mr-1" /> Submitting...
                  </div>
                ) : (
                  'Confirm'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {showDeleteModal && userToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-lg font-semibold">Confirm Delete</h2>
            <p className="my-4 text-sm">
              Are you sure you want to delete <em>{userToDelete.email}</em>?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 text-xs"
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className={`px-4 py-2 text-white rounded text-xs ${
                  isDeleting ? 'bg-red-300 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'
                }`}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <div className="flex items-center">
                    <FaSpinner className="animate-spin mr-1" /> Submitting...
                  </div>
                ) : (
                  'Confirm'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Block Access Modal */}
      {showBlockModal && blockInfo && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-lg font-semibold text-red-600">Block Access (Chain)</h2>
            <p className="text-sm mt-2 text-gray-700">
              You are about to block <strong>{blockInfo.email}</strong>{" "}
              (phone: <strong>{blockInfo.phone}</strong>)
              from accessing the system, <em>plus</em> any other user that shares their phone,
              email, or fingerprint <em>or</em> is within 20 meters (based on last login).
            </p>
            {blockInfo.alreadyBlocked && (
              <p className="text-red-500 text-xs mt-2">
                This user is already blocked, but you can re-block their chain if needed.
              </p>
            )}
            {!blockInfo.fingerprintId && (
              <p className="text-red-500 text-xs mt-2">
                No fingerprint found. Cannot chain-block this user.
              </p>
            )}
            <div className="mt-4 text-xs text-gray-600">
              <p>
                <strong>Fingerprint:</strong> {blockInfo.fingerprintId || 'N/A'}
              </p>
              <p>
                <strong>Last location:</strong>{" "}
                {blockInfo.lastLocation
                  ? `Lng: ${blockInfo.lastLocation.longitude}, Lat: ${blockInfo.lastLocation.latitude}`
                  : 'None or 0.00'}
              </p>
            </div>
            {blockInfo.associatedUsers && blockInfo.associatedUsers.length > 0 && (
              <div className="mt-3 bg-yellow-50 border border-yellow-200 p-2 rounded text-xs">
                <strong className="block mb-1">Also potentially blocking:</strong>
                <ul className="list-disc list-inside">
                  {blockInfo.associatedUsers.map((aUser) => (
                    <li key={aUser._id} className="mb-1">
                      <strong>Email:</strong> {aUser.email}
                      <span className="mx-1">|</span>
                      <strong>Phone:</strong> {aUser.phoneNumber}
                      <span className="mx-1">|</span>
                      <strong>PayID:</strong> {aUser.payId}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={() => setShowBlockModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 text-xs"
                disabled={isBlocking}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmBlock}
                className={`px-4 py-2 bg-red-500 text-white rounded text-xs hover:bg-red-600 ${
                  !blockInfo.fingerprintId ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!blockInfo.fingerprintId || isBlocking}
              >
                {isBlocking ? (
                  <div className="flex items-center">
                    <FaSpinner className="animate-spin mr-1" /> Blocking...
                  </div>
                ) : (
                  'Confirm Block'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Grant Immunity (Remove block) Modal */}
      {showImmunityModal && userToImmunize && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-lg font-semibold text-emerald-700">Remove From Block Chain</h2>
            <p className="my-4 text-sm">
              You are about to permanently grant immunity to <strong>{userToImmunize.email}</strong>.
              <br />
              This will set <em>accessBlocked = false</em> and <em>immunity = true</em>, so they are no longer automatically blocked in future checks.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowImmunityModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 text-xs"
                disabled={isGrantingImmunity}
              >
                Cancel
              </button>
              <button
                onClick={confirmImmunity}
                className={`px-4 py-2 text-white rounded text-xs ${
                  isGrantingImmunity ? 'bg-emerald-300 cursor-not-allowed' : 'bg-emerald-500 hover:bg-emerald-600'
                }`}
                disabled={isGrantingImmunity}
              >
                {isGrantingImmunity ? (
                  <div className="flex items-center">
                    <FaSpinner className="animate-spin mr-1" /> Submitting...
                  </div>
                ) : (
                  'Confirm'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
