// ProfileBadge.jsx
import React from 'react';

const ProfileBadge = ({ className = 'w-6 h-6', fill = '#6366F1' }) => (
  <svg className={className} viewBox="0 0 64 64" fill="none">
    <circle cx="32" cy="32" r="24" fill={fill} />
    <circle cx="32" cy="24" r="6" fill="#E0E7FF" />
    <path
      d="M40 38H24C24 42 26 44 32 44C38 44 40 42 40 38Z"
      fill="#E0E7FF"
    />
    <path
      d="M32 44L36 52H28L32 44Z"
      fill="#A5B4FC"
    />
  </svg>
);

export default ProfileBadge;