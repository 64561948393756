// src/components/crowdfund/MyCampaignEdit.jsx
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaSave, FaTimesCircle } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../api";
import { useUser } from "../context";

const MyCampaignEdit = ({ campaignId, onCancel }) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // Validation errors for each field
  const [fieldErrors, setFieldErrors] = useState({});

  // Fields to edit (non-media)
  const [campaignTitle, setCampaignTitle] = useState("");
  const [campaignType, setCampaignType] = useState("reward-based");
  const [fundingGoal, setFundingGoal] = useState(1000);
  const [currency, setCurrency] = useState("USD"); // Not editable
  const [fundingModel, setFundingModel] = useState("flexible");
  const [equityOffered, setEquityOffered] = useState(0);
  const [minInvestment, setMinInvestment] = useState(0);
  const [maxInvestment, setMaxInvestment] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pitchDeckUrl, setPitchDeckUrl] = useState("");
  const [promoVideoUrl, setPromoVideoUrl] = useState("");
  const [tags, setTags] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setLoading(true);
        setError("");
        setSuccessMsg("");
        const res = await api.get(`/api/crowdfund/campaigns/${campaignId}`);
        if (res.data.success) {
          const c = res.data.data;
          setCampaignTitle(c.campaignTitle || "");
          setCampaignType(c.campaignType || "reward-based");
          setFundingGoal(c.fundingGoal || 1000);
          setCurrency(c.currency || "USD"); // always set to USD, not editable
          setFundingModel(c.fundingModel || "flexible");
          setEquityOffered(c.equityOffered || 0);
          setMinInvestment(c.minInvestment || 0);
          setMaxInvestment(c.maxInvestment || 0);
          setStartDate(c.startDate ? c.startDate.substring(0, 10) : "");
          setEndDate(c.endDate ? c.endDate.substring(0, 10) : "");
          setPitchDeckUrl(c.pitchDeckUrl || "");
          setPromoVideoUrl(c.promoVideoUrl || "");
          setTags(c.tags || "");
          setCampaignDescription(c.campaignDescription || "");
        } else {
          setError("Failed to load campaign data.");
        }
      } catch (err) {
        console.error("Error fetching campaign:", err);
        setError("An error occurred while loading the campaign.");
      } finally {
        setLoading(false);
      }
    };

    if (campaignId) fetchCampaign();
  }, [campaignId]);

  // Validation check on submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMsg("");
    setFieldErrors({});
  
    const errors = {};
  
    // Required fields validation
    if (!campaignTitle.trim()) {
      errors.campaignTitle = "Title is required.";
    }
    if (!fundingGoal || fundingGoal < 1) {
      errors.fundingGoal = "Goal must be at least 1.";
    }
    if (!endDate) {
      errors.endDate = "End date is required.";
    }
  
    // If errors exist, display them and stop submission
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
  
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
  
      const payload = {
        campaignTitle,
        campaignDescription,
        campaignType,
        fundingGoal,
        // Currency remains unchanged
        fundingModel,
        equityOffered,
        minInvestment,
        maxInvestment,
        startDate,
        endDate,
        pitchDeckUrl,
        promoVideoUrl,
        tags,
      };
  
      const res = await api.put(
        `/api/crowdfund/campaigns/${campaignId}`,
        payload,
        config
      );
  
      if (res.data.success) {
        setSuccessMsg("Campaign updated successfully!");
        setTimeout(() => {
          onCancel();
        }, 150);
      } else {
        setError(res.data.message || "Failed to update campaign.");
      }
    } catch (err) {
      console.error("Error updating campaign:", err);
      setError(
        err.response?.data?.message || "An error occurred while updating the campaign."
      );
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="max-w-xl mx-auto p-4 border border-gray-200 bg-white rounded-md shadow relative">
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={onCancel}
          className="text-sm text-gray-500 hover:text-gray-700 inline-flex items-center"
        >
          <FaArrowLeft className="mr-1" />
          Back to List
        </button>
        <h2 className="text-lg font-bold text-gray-800">Edit Campaign</h2>
      </div>

      {/* Yellow alert about media editing */}
      <div className="bg-yellow-100 border-l-4 border-yellow-300 text-yellow-700 p-2 rounded text-sm mb-4">
        If you were looking to edit the cover photo or gallery images, please open
        the campaign in <strong>view mode</strong> and click on the picture you
        wish to edit.
      </div>

      {loading && <p className="text-center text-gray-500 mb-4">Loading...</p>}

      {/* Error alert */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 p-2 rounded mb-4 text-sm">
          <FaTimesCircle className="inline mr-1" />
          {error}
        </div>
      )}

      {/* Success alert */}
      {successMsg && (
        <div className="bg-emerald-100 border border-emerald-400 text-emerald-700 p-2 rounded mb-4 text-sm">
          {successMsg}
        </div>
      )}

      {/* Form */}
      {!loading && !error && (
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Campaign Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Campaign Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={campaignTitle}
              onChange={(e) => setCampaignTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm"
            />
            {fieldErrors.campaignTitle && (
              <span className="text-xs text-red-500">
                {fieldErrors.campaignTitle}
              </span>
            )}
          </div>

          {/* Campaign Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Campaign Type
            </label>
            <select
              value={campaignType}
              onChange={(e) => setCampaignType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm"
            >
              <option value="reward-based">Reward-based</option>
              <option value="equity-based">Equity-based</option>
              <option value="donation-based">Donation-based</option>
            </select>
          </div>

          {/* Funding Goal & Currency (Read-Only) */}
          <div className="flex gap-2">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Funding Goal <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                min="1"
                value={fundingGoal}
                onChange={(e) => setFundingGoal(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded text-sm"
              />
              {fieldErrors.fundingGoal && (
                <span className="text-xs text-red-500">
                  {fieldErrors.fundingGoal}
                </span>
              )}
            </div>
            <div className="w-32">
              <label className="block text-sm font-medium text-gray-700">
                Currency
              </label>
              <input
                type="text"
                value={currency}
                readOnly
                className="w-full p-2 border border-gray-300 rounded text-sm bg-gray-100 text-gray-500"
              />
            </div>
          </div>

          {/* Funding Model */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Funding Model
            </label>
            <select
              value={fundingModel}
              onChange={(e) => setFundingModel(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm"
            >
              <option value="flexible">Flexible</option>
              <option value="all-or-nothing">All or Nothing</option>
            </select>
          </div>

          {/* Equity-based fields */}
          {campaignType === "equity-based" && (
            <div className="flex flex-wrap gap-2">
              <div className="flex-1 min-w-[100px]">
                <label className="block text-sm font-medium text-gray-700">
                  Equity Offered (%)
                </label>
                <input
                  type="number"
                  value={equityOffered}
                  onChange={(e) => setEquityOffered(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                />
              </div>
              <div className="flex-1 min-w-[100px]">
                <label className="block text-sm font-medium text-gray-700">
                  Min Investment
                </label>
                <input
                  type="number"
                  value={minInvestment}
                  onChange={(e) => setMinInvestment(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                />
              </div>
              <div className="flex-1 min-w-[100px]">
                <label className="block text-sm font-medium text-gray-700">
                  Max Investment
                </label>
                <input
                  type="number"
                  value={maxInvestment}
                  onChange={(e) => setMaxInvestment(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                />
              </div>
            </div>
          )}

          {/* Start / End date */}
          <div className="flex gap-2">
            <div className="flex-1 min-w-[100px]">
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded text-sm"
              />
            </div>
            <div className="flex-1 min-w-[100px]">
              <label className="block text-sm font-medium text-gray-700">
                End Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded text-sm"
              />
              {fieldErrors.endDate && (
                <span className="text-xs text-red-500">
                  {fieldErrors.endDate}
                </span>
              )}
            </div>
          </div>

          {/* Pitch Deck / Promo Video */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Pitch Deck URL
            </label>
            <input
              type="text"
              value={pitchDeckUrl}
              onChange={(e) => setPitchDeckUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Promo Video URL
            </label>
            <input
              type="text"
              value={promoVideoUrl}
              onChange={(e) => setPromoVideoUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm"
            />
          </div>

          {/* Tags */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Tags (comma separated)
            </label>
            <input
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-sm"
            />
          </div>

          {/* Detailed Description with ReactQuill */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Detailed Description
            </label>
            <div className="border border-gray-300 rounded p-1 mb-1">
              <ReactQuill
                theme="snow"
                value={campaignDescription}
                onChange={setCampaignDescription}
                style={{ minHeight: "150px" }}
              />
            </div>
            <span className="text-xs text-gray-500">
              Provide a rich, detailed narrative about your campaign.
            </span>
          </div>

          {/* Submit / Cancel */}
          <div className="mt-4 flex justify-end gap-2">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 text-gray-600 rounded-md border"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm bg-emerald-600 hover:bg-emerald-700 text-white rounded-md border border-emerald-700 disabled:opacity-50"
            >
              <FaSave className="inline-block mr-1" />
              {loading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default MyCampaignEdit;
