import React, { useEffect, useState, memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    FaArrowLeft,
    FaArrowRight,
    FaSave,
    FaSpinner,
    FaInfoCircle,
    FaTimesCircle,
} from 'react-icons/fa';

import FileUploadWithPreview from './FileUploadWithPreview';
import api from '../../api';
import { useUser } from '../context'; // For user auth, if needed

// Step titles
const stepsTitles = [
    'Intro',
    'Select Business',
    'Campaign Basics',
    'Funding & Timeline',
    'Media Uploads',
    'Review & Submit',
];

// Default blank form
const initialForm = {
    businessProfile: '', // The selected business _id
    campaignTitle: '',
    campaignDescription: '',
    campaignType: 'reward-based',
    fundingGoal: 1000,
    currency: 'USD',
    fundingModel: 'flexible',
    equityOffered: 0,
    minInvestment: 0,
    maxInvestment: 0,
    startDate: '',
    endDate: '',
    pitchDeckUrl: '',
    promoVideoUrl: '',
    tags: '',
};

/**
 * Step Indicator - Memoized to avoid frequent re-renders
 */
const StepIndicator = memo(function StepIndicator({ step }) {
    return (
        <div className="mb-6 overflow-x-auto flex items-center justify-center">
            <div className="flex items-center justify-center min-w-[500px]">
                {stepsTitles.map((title, idx) => (
                    <div key={idx} className="flex items-center">
                        <div
                            className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center text-xs sm:text-sm font-bold
                ${step === idx
                                    ? 'bg-emerald-400 text-white border border-emerald-500'
                                    : idx < step
                                        ? 'bg-emerald-100 text-emerald-700 border border-emerald-200'
                                        : 'bg-gray-200 text-gray-600 border border-gray-300'
                                }`}
                        >
                            {idx + 1}
                        </div>
                        {idx < stepsTitles.length - 1 && (
                            <div className="w-6 sm:w-8 h-[2px] bg-gray-200 mx-1 sm:mx-2" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});

/**
 * Container - Memoized to avoid frequent re-renders
 */
const Container = memo(function Container({ step, children }) {
    return (
        <div className="max-w-3xl mx-auto mt-6 bg-white border border-emerald-200 rounded-md shadow-md p-6">
            <StepIndicator step={step} />
            {children}
        </div>
    );
});

const CampaignWizard = ({ existingCampaign, onCancel, onSuccess }) => {
    const { user } = useUser();
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState(initialForm);
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    // For file uploads
    const [coverImageFiles, setCoverImageFiles] = useState([]);
    const [galleryImagesFiles, setGalleryImagesFiles] = useState([]);

    // For the dropdown: the user’s businesses
    const [myBusinesses, setMyBusinesses] = useState([]);
    const [loadingBusinesses, setLoadingBusinesses] = useState(false);

    // --- Fetch user businesses once ---
    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                setLoadingBusinesses(true);
                const res = await api.get(`/api/crowdfund/business/user/${user._id}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                setMyBusinesses(res.data.data || []);
            } catch (error) {
                console.error('Failed to fetch businesses', error);
            } finally {
                setLoadingBusinesses(false);
            }
        };
        fetchBusinesses();
    }, [user]);

    // --- If editing an existing campaign ---
    useEffect(() => {
        if (existingCampaign) {
            setFormData({
                businessProfile: existingCampaign.businessProfile?._id || '',
                campaignTitle: existingCampaign.campaignTitle || '',
                campaignDescription: existingCampaign.campaignDescription || '',
                campaignType: existingCampaign.campaignType || 'reward-based',
                fundingGoal: existingCampaign.fundingGoal || 1000,
                currency: existingCampaign.currency || 'USD',
                fundingModel: existingCampaign.fundingModel || 'flexible',
                equityOffered: existingCampaign.equityOffered || 0,
                minInvestment: existingCampaign.minInvestment || 0,
                maxInvestment: existingCampaign.maxInvestment || 0,
                startDate: existingCampaign.startDate
                    ? new Date(existingCampaign.startDate).toISOString().split('T')[0]
                    : '',
                endDate: existingCampaign.endDate
                    ? new Date(existingCampaign.endDate).toISOString().split('T')[0]
                    : '',
                pitchDeckUrl: existingCampaign.pitchDeckUrl || '',
                promoVideoUrl: existingCampaign.promoVideoUrl || '',
                tags: (existingCampaign.tags || []).join(', '),
            });
            // For images, you'd have to handle them separately if you want to display existing files
        }
    }, [existingCampaign]);

    // --- Update form field helper ---
    const updateField = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    // --- Validate steps 1..3 ---
    const validateStep = (idx) => {
        const newErrors = {};

        // Step 1: Must select business
        if (idx === 1) {
            if (!formData.businessProfile) {
                newErrors.businessProfile = 'Please select a business.';
            }
        }

        // Step 2: Title & Description
        if (idx === 2) {
            if (!formData.campaignTitle.trim()) {
                newErrors.campaignTitle = 'Campaign title is required.';
            }
            if (!formData.campaignDescription.trim()) {
                newErrors.campaignDescription = 'Campaign description is required.';
            }
        }

        // Step 3: Funding & timeline
        if (idx === 3) {
            if (Number(formData.fundingGoal) < 1) {
                newErrors.fundingGoal = 'Funding goal must be at least 1.';
            }
            if (!formData.endDate) {
                newErrors.endDate = 'An end date is required.';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // --- Navigation (Next / Prev) ---
    const handleNext = () => {
        // Validate certain steps
        if ([1, 2, 3].includes(step)) {
            if (!validateStep(step)) return;
        }
        setStep((s) => s + 1);
    };

    const handlePrev = () => {
        setStep((s) => s - 1);
    };

    // --- Date Constraints ---
    // Start Date cannot be in the past: its minimum is today.
    const today = new Date();
    const todayStr = today.toISOString().split("T")[0];

    // End Date must be at least 7 days after the Start Date.
    // If no Start Date is provided, default to 7 days from today.
    const minEndDate = formData.startDate
        ? (() => {
              const start = new Date(formData.startDate);
              start.setDate(start.getDate() + 7);
              return start.toISOString().split("T")[0];
          })()
        : (() => {
              const d = new Date();
              d.setDate(d.getDate() + 7);
              return d.toISOString().split("T")[0];
          })();

    const handleSubmit = async () => {
        setSubmitting(true);
        setErrors({});

        try {
            // Build FormData
            const dataToSend = new FormData();
            dataToSend.append('businessProfile', formData.businessProfile);
            dataToSend.append('campaignTitle', formData.campaignTitle);
            dataToSend.append('campaignDescription', formData.campaignDescription);
            dataToSend.append('campaignType', formData.campaignType);
            dataToSend.append('fundingGoal', formData.fundingGoal);
            dataToSend.append('currency', formData.currency);
            dataToSend.append('fundingModel', formData.fundingModel);
            dataToSend.append('equityOffered', formData.equityOffered);
            dataToSend.append('minInvestment', formData.minInvestment);
            dataToSend.append('maxInvestment', formData.maxInvestment);
            dataToSend.append('startDate', formData.startDate || '');
            dataToSend.append('endDate', formData.endDate);
            dataToSend.append('pitchDeckUrl', formData.pitchDeckUrl);
            dataToSend.append('promoVideoUrl', formData.promoVideoUrl);

            // Convert tags to a comma-separated string
            const tagString = formData.tags
                .split(',')
                .map((t) => t.trim())
                .filter(Boolean)
                .join(',');
            dataToSend.append('tags', tagString);

            // Cover image
            if (coverImageFiles.length > 0) {
                dataToSend.append('coverImage', coverImageFiles[0]);
            }
            // Gallery images
            galleryImagesFiles.forEach((file) => {
                dataToSend.append('images', file);
            });

            // POST request
            const endpoint = '/api/crowdfund/campaigns/campaign-create';
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await api.post(endpoint, dataToSend, config);

            // If success => call onSuccess
            if (response.data.success) {
                onSuccess && onSuccess(response.data.data);
            } else {
                throw new Error(response.data.message || 'Failed to create campaign.');
            }
        } catch (error) {
            console.error('❌ Campaign submit error:', error);

            let userFriendlyMessage = 'Something went wrong. Please try again.';
            if (error.response?.data?.message) {
                userFriendlyMessage = error.response.data.message;
            }
            setErrors({ submitError: userFriendlyMessage });
        } finally {
            setSubmitting(false);
        }
    };

    // --- Renders Steps ---
    // Step 0: Intro / Motivational
    if (step === 0) {
        return (
            <div className="max-w-2xl mx-auto mt-6 bg-white border border-emerald-200 rounded-md p-6 shadow-lg">
                <div className="flex items-center mb-4 text-emerald-700">
                    <FaInfoCircle className="mr-2 text-xl" />
                    <h2 className="text-lg font-bold">🚀 Ready to Launch an Amazing Campaign?</h2>
                </div>

                <p className="text-sm text-gray-700 leading-relaxed mb-4">
                    You’re about to create a <strong>crowdfunding campaign</strong> that could inspire
                    people to support your cause or business! But to truly stand out, your campaign
                    needs to tell a <strong>compelling story</strong>.
                </p>

                <div className="bg-emerald-50 border-l-4 border-emerald-400 p-4 mb-4 text-xs text-emerald-700">
                    <p className="font-semibold">💡 Pro Tip: The Best Campaigns Have</p>
                    <ul className="list-disc list-inside mt-2 space-y-1">
                        <li>
                            <strong>A Captivating Title:</strong> Keep it short, powerful, and exciting.
                        </li>
                        <li>
                            <strong>A Persuasive Description:</strong> Tell people <em>why</em> your campaign
                            matters. How does it help? Who benefits? What impact will it make?
                        </li>
                        <li>
                            <strong>High-Quality Media:</strong> People connect with visuals—show them
                            something inspiring.
                        </li>
                        <li>
                            <strong>A Clear Funding Goal:</strong> Let supporters know exactly what their
                            contributions will achieve.
                        </li>
                    </ul>
                </div>

                <p className="text-xs text-gray-700 leading-relaxed mb-4">
                    Your campaign is your pitch! Think of it as your opportunity to <strong>inspire,
                        engage, and persuade</strong>. Whether you're launching a <strong>business venture</strong>,
                    funding a community project, or supporting a cause that matters, make sure it’s clear,
                    passionate, and goal-oriented.
                </p>

                <p className="text-sm text-gray-700 leading-relaxed mb-4">
                    Remember, you can only create <strong>one active campaign</strong> at a time, so make
                    it count!
                </p>

                <div className="flex items-center gap-3">
                    <button
                        onClick={() => setStep(1)}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-600 hover:bg-emerald-700 text-white border border-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-400 transition-all duration-150"
                    >
                        Let's Build Something Great!
                        <FaArrowRight className="ml-2" />
                    </button>
                    <button
                        onClick={onCancel}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700 text-sm"
                    >
                        <FaTimesCircle className="mr-1" />
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    // Step 1: Select Business
    if (step === 1) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 1: Select Business
                </h2>
                <p className="text-gray-600 text-sm mb-4 text-center">
                    Choose which of your businesses this campaign will be associated with.
                </p>

                <div className="mb-4">
                    {loadingBusinesses ? (
                        <p>Loading your businesses...</p>
                    ) : myBusinesses.length === 0 ? (
                        <p className="text-red-500">
                            You have no businesses. Please create a business profile first.
                        </p>
                    ) : (
                        <>
                            <label
                                htmlFor="businessSelect"
                                className="block mb-1 font-medium text-gray-700"
                            >
                                My Businesses
                            </label>
                            <select
                                id="businessSelect"
                                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                                value={formData.businessProfile}
                                onChange={(e) => updateField('businessProfile', e.target.value)}
                            >
                                <option value="">-- Select a Business --</option>
                                {myBusinesses.map((biz) => (
                                    <option key={biz._id} value={biz._id}>
                                        {biz.businessName}
                                    </option>
                                ))}
                            </select>
                            {errors.businessProfile && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.businessProfile}
                                </p>
                            )}
                            <span className="text-xs text-gray-500 italic">
                                Linking your campaign to the right business helps potential backers trust that
                                you’re authentic.
                            </span>
                        </>
                    )}
                </div>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={() => setStep(0)}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        disabled={myBusinesses.length === 0}
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400 disabled:opacity-50"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 2: Campaign Basics
    if (step === 2) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 2: Campaign Basics
                </h2>

                {/* Campaign Title */}
                <div className="mb-4">
                    <label
                        htmlFor="campaignTitle"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Campaign Title <span className="text-red-500">*</span>
                    </label>
                    <input
                        id="campaignTitle"
                        name="campaignTitle"
                        type="text"
                        placeholder="E.g. 'Launch Our Next Product'"
                        value={formData.campaignTitle}
                        onChange={(e) => updateField('campaignTitle', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    {errors.campaignTitle && (
                        <p className="text-red-600 text-sm mt-1">{errors.campaignTitle}</p>
                    )}
                    <span className="text-xs text-gray-500 italic">
                        A short, punchy title can catch attention and give backers a quick idea of your campaign.
                    </span>
                </div>

                {/* Campaign Type */}
                <div className="mb-4">
                    <label
                        htmlFor="campaignType"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Campaign Type
                    </label>
                    <select
                        id="campaignType"
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        value={formData.campaignType}
                        onChange={(e) => updateField('campaignType', e.target.value)}
                    >
                        <option value="reward-based">Reward-based</option>
                        <option value="equity-based">Equity-based</option>
                        <option value="donation-based">Donation-based</option>
                    </select>
                    <span className="text-xs text-gray-500 italic">
                        Choose <strong>equity-based</strong> if you're offering a stake in your business,
                        <strong>reward-based</strong> for perks, or <strong>donation-based</strong> for
                        charitable/fundraiser style campaigns.
                    </span>
                </div>

                {/* Campaign Description */}
                <div className="mb-4">
                    <label
                        htmlFor="campaignDescription"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Campaign Description <span className="text-red-500">*</span>
                    </label>
                    <div className="border border-gray-200 rounded p-2 mb-2">
                        <ReactQuill
                            id="campaignDescription"
                            theme="snow"
                            value={formData.campaignDescription}
                            onChange={(val) => updateField('campaignDescription', val)}
                            style={{ minHeight: '150px' }}
                        />
                    </div>
                    {errors.campaignDescription && (
                        <p className="text-red-600 text-sm mt-1">
                            {errors.campaignDescription}
                        </p>
                    )}
                    <span className="text-xs text-gray-500 italic">
                        Tell a story! Explain your goals, impact, and how contributions will help. A strong
                        narrative can inspire people to support you.
                    </span>
                </div>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 3: Funding & Timeline
    if (step === 3) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 3: Funding &amp; Timeline
                </h2>

                {/* Funding Goal */}
                <div className="mb-4">
                    <label
                        htmlFor="fundingGoal"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Funding Goal <span className="text-red-500">*</span>
                    </label>
                    <input
                        id="fundingGoal"
                        name="fundingGoal"
                        type="number"
                        min="1"
                        value={formData.fundingGoal}
                        onChange={(e) => updateField('fundingGoal', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    {errors.fundingGoal && (
                        <p className="text-red-600 text-sm mt-1">{errors.fundingGoal}</p>
                    )}
                    <span className="text-xs text-gray-500 italic">
                        How much money do you need? Be realistic yet ambitious. Potential backers want to see
                        goals that are achievable.
                    </span>
                </div>

                {/* Funding Model */}
                <div className="mb-4">
                    <label
                        htmlFor="fundingModel"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Funding Model
                    </label>
                    <select
                        id="fundingModel"
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        value={formData.fundingModel}
                        onChange={(e) => updateField('fundingModel', e.target.value)}
                    >
                        <option value="flexible">Flexible</option>
                        <option value="all-or-nothing">All or Nothing</option>
                    </select>
                    <span className="text-xs text-gray-500 italic">
                        <strong>Flexible</strong> means you’ll keep whatever you raise;
                        <strong>All or Nothing</strong> means you must hit your goal or get nothing.
                    </span>
                </div>

                {/* Equity-based fields */}
                {formData.campaignType === 'equity-based' && (
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label
                                htmlFor="equityOffered"
                                className="block mb-1 font-medium text-gray-700"
                            >
                                Equity Offered (%)
                            </label>
                            <input
                                id="equityOffered"
                                name="equityOffered"
                                type="number"
                                value={formData.equityOffered}
                                onChange={(e) => updateField('equityOffered', e.target.value)}
                                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                            />
                            <span className="text-xs text-gray-500 italic">
                                The percentage of your company you’re offering in exchange for investments.
                            </span>
                        </div>
                        <div>
                            <label
                                htmlFor="minInvestment"
                                className="block mb-1 font-medium text-gray-700"
                            >
                                Min Investment
                            </label>
                            <input
                                id="minInvestment"
                                name="minInvestment"
                                type="number"
                                value={formData.minInvestment}
                                onChange={(e) => updateField('minInvestment', e.target.value)}
                                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                            />
                            <span className="text-xs text-gray-500 italic">
                                The smallest amount someone can invest in your equity campaign.
                            </span>
                        </div>
                        <div>
                            <label
                                htmlFor="maxInvestment"
                                className="block mb-1 font-medium text-gray-700"
                            >
                                Max Investment
                            </label>
                            <input
                                id="maxInvestment"
                                name="maxInvestment"
                                type="number"
                                value={formData.maxInvestment}
                                onChange={(e) => updateField('maxInvestment', e.target.value)}
                                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                            />
                            <span className="text-xs text-gray-500 italic">
                                The largest amount a single backer can contribute. Keep it reasonable to avoid
                                undue concentration.
                            </span>
                        </div>
                    </div>
                )}

                {/* Start/End Date */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label
                            htmlFor="startDate"
                            className="block mb-1 font-medium text-gray-700"
                        >
                            Start Date
                        </label>
                        <input
                            id="startDate"
                            name="startDate"
                            type="date"
                            value={formData.startDate}
                            onChange={(e) => updateField('startDate', e.target.value)}
                            min={todayStr}
                            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                        <span className="text-xs text-gray-500 italic">
                            If left blank, we’ll default to today.
                        </span>
                    </div>
                    <div>
                        <label
                            htmlFor="endDate"
                            className="block mb-1 font-medium text-gray-700"
                        >
                            End Date <span className="text-red-500">*</span>
                        </label>
                        <input
                            id="endDate"
                            name="endDate"
                            type="date"
                            value={formData.endDate}
                            onChange={(e) => updateField('endDate', e.target.value)}
                            min={minEndDate}
                            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                        {errors.endDate && (
                            <p className="text-red-600 text-sm mt-1">{errors.endDate}</p>
                        )}
                        <span className="text-xs text-gray-500 italic">
                            End date must be at least 7 days after the start date.
                        </span>
                    </div>
                </div>

                {/* Pitch Deck & Promo Video */}
                <div className="mb-4">
                    <label
                        htmlFor="pitchDeckUrl"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Pitch Deck URL <span className="text-xs text-gray-400">(optional)</span>
                    </label>
                    <input
                        id="pitchDeckUrl"
                        name="pitchDeckUrl"
                        type="url"
                        placeholder="https://example.com/pitch.pdf"
                        value={formData.pitchDeckUrl}
                        onChange={(e) => updateField('pitchDeckUrl', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    <span className="text-xs text-gray-500 italic">
                        A pitch deck can help investors quickly understand your plan. PDFs or Google Slides
                        are common.
                    </span>
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="promoVideoUrl"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Promo Video URL <span className="text-xs text-gray-400">(optional)</span>
                    </label>
                    <input
                        id="promoVideoUrl"
                        name="promoVideoUrl"
                        type="url"
                        placeholder="https://youtube.com/..."
                        value={formData.promoVideoUrl}
                        onChange={(e) => updateField('promoVideoUrl', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    <span className="text-xs text-gray-500 italic">
                        Videos often boost engagement. Consider uploading to YouTube or Vimeo for easy sharing.
                    </span>
                </div>

                {/* Tags */}
                <div className="mb-4">
                    <label
                        htmlFor="tags"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Tags (comma-separated)
                    </label>
                    <input
                        id="tags"
                        name="tags"
                        type="text"
                        placeholder="e.g. tech, hardware"
                        value={formData.tags}
                        onChange={(e) => updateField('tags', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    <span className="text-xs text-gray-500 italic">
                        Tags help people discover campaigns in their interest areas (e.g., <em>tech</em>).
                    </span>
                </div>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 4: Media Uploads
    if (step === 4) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 4: Media Uploads
                </h2>
                <p className="text-sm text-gray-500 mb-4 text-center">
                    Upload a cover image plus additional gallery images (up to 5).
                </p>
                <FileUploadWithPreview
                    label="Cover Image"
                    accept="image/*"
                    multiple={false}
                    files={coverImageFiles}
                    setFiles={setCoverImageFiles}
                />
                <span className="text-xs text-gray-500 italic block mb-4">
                    A captivating cover image is the first thing potential backers see—choose wisely!
                </span>

                <FileUploadWithPreview
                    label="Gallery Images (up to 5)"
                    accept="image/*"
                    multiple={true}
                    maxFiles={5}
                    files={galleryImagesFiles}
                    setFiles={setGalleryImagesFiles}
                />
                <span className="text-xs text-gray-500 italic block">
                    Show more angles, product shots, or event photos to help backers visualize your project.
                </span>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={() => setStep(5)}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 5: Review & Submit
    if (step === 5) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 5: Review &amp; Submit
                </h2>
                <p className="text-gray-600 text-center mb-6">
                    Please review your details below.
                </p>

                <div className="text-sm leading-relaxed space-y-2 mb-4">
                    <p>
                        <strong>Business:</strong>{' '}
                        {myBusinesses.find((b) => b._id === formData.businessProfile)?.businessName ||
                            '(none)'}
                    </p>
                    <p>
                        <strong>Title:</strong> {formData.campaignTitle}
                    </p>
                    <p>
                        <strong>Type:</strong> {formData.campaignType}
                    </p>
                    <p>
                        <strong>Goal:</strong> {formData.fundingGoal} {formData.currency}
                    </p>
                    <p>
                        <strong>Funding Model:</strong> {formData.fundingModel}
                    </p>
                    {formData.campaignType === 'equity-based' && (
                        <>
                            <p>
                                <strong>Equity Offered:</strong> {formData.equityOffered}%
                            </p>
                            <p>
                                <strong>Min Investment:</strong> {formData.minInvestment}
                            </p>
                            <p>
                                <strong>Max Investment:</strong> {formData.maxInvestment}
                            </p>
                        </>
                    )}
                    <p>
                        <strong>Start:</strong> {formData.startDate || '(not set)'}{' '}
                        <strong>End:</strong> {formData.endDate}
                    </p>
                    <p>
                        <strong>Pitch Deck:</strong> {formData.pitchDeckUrl}
                    </p>
                    <p>
                        <strong>Promo Video:</strong> {formData.promoVideoUrl}
                    </p>
                    <p>
                        <strong>Tags:</strong> {formData.tags}
                    </p>
                </div>

                <div className="border border-emerald-100 bg-emerald-50 rounded p-3 my-4 text-sm text-gray-700 max-h-40 overflow-y-auto">
                    <strong className="block mb-1">Campaign Description:</strong>
                    {formData.campaignDescription ? (
                        <div
                            className="prose prose-sm max-w-none"
                            dangerouslySetInnerHTML={{ __html: formData.campaignDescription }}
                        />
                    ) : (
                        <p className="italic text-gray-500">No description entered</p>
                    )}
                </div>

                {errors.submitError && (
                    <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md text-sm">
                        <FaTimesCircle className="inline-block mr-2" />
                        {errors.submitError}
                    </div>
                )}

                <div className="flex justify-between mt-8">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleSubmit}
                        disabled={submitting}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        {submitting ? (
                            <>
                                <FaSpinner className="mr-2 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            <>
                                Submit
                                <FaSave className="ml-2" />
                            </>
                        )}
                    </button>
                </div>
            </Container>
        );
    }

    return null;
};

export default CampaignWizard;
