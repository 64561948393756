// src/components/modals/UploadDocsModal.jsx
import React, { useState } from 'react';
import { FaSpinner, FaTimesCircle,FaCloudUploadAlt } from 'react-icons/fa';

const UploadDocsModal = ({ onUpload, onClose, isUploading }) => {
  const [files, setFiles] = useState([]);

  // Allow selecting files and merge them with existing ones (up to a max of 10)
  const handleFileChange = (e) => {
    if (!e.target.files) return;
    const selectedFiles = Array.from(e.target.files);
    let newFiles = [...files];
    for (let file of selectedFiles) {
      if (newFiles.length < 10) {
        newFiles.push(file);
      } else {
        break;
      }
    }
    setFiles(newFiles);
  };

  // Remove a file from the preview list
  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleUpload = () => {
    if (!files || files.length === 0) return;
    onUpload(files);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      <div className="bg-white w-full max-w-sm rounded-md shadow-md p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Upload Documents
        </h2>

        <div className="mb-4">
          <input
            type="file"
            multiple
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-700
                       file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 
                       file:text-sm file:font-semibold file:bg-emerald-100 file:text-emerald-700
                       hover:file:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-emerald-300"
          />
          <p className="text-xs text-gray-500 mt-2">
            Allowed types: pdf, doc, docx, xls, xlsx, ppt, pptx. (Max 10 files)
          </p>

          {files && files.length > 0 && (
            <div className="mt-2 flex flex-col gap-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between border border-gray-200 p-2 rounded"
                >
                  <span className="text-sm truncate">{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={isUploading}
            className="px-4 py-2 border text-gray-600 rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            disabled={files.length === 0 || isUploading}
            className="px-4 py-2 bg-emerald-500 text-white rounded hover:bg-emerald-600 flex items-center"
          >
            {isUploading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Uploading...
              </>
            ) : (
              <>
                <FaCloudUploadAlt className="mr-2" />
                Upload
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadDocsModal;
