// src/components/crowdfund/FileUploadWithPreview.jsx
import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';

const FileUploadWithPreview = ({ label, accept, multiple, files, setFiles, maxFiles }) => {
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (multiple) {
      let newFiles = [...files];
      for (let file of selectedFiles) {
        if (maxFiles && newFiles.length >= maxFiles) break;
        newFiles.push(file);
      }
      setFiles(newFiles);
    } else {
      // For single file uploads, always replace the previous file
      setFiles(selectedFiles);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div className="mb-4">
      <label className="block mb-1 font-medium text-gray-700">{label}</label>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleFileChange}
        className="block w-full text-sm text-gray-700
                   file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 
                   file:text-sm file:font-semibold file:bg-emerald-100 file:text-emerald-700
                   hover:file:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-emerald-300"
      />
      {files && files.length > 0 && (
        <div className="mt-2 flex flex-wrap gap-2">
          {files.map((file, index) => {
            const fileUrl = URL.createObjectURL(file);
            if (file.type && file.type.startsWith("image/")) {
              return (
                <div
                  key={index}
                  className="relative w-16 h-16 border border-gray-200 rounded overflow-hidden"
                >
                  <img
                    src={fileUrl}
                    alt="preview"
                    className="object-cover w-full h-full"
                  />
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="absolute top-0 right-0 bg-white rounded-full text-red-500 hover:text-red-700"
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="relative p-2 border border-gray-200 rounded flex items-center"
                >
                  <span className="text-sm">{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default FileUploadWithPreview;
