// src/components/modals/UploadSingleImageModal.jsx
import React, { useState } from 'react';
import { FaSpinner, FaCloudUploadAlt, FaTimesCircle } from 'react-icons/fa';

const UploadSingleImageModal = ({
  title,
  onUpload,
  onClose,
  isUploading,
}) => {
  const [file, setFile] = useState(null);

  // When a file is selected, update state with the first file
  const handleFileChange = (e) => {
    if (!e.target.files) return;
    setFile(e.target.files[0]);
  };

  // Remove the selected file (clear preview)
  const handleRemoveFile = () => {
    setFile(null);
  };

  // Trigger the upload callback if a file exists
  const handleUpload = () => {
    if (!file) return;
    onUpload(file);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      <div className="bg-white w-full max-w-sm rounded-md shadow-md p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          {title}
        </h2>

        <div className="mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-700"
          />
        </div>

        {file && (
          <div className="relative w-32 h-32 border border-gray-200 rounded overflow-hidden mb-4">
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              className="object-cover w-full h-full"
            />
            <button
              onClick={handleRemoveFile}
              className="absolute top-0 right-0 bg-white rounded-full text-red-500 hover:text-red-700"
            >
              <FaTimesCircle />
            </button>
          </div>
        )}

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={isUploading}
            className="px-4 py-2 border text-gray-600 rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            disabled={!file || isUploading}
            className="px-4 py-2 bg-emerald-500 text-white rounded hover:bg-emerald-600 flex items-center"
          >
            {isUploading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Uploading...
              </>
            ) : (
              <>
                <FaCloudUploadAlt className="mr-2" />
                Upload
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadSingleImageModal;
