import React, { useEffect, useState, useRef } from 'react';
import api from '../../../api'; // Ensure api.js is properly configured

const CrowdfundLikesModal = ({ campaignId, show, onClose }) => {
  const [likersList, setLikersList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const scrollableRef = useRef(null);
  const initialLoadRef = useRef(false);

  // Reset state when the modal opens
  useEffect(() => {
    if (show) {
      setLikersList([]);
      setPage(1);
      setHasMore(true);
      initialLoadRef.current = false;
    }
  }, [show]);

  // Fetch likers using api.post
  const fetchLikers = async (pageNum = 1) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/crowdfund/campaigns/${campaignId}/likes?page=${pageNum}&limit=10`
      );

      if (response.data.success) {
        const { likers: newLikers, totalLikes } = response.data.data;
        setLikersList((prev) => {
          const updated = pageNum === 1 ? newLikers : [...prev, ...newLikers];
          setHasMore(updated.length < totalLikes);
          return updated;
        });
      }
    } catch (err) {
      console.error("Error fetching likers:", err);
    } finally {
      setLoading(false);
    }
  };

  // Run the initial load only once when the modal is shown
  useEffect(() => {
    if (show && campaignId) {
      if (page === 1 && !initialLoadRef.current) {
        fetchLikers(page);
        initialLoadRef.current = true;
      } else if (page > 1) {
        fetchLikers(page);
      }
    }
  }, [campaignId, page, show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div
        ref={scrollableRef}
        className="bg-white rounded-lg p-4 w-full max-w-md max-h-[80vh] overflow-y-auto shadow-lg"
      >
        <div className="flex justify-between items-center border-b pb-2">
          <h3 className="text-xs font-semibold">Liked by</h3>
          <button onClick={onClose} className="text-gray-600 text-xs hover:underline">
            Close
          </button>
        </div>
        <div className="mt-3 space-y-2">
          {likersList.map((like) => (
            <div key={like._id} className="flex items-center space-x-2">
              <img
                src={`${process.env.REACT_APP_API_BASE_URL || ""}api/proxy?url=${encodeURIComponent(
                  like.fakeUser.profilePicture
                )}`}
                alt={`${like.fakeUser.firstName} ${like.fakeUser.lastName}`}
                className="w-8 h-8 rounded-full border object-cover"
              />
              <span className="text-xs text-gray-800">
                {like.fakeUser.firstName} {like.fakeUser.lastName}
              </span>
            </div>
          ))}
        </div>
        {loading && (
          <div className="text-center mt-2 text-xs text-gray-500">Loading...</div>
        )}
        {!loading && hasMore && (
          <div className="mt-4 text-center">
            <button
              onClick={() => setPage((prev) => prev + 1)}
              className="text-sm text-blue-600 hover:underline"
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrowdfundLikesModal;
