// src/components/Dashboard.jsx

import React, { useState, useEffect } from 'react';
import { useUser } from "./context";
import { useNavigate } from 'react-router-dom';
import api from '../api';

// Your other imports...
import Sidebar from './Sidebar';
import ForexStrip from './TradingViewWidget';
import AntiFraudDisclaimerModal from './AntiFraudDisclaimerModal';
import DonationsSummary from './DonationsSummary';
import MyDonations from './Donations/DonationCampaignContainer.jsx';
import CampaignsInsideApp from './CampaignsInsideApp';
import VolunteerActivities from './VolunteerActivities';
import CommunityMain from './Community/CommunityMain';
import Profile from './Profile/Profile';
import Membership from './Membership';
import Wallet from './wallet/Wallet';
import Kyc from './Kyc';
import CrowdfundContainer from './crowdfund/CrowdfundContainer';

import GrantsContainer from './grants/GrantsContainer';

const Dashboard = () => {
  const { user, login } = useUser();
  const navigate = useNavigate();

  // For which component is displayed
  const [activeComponent, setActiveComponent] = useState('donationsSummary');
  

  // Control the Anti-Fraud modal
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  // Re-fetch user data from the server
  const refreshUserData = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      };
      const response = await api.get('/api/auth/info', config);
      if (response.status === 200) {
        login({ ...user, ...response.data });
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  // On mount, check login
  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
      return;
    }
    // Re-fetch user data
    refreshUserData();
  }, []);

  // If user is missing KYC, send them to KYC
  useEffect(() => {
    if (user?.token && (!user?.primaryInfo?.countryCode || !user?.primaryInfo?.firstName)) {
      setActiveComponent('kyc');
      navigate('/kyc');
    }
  }, [user, navigate]);

  // Check if we must show the Anti-Fraud disclaimer
  useEffect(() => {
    if (user && user.antiFraudDisclaimer && user.antiFraudDisclaimer.accepted === false) {
      // user has NOT accepted => show modal
      setShowDisclaimer(true);
    }
  }, [user]);



  // Map the activeComponent to the actual component
  const componentMap = {
    donationsSummary: <DonationsSummary setActiveComponent={setActiveComponent} />,
    myDonations: <MyDonations />,
    campaigns: <CampaignsInsideApp />,
    volunteerActivities: <VolunteerActivities />,
    community: <CommunityMain />,
    profile: <Profile />,
    membership: <Membership />,
    wallet: <Wallet />,
    kyc: <Kyc />,
    crowdfunding: <CrowdfundContainer />,
    grants: <GrantsContainer />,
  };

  return (
    <div className="bg-emerald-50 min-h-screen pb-20 font-harmattan font-12">
      <ForexStrip />

      {/* Anti-Fraud Disclaimer Modal */}
      {showDisclaimer && user && (
        <AntiFraudDisclaimerModal
          user={user}
          onClose={() => setShowDisclaimer(false)}
          refreshUser={refreshUserData}
        />
      )}

      {/* Example Announcement */}
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center text-xs" role="alert">
        <strong className="font-bold">🎉 Congratulations! 🎉</strong>
        <span className="block sm:inline">
          You can now call us for assistance. Reach out to our support team for prompt help at{' '}
          <a href="tel:+13852339891" className="underline">+1 385 233 9891</a>.
        </span>
      </div>

      <div
        className="bg-yellow-50 border border-yellow-300 text-yellow-800 px-4 py-3 rounded relative text-center text-xs mt-4"
        role="alert"
      >
        <span className="block sm:inline">
          {user?.primaryInfo?.firstName}, explore our newly updated help center with over 20 detailed articles!{' '}
          <span
            className="text-blue-600 font-bold cursor-pointer underline"
            onClick={() => navigate('/help')}
          >
            Click here to learn more!
          </span>
        </span>
      </div>

      <div className="lg:flex lg:flex-row p-4 rounded-lg border border-gray-200">
        <Sidebar changeComponent={setActiveComponent} />
        <div className="mx-4 mt-8 rounded-lg flex-grow flex flex-col font-harmattan font-12">
          {/* Render the active component */}
          {componentMap[activeComponent]}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
