// src/components/Login.jsx

import { useState, useEffect } from 'react';
import api from '../../api';
import { useUser } from '../context';
import { useNavigate, Link } from "react-router-dom";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLock
} from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

/**
 *  getFingerprint - obtains a unique visitorId from FingerprintJS
 */
async function getFingerprint() {
  try {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
  } catch (error) {
    console.error("Error obtaining fingerprint:", error);
    return null;
  }
}

/**
 * getLocationName - attempts to reverse-geocode lat/long into a location name
 */
async function getLocationName(latitude, longitude) {
  try {
    const apiKey = 'AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK") {
      // Attempt to find a "locality"
      const locality = data.results.find((r) =>
        r.types.includes("locality") ||
        r.types.includes("administrative_area_level_2")
      );
      return locality
        ? locality.formatted_address
        : data.results[0]?.formatted_address || 'Unknown Location';
    }
    return 'Unknown Location';
  } catch (err) {
    console.error("Error getting location name:", err);
    return 'Unknown Location';
  }
}

/**
 * Login Component
 */
const Login = () => {
  const { login, logout } = useUser();
  const navigate = useNavigate();

  // Basic form states
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Location states
  const [location, setLocation] = useState(null); // { latitude, longitude }
  const [locationName, setLocationName] = useState('');
  const [locationPermissionStatus, setLocationPermissionStatus] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Password show/hide
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // On mount, ensure we log out any existing user
  useEffect(() => {
    logout();
  }, [logout]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  // Check location permission on mount
  useEffect(() => {
    async function checkLocationPermission() {
      try {
        if (navigator.permissions && navigator.permissions.query) {
          const permStatus = await navigator.permissions.query({ name: 'geolocation' });
          setLocationPermissionStatus(permStatus.state);

          if (permStatus.state === 'granted') {
            getUserLocation();
          } else if (permStatus.state === 'prompt') {
            // Show modal to request
            setShowModal(true);
          } else if (permStatus.state === 'denied') {
            handleDeniedLocation();
          }
        } else {
          // Fallback for older browsers
          getUserLocation();
        }
      } catch (err) {
        console.error('Error checking location permission:', err);
      }
    }

    checkLocationPermission();
  }, []);

  /**
   * getUserLocation - tries to get location using HTML5 geolocation
   */
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser.");
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const { latitude, longitude } = pos.coords;
        setLocation({ latitude, longitude });
        try {
          const name = await getLocationName(latitude, longitude);
          setLocationName(name);
        } catch (err) {
          console.error("Failed to get location name:", err);
          setLocationName("Unknown Location");
        }
        setShowModal(false);
      },
      (err) => {
        if (err.code === err.PERMISSION_DENIED) {
          handleDeniedLocation();
        } else {
          setError("Failed to get location. Please reload the page.");
        }
        setShowModal(false);
      },
      { timeout: 10000 }
    );
  };

  /**
   * handleDeniedLocation - user refused location or error
   */
  const handleDeniedLocation = () => {
    setError(
      "Location access was denied. You can re-enable it in your browser settings or proceed without location, but some anti-fraud checks may fail."
    );
  };

  // Called from the location modal
  const requestLocationPermission = () => {
    getUserLocation();
  };

  /**
   * handleLogin - main login flow
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const fingerprintId = await getFingerprint();

      const loginPayload = {
        ...loginData,
        fingerprintId,
        location: location
          ? { latitude: location.latitude, longitude: location.longitude }
          : null,
        locationName: locationName || null
      };

      const response = await api.post('/api/auth/login', loginPayload);
      if (response.status === 200) {
        // Normal success
        login(response.data);
        navigate('/dashboard');
      } else {
        setError('Login failed due to unexpected response. Please try again.');
      }
    } catch (err) {
      if (err.response) {
        const { status, data } = err.response;
        if (status === 403) {
          // Something triggered a 403
          const msg = data.message || '';
          if (msg.includes('blocked') || msg.includes('block')) {
            // user is blocked
            setError('Access to your account has been denied. For assistance, please contact support at support@verdantcharity.org.');
          } else if (msg.includes('Phone verification needed')) {
            // phone verification
            const { token, phoneNumber } = data;
            navigate('/phone-verify', { state: { phoneNumber, token } });
          } else if (msg.includes('Verification needed')) {
            // email verification
            navigate('/verify', {
              state: { email: loginData.email, token: data.token },
            });
          } else {
            setError(msg || 'Forbidden. Contact support.');
          }
        } else if (status === 400 || status === 404) {
          setError(data.message || 'Invalid credentials or user not found.');
        } else if (status === 429) {
          setError(data.message || 'Too many attempts. Try again later.');
        } else {
          setError(data.message || 'Login failed. Please try again.');
        }
      } else {
        setError('Network error. Please check your connection and try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: "url('/auth_images/auth_background.jpg')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '100vh',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center overflow-auto">
        <div className="relative z-10 p-8 rounded-lg max-w-md w-full space-y-8 bg-black bg-opacity-10 text-center">
          {/* Possibly a small icon or logo at top */}
          <div className="flex justify-center mb-2">
            <img
              src="https://cdn-icons-png.flaticon.com/512/8910/8910788.png"
              alt="Login Icon"
              className="w-24 h-auto"
            />
          </div>
          <h2 className="text-xs sm:text-2xl md:text-3xl font-bold text-emerald-600">
            Sign in
          </h2>

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-left text-xs sm:text-sm"
              role="alert"
            >
              <strong className="font-bold">Error!</strong> {error}
            </div>
          )}

          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div className="mb-4 relative">
              <AiOutlineMail
                className="absolute top-3 left-3 text-emerald-500"
                size="1.25em"
              />
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="text-xs sm:text-sm block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                placeholder="Email address"
                value={loginData.email}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-4 relative">
              <AiOutlineLock
                className="absolute top-3 left-3 text-emerald-500"
                size="1.25em"
              />
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                className="text-xs sm:text-sm block w-full pl-10 pr-12 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                placeholder="Password"
                value={loginData.password}
                onChange={handleInputChange}
              />
              <button
                type="button"
                onClick={handlePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-xs sm:text-sm"
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible className="text-emerald-500" size="1.25em" />
                ) : (
                  <AiOutlineEye className="text-emerald-500" size="1.25em" />
                )}
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-xs sm:text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>
              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-emerald-600 hover:text-emerald-500 text-xs sm:text-sm"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {loading && <FaSpinner className="h-5 w-5 text-white animate-spin" />}
              </span>
              {loading ? 'Signing in...' : 'Sign in'}
            </button>

            <div className="text-center text-white text-xs sm:text-sm mt-2">
              Don't have an account?
              <Link
                to="/signup"
                className="font-medium text-emerald-600 hover:text-emerald-500 underline ml-1"
              >
                Create new account
              </Link>
            </div>
          </form>
        </div>
      </div>

      {/* Location Permission Modal */}
      {showModal && (
        <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
            <h2 className="text-lg font-bold mb-4">Location Access Needed</h2>
            <p className="text-gray-600 text-xs mb-4">
              Verdant Charity needs access to your location to ensure the security
              and authenticity of your account. We use it for anti-fraud checks.
            </p>
            <div className="bg-gray-100 p-4 border border-gray-300 rounded-lg flex items-start text-left mb-4">
              <span className="text-blue-500 mr-3 text-xl">ℹ️</span>
              <p className="text-gray-600 text-xs">
                If you deny permission, certain security checks might fail, potentially
                causing login issues. We strongly recommend allowing location for a financial platform.
              </p>
            </div>
            <button
              onClick={requestLocationPermission}
              className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-600 transition duration-300"
            >
              Grant Location Access
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
