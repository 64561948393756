// src/components/modals/DeleteConfirmationModal.jsx
import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const DeleteConfirmationModal = ({
  message,
  onConfirm,
  onCancel,
  isDeleting,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      <div className="bg-white w-full max-w-sm mx-auto rounded-md shadow-md p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Confirm Deletion
        </h2>
        <p className="text-sm text-gray-600 mb-6">{message}</p>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onCancel}
            disabled={isDeleting}
            className={`px-4 py-2 border text-gray-600 rounded hover:bg-gray-100 ${
              isDeleting ? 'opacity-60 cursor-not-allowed' : ''
            }`}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={isDeleting}
            className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center ${
              isDeleting ? 'opacity-60 cursor-not-allowed' : ''
            }`}
          >
            {isDeleting ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Deleting...
              </>
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
