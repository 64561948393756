import React, { useState, useEffect } from 'react';
import { FaHome, FaDollarSign, FaHandHoldingHeart, FaBell, FaUser, FaHeart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NotificationsPanel from './NotificationsPanel';
import api from '../api';
import { useUser } from './context';

const Footer = () => {
    const { user } = useUser();
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotificationCount = async () => {
            try {
                const response = await api.get('/api/notifications/count', {
                    headers: {
                        'Authorization': `Bearer ${user?.token}`
                    }
                });
                setNotificationCount(response.data.count);
            } catch (error) {
                console.error('Error fetching notification count:', error);
            }
        };

        if (user?.token) fetchNotificationCount();
    }, [user]);

    if (!user) return null;

    return (
        <div className="footer-container bg-rose-600/90 backdrop-blur-xl shadow-xl p-4 fixed bottom-0 inset-x-0 z-50 md:hidden border-t-2 border-rose-400">
            {/* Floating Mini Hearts */}
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                {[...Array(12)].map((_, i) => (
                    <FaHeart
                        key={i}
                        className="absolute text-rose-200/50 heart-animate"
                        style={{
                            left: `${Math.random() * 100}%`,
                            animationDelay: `${i * 1}s`,
                            fontSize: '0.75rem'
                        }}
                    />
                ))}
            </div>

            <div className="footer-icons flex justify-around relative z-10">
                {/* Home */}
                <div 
                    className="relative group cursor-pointer p-2"
                    onClick={() => navigate('/home')}
                >
                    <span className=" absolute inset-0 rounded-full bg-rose-300 opacity-75"></span>
                    <FaHome className="text-rose-50 group-hover:text-white transition-colors" size={20} />
                </div>

                {/* Wallet */}
                <div 
                    className="group cursor-pointer p-2"
                    onClick={() => navigate('/wallet')}
                >
                    <FaDollarSign className="text-rose-50 group-hover:text-white transition-colors" size={20} />
                </div>

                {/* Volunteer */}
                <div 
                    className="group cursor-pointer p-2"
                    onClick={() => navigate('/dashboard')}
                >
                    <FaHandHoldingHeart className="text-rose-50 group-hover:text-white transition-colors" size={20} />
                </div>

                {/* Notifications */}
                <div 
                    className="relative group cursor-pointer p-2"
                    onClick={() => setShowNotifications(!showNotifications)}
                >
                    <FaBell className="text-rose-50 group-hover:text-white transition-colors" size={24} />
                    {notificationCount > 0 && (
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-rose-800 rounded-full transform translate-x-1/2 -translate-y-1/2">
                            {notificationCount > 99 ? '99+' : notificationCount}
                        </span>
                    )}
                </div>

                {/* Profile */}
                <div 
                    className="group cursor-pointer p-2"
                    onClick={() => navigate('/profile')}
                >
                    <FaUser className="text-rose-50 group-hover:text-white transition-colors" size={20} />
                </div>
            </div>

            {showNotifications && <NotificationsPanel setShowNotifications={setShowNotifications} />}

            <style jsx>{`
                .heart-animate {
                    animation: float 6s linear infinite;
                }
                @keyframes float {
                    0% { transform: translateY(0) rotate(0deg); opacity: 1; }
                    100% { transform: translateY(-100vh) rotate(360deg); opacity: 0; }
                }
            `}</style>
        </div>
    );
};

export default Footer;