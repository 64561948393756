import React, { useEffect, useState } from 'react';
import { 
  FaArrowLeft, 
  FaPlus, 
  FaTrash, 
  FaMapMarkerAlt, 
  FaCalendarAlt, 
  FaInfoCircle,
  FaSpinner,
  FaTimes
} from 'react-icons/fa';

import { useUser } from '../context';
import api from '../../api';

const MyBusinessesList = ({
  onSelectBusiness,
  onCreateNewBusiness,
  onSetBusinesses,
  onBackToLanding,
}) => {
  const { user } = useUser();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isBusinessProfileModalOpen, setIsBusinessProfileModalOpen] = useState(false);

  const fetchUserBusinesses = async () => {
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.get('/api/crowdfund/business/all-User-businesses', config);
      const data = res.data.data; // array of businesses
      setBusinesses(data);
      onSetBusinesses(data); // keep the container’s state in sync
    } catch (error) {
      console.error('Error fetching user businesses:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClick = (bizId) => {
    setBusinessToDelete(bizId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!businessToDelete) return;
    setIsDeleting(true);
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      await api.delete(`/api/crowdfund/business/${businessToDelete}`, config);
      const updated = businesses.filter((b) => b._id !== businessToDelete);
      setBusinesses(updated);
      onSetBusinesses(updated);
    } catch (error) {
      console.error('Delete business error:', error);
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
      setBusinessToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setBusinessToDelete(null);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mb-4" />
        <p className="text-sm text-gray-500">Loading businesses...</p>
      </div>
    );
  }

  let mainContent;

  if (businesses.length === 0) {
    mainContent = (
      <div className="max-w-2xl mx-auto px-1 sm:px-6 lg:px-8 py-8">
        <button
          onClick={onBackToLanding}
          className="inline-flex items-center text-gray-600 hover:text-gray-800 transition-colors duration-200 mb-6 group"
        >
          <FaArrowLeft className="mr-2 transform group-hover:-translate-x-1 transition-transform" />
          Back to Landing
        </button>

        <div className="bg-white p-6 rounded-md text-center border border-gray-200">
          <h2 className="text-xl font-bold text-gray-800 mb-3">
            You have no business profiles yet
          </h2>
          <p className="text-sm text-gray-600 mb-4">
            To launch or manage campaigns, create a business profile first.
          </p>
          <button
            onClick={onCreateNewBusiness}
            className="px-4 py-2 rounded-md border border-gray-200 bg-white text-gray-700 hover:bg-gray-50"
          >
            Create Business
          </button>
          <div className="mt-4">
            <button
              onClick={() => setIsBusinessProfileModalOpen(true)}
              className="text-sm text-blue-500 hover:underline"
            >
              What is a Business Profile?
            </button>
          </div>

          {/* Mock Business Profile Preview */}
          <div className="mt-12 animate-fade-in-up">
            <h3 className="text-gray-500 text-sm font-semibold uppercase tracking-wide mb-4 text-center">
              Example Profile Preview
            </h3>
            <div className="bg-white border border-dashed border-gray-200 rounded-md overflow-hidden transition-all duration-300 hover:border-indigo-100">
              <div className="relative">
                {/* Cover Image with gradient overlay */}
                <div className="h-32 sm:h-40 bg-gray-100 relative overflow-hidden">
                  <img
                    src="https://media.bizj.us/view/img/11674779/paloma-c-53*900xx2048-1152-0-192.jpg"
                    alt="Mock Cover"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900/20" />
                </div>
                {/* Business Logo */}
                <div className="absolute -bottom-6 left-4 sm:left-6">
                  <div className="relative">
                    <img
                      src="https://images.picxy.com/cache/2022/2/4/f5d59c8c4a1eadb044f785fbcf73342b.jpg"
                      alt="Mock Logo"
                      className="w-16 h-16 sm:w-20 sm:h-20 rounded-xl border-4 border-white shadow-lg object-cover"
                    />
                    <div className="absolute inset-0 rounded-xl border border-white/20" />
                  </div>
                </div>
              </div>
              <div className="pt-8 pb-6 px-4 sm:px-6">
                <div className="mb-4">
                  <h2 className="text-xl font-bold text-gray-900 mb-1">
                    Acme Innovations LLC
                  </h2>
                  <div className="flex items-center text-sm text-gray-500 space-x-2">
                    <span>Technology • SaaS Solutions</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 text-sm mb-6">
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="flex-shrink-0 text-gray-400 mr-2" />
                    <span className="truncate">Silicon Valley, CA</span>
                  </div>
                  <div className="flex items-center">
                    <FaCalendarAlt className="flex-shrink-0 text-gray-400 mr-2" />
                    <span>Founded 2001</span>
                  </div>
                </div>
                <p className="text-gray-600 text-sm leading-relaxed">
                  This interactive preview shows how your business will appear to customers. 
                  Showcase your brand with a custom cover image, logo, and detailed company information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    mainContent = (
      <div className="max-w-5xl mx-auto p-2">
        <button
          onClick={onBackToLanding}
          className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
        >
          <FaArrowLeft className="mr-1" />
          Back
        </button>

        <div className="flex justify-end items-center mb-4">
          <button
            onClick={onCreateNewBusiness}
            className="px-4 py-2 flex items-center gap-2 rounded-md border border-gray-200 bg-white text-gray-700 hover:bg-gray-50"
          >
            <FaPlus className="text-gray-500" /> Create Business
          </button>
        </div>

        <div className="bg-blue-50 border border-blue-200 text-blue-800 p-4 rounded-md mb-4 relative flex items-start">
          <FaInfoCircle className="h-6 w-6 text-blue-500 mr-3" />
          <div>
            <p className="text-xs">
              Below are your business profiles. Click on a business to <strong>create or view campaigns</strong>.
            </p>
            <p className="text-xs mt-1">
              You can always create a new business by clicking the <strong>Create Business</strong> button above.
            </p>
          </div>
        </div>

        <div className="flex justify-end mb-4">
          <button
            onClick={() => setIsBusinessProfileModalOpen(true)}
            className="text-xs text-blue-500 hover:underline"
          >
            What is a Business Profile?
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {businesses.map((biz) => (
            <div
              key={biz._id}
              className="bg-gradient-to-r from-white to-gray-100 border border-gray-200 rounded-md overflow-hidden cursor-pointer"
              onClick={() => onSelectBusiness(biz)}
            >
              <div className="relative">
                {biz.coverImage ? (
                  <img
                    src={biz.coverImage}
                    alt="cover"
                    className="w-full h-40 object-cover"
                  />
                ) : (
                  <div className="w-full h-40 bg-gray-200 flex items-center justify-center text-gray-400 text-sm">
                    No Cover
                  </div>
                )}
              </div>
              <div className="p-3">
                <div className="flex items-center mb-2">
                  {biz.companyLogo ? (
                    <img
                      src={biz.companyLogo}
                      alt="logo"
                      className="w-10 h-10 object-cover rounded-full border border-gray-200 mr-2"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-gray-300 rounded-full mr-2 flex items-center justify-center text-xs text-gray-500">
                      No Logo
                    </div>
                  )}
                  <h3 className="font-semibold text-gray-800 flex-1">
                    {biz.businessName}
                  </h3>
                </div>

                <div className="flex items-center text-gray-600 text-xs mb-2">
                  <FaMapMarkerAlt className="text-red-500 mr-1" />
                  {biz.city || "Unknown City"}, {biz.country || "Unknown Country"}
                </div>

                <div
                  className="text-xs text-gray-600 line-clamp-2 mb-2"
                  dangerouslySetInnerHTML={{
                    __html: biz.businessDescription || "<p>No description</p>",
                  }}
                />

                <div className="flex justify-between items-center border-t border-dashed border-gray-300 pt-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectBusiness(biz);
                    }}
                    className="text-sm text-emerald-600 hover:underline"
                  >
                    View Details
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(biz._id);
                    }}
                    className="text-sm text-red-500 hover:text-red-700 flex items-center"
                  >
                    <FaTrash className="mr-1" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {mainContent}

      {/* Business Profile Info Modal */}
      {isBusinessProfileModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 p-6 relative max-h-[80vh] overflow-y-auto">
            <button
              onClick={() => setIsBusinessProfileModalOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
            <h2 className="text-xl font-semibold mb-4 text-center">
              What is a Business Profile?
            </h2>
            <div className="text-sm text-gray-700 space-y-4">
              <p>
                A business profile is essentially your business’s public identity on the platform—it’s what potential investors, donors, and supporters see when they check out your campaign. Think of it as your digital business card that communicates who you are, what you do, and what you aspire to achieve.
              </p>
              <h3 className="font-bold">Why It’s Important</h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>
                  <span className="font-semibold">First Impressions Matter:</span> Your profile is like the storefront of your business. For example, a professional cover image and a clear logo can instantly signal that you’re trustworthy and ready to make an impact.
                </li>
                <li>
                  <span className="font-semibold">Flexibility for All Stages:</span> Whether you're a fully registered entity or just at the idea stage, you can create a profile. For instance, if you plan to launch “EcoSmart Solutions”, you can start branding with that name even before official registration.
                </li>
                <li>
                  <span className="font-semibold">Enhanced Visibility and Engagement:</span> A complete profile includes your cover image, logo, detailed business description, and location. This rich information helps your profile stand out and enables local investors or supporters to connect with your mission.
                </li>
                <li>
                  <span className="font-semibold">Your Face to Investors and Donors:</span> Ultimately, your profile is your ambassador—the first thing potential supporters see. A polished profile that clearly communicates your value proposition and vision can set you apart from the rest.
                </li>
              </ul>
            </div>
            <button
              onClick={() => setIsBusinessProfileModalOpen(false)}
              className="w-full bg-emeland-500 hover:bg-emeland-600 text-white py-2 rounded mt-6"
            >
              Got it!
            </button>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
          <div className="bg-white w-full max-w-sm mx-auto rounded-md shadow-md p-6">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              Confirm Deletion
            </h2>
            <p className="text-sm text-gray-600 mb-6">
              Are you sure you want to delete this business? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={handleDeleteCancel}
                disabled={isDeleting}
                className={`px-4 py-2 border text-gray-600 rounded hover:bg-gray-100 ${
                  isDeleting ? 'opacity-60 cursor-not-allowed' : ''
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                disabled={isDeleting}
                className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center ${
                  isDeleting ? 'opacity-60 cursor-not-allowed' : ''
                }`}
              >
                {isDeleting ? (
                  <>
                    <FaSpinner className="animate-spin mr-2" />
                    Deleting...
                  </>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyBusinessesList;
