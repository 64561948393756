import React from 'react';
import { BiDollarCircle } from 'react-icons/bi';
import { AiFillAlipayCircle, AiOutlineGift } from 'react-icons/ai';
import { MdOutlineVolunteerActivism } from 'react-icons/md';
import { useUser } from "./context";

const formatCurrency = (amount, currencyCode, currencySymbol) => {
  const roundedAmount = Math.round(amount);
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const parts = formatter.formatToParts(roundedAmount);
  const formattedParts = parts.map(part => (part.type === 'currency' ? (currencySymbol || part.value) + ' ' : part.value));
  return formattedParts.join('');
};

const FloatingHearts = () => (
  <div className="absolute inset-0 overflow-hidden pointer-events-none">
    {[...Array(10)].map((_, i) => (
      <svg
        key={i}
        className="absolute heart-animate"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDelay: `${i * 2}s`,
          width: '24px',
          height: '24px'
        }}
        viewBox="0 0 32 32"
      >
        <path
          fill="#e11d48"
          d="M23.6 2c-3.4 0-6.3 2.7-7.6 5.6C14.7 4.7 11.8 2 8.4 2 4.2 2 1 5.4 1 9.6c0 4.2 3.4 9.9 10.4 16.4l2.8 2.6 2.8-2.6c7-6.5 10.4-12.2 10.4-16.4C27 5.4 23.8 2 23.6 2z"
        />
      </svg>
    ))}
  </div>
);

const SummaryCards = () => {
  const { user } = useUser();

  // Original account balance calculations remain the same
  const usdAccount = user?.accounts?.find(account => account.currency === "USD");
  const usdBalance = usdAccount ? usdAccount.balance : 0;
  const usdSymbol = usdAccount?.currencySymbol || "$";

  const primaryAccount = user?.accounts?.find(account => account.isPrimary && account.currency !== "USD") ||
    user?.accounts?.find(account => account.currency === "GBP");
  const primaryCurrency = primaryAccount ? primaryAccount.currency : 'N/A';
  const primaryBalance = primaryAccount ? primaryAccount.balance : 0;
  const primarySymbol = primaryAccount?.currencySymbol || "$";
  const primaryCurrencyCode = primaryAccount?.currency || 'USD';

  return (
    <div className="relative bg-rose-50 bg-opacity-90 backdrop-filter backdrop-blur-lg p-4 space-y-3 rounded-lg shadow-lg border-2 border-rose-100 md:grid md:grid-cols-4 md:gap-3 md:space-y-0"
      style={{
        backgroundImage: "linear-gradient(150deg, #fff0f6 0%, #ffe4e6 100%)",
      }}>
      <FloatingHearts />

      {/* Card 1: USD Balance */}
      <div className="relative bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm shadow-sm p-3 rounded-xl border-2 border-rose-200 flex items-center justify-between transition-all hover:scale-[1.02]">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-rose-900">USD Balance</h5>
          <p className="text-sm md:text-md font-bold text-rose-700">{formatCurrency(usdBalance, 'USD', usdSymbol)}</p>
        </div>
        <div className="rounded-full bg-rose-100 p-2 shadow-inner">
          <BiDollarCircle className="text-rose-600 text-lg md:text-2xl" />
        </div>
      </div>

      {/* Card 2: Primary Currency */}
      <div className="relative bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm shadow-sm p-3 rounded-xl border-2 border-rose-200 flex items-center justify-between transition-all hover:scale-[1.02]">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-rose-900">{primaryCurrency} Balance</h5>
          <p className="text-sm md:text-md font-bold text-rose-700">{formatCurrency(primaryBalance, primaryCurrencyCode, primarySymbol)}</p>
        </div>
        <div className="rounded-full bg-rose-100 p-2 shadow-inner">
          <AiFillAlipayCircle className="text-rose-600 text-lg md:text-2xl" />
        </div>
      </div>

      {/* Card 3: Active Fundraisers */}
      <div className="relative bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm shadow-sm p-3 rounded-xl border-2 border-rose-200 flex items-center justify-between transition-all hover:scale-[1.02]">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-rose-900">Active Fundraisers</h5>
          <p className="text-sm md:text-md font-bold text-rose-700">0</p>
        </div>
        <div className="rounded-full bg-rose-100 p-2 shadow-inner">
          <MdOutlineVolunteerActivism className="text-rose-600 text-lg md:text-2xl" />
        </div>
      </div>

      {/* Card 4: Impact Points */}
      <div className="relative bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm shadow-sm p-3 rounded-xl border-2 border-rose-200 flex items-center justify-between transition-all hover:scale-[1.02]">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-rose-900">Impact Points</h5>
          <p className="text-sm md:text-md font-bold text-rose-700">{user?.points}</p>
        </div>
        <div className="rounded-full bg-rose-100 p-2 shadow-inner">
          <AiOutlineGift className="text-rose-600 text-lg md:text-2xl" />
        </div>
      </div>

      <style jsx>{`
        @keyframes float {
          0% { transform: translateY(0) rotate(0deg); opacity: 1; }
          100% { transform: translateY(-100vh) rotate(360deg); opacity: 0; }
        }
        .heart-animate {
          animation: float 8s infinite linear;
        }
        .heart-animate:nth-child(odd) { animation-duration: 10s; }
        .heart-animate:nth-child(even) { animation-duration: 12s; }
      `}</style>
    </div>
  );
};

export default SummaryCards;