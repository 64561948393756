// PromoteRocket.jsx
import React from 'react';

const PromoteRocket = ({ className = 'w-6 h-6', fill = '#F97316' }) => (
  <svg className={className} viewBox="0 0 64 64" fill="none">
    <path
      d="M32 4L44 16L36 28L44 40L32 52L20 40L28 28L20 16L32 4Z"
      fill={fill}
    />
    <path
      d="M32 24L40 16"
      stroke="#FDE047"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M24 32L16 40"
      stroke="#FDE047"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <circle cx="32" cy="32" r="4" fill="#FDE047" />
    <path
      d="M48 48L56 56"
      stroke="#F59E0B"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export default PromoteRocket;