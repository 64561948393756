import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './context';
import {
  FaTachometerAlt,
  FaHandHoldingHeart,
  FaHandsHelping,
  FaInfoCircle,
  FaPhoneAlt,
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaQuestionCircle,
} from 'react-icons/fa';

const FloatingHearts = () => (
  <div className="absolute inset-0 overflow-hidden pointer-events-none">
    {[...Array(8)].map((_, i) => (
      <svg
        key={i}
        className="absolute heart-animate"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDelay: `${i * 2}s`,
          width: '24px',
          height: '24px'
        }}
        viewBox="0 0 32 32"
      >
        <path
          fill="#e11d48"
          d="M23.6 2c-3.4 0-6.3 2.7-7.6 5.6C14.7 4.7 11.8 2 8.4 2 4.2 2 1 5.4 1 9.6c0 4.2 3.4 9.9 10.4 16.4l2.8 2.6 2.8-2.6c7-6.5 10.4-12.2 10.4-16.4C27 5.4 23.8 2 23.6 2z"
        />
      </svg>
    ))}
  </div>
);

const Navbar = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-gradient-to-r from-rose-400 to-pink-300 bg-opacity-95 backdrop-filter backdrop-blur-lg p-2 text-red-50 shadow-lg transition-colors duration-300 border-b-2 border-rose-300">
      <FloatingHearts />
      <div className="container mx-auto flex justify-between items-center">
        {/* Brand Logo */}
        <div className="flex items-center space-x-2">
          <Link 
            to="/" 
            className="flex items-center hover:opacity-90 transition-opacity"
            onClick={closeMobileMenu}
          >
            <img
              src="/dashboard_images/verdant_design.png"
              alt="Love & Care"
              className="h-12 w-auto transition-transform duration-300 hover:scale-105"
            />
          </Link>
        </div>

        {/* Desktop Navigation - Full implementation */}
        <div className="hidden md:flex space-x-4 text-sm">
          <Link
            to="/"
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
          >
            <FaTachometerAlt className="text-rose-100 group-hover:text-white" />
            <span className="font-medium group-hover:text-white">Dashboard</span>
          </Link>
          <Link
            to="/Crowdfund"
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
          >
            <FaHandHoldingHeart className="text-rose-100 group-hover:text-white" />
            <span className="font-medium group-hover:text-white">Crowdfund</span>
          </Link>
          <Link
            to="/wallet"
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
          >
            <FaHandsHelping className="text-rose-100 group-hover:text-white" />
            <span className="font-medium group-hover:text-white">Volunteer</span>
          </Link>
          <Link
            to="/learn-more"
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
          >
            <FaInfoCircle className="text-rose-100 group-hover:text-white" />
            <span className="font-medium group-hover:text-white">About</span>
          </Link>
          <Link
            to="/learn-more/contact-us"
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
          >
            <FaPhoneAlt className="text-rose-100 group-hover:text-white" />
            <span className="font-medium group-hover:text-white">Contact</span>
          </Link>
          <Link
            to="/help"
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
          >
            <FaQuestionCircle className="text-rose-100 group-hover:text-white" />
            <span className="font-medium group-hover:text-white">Help</span>
          </Link>
          {user && (
            <button
              onClick={handleLogout}
              className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-rose-500/30 transition-all group"
            >
              <FaSignOutAlt className="text-rose-100 group-hover:text-white" />
              <span className="font-medium group-hover:text-white">Logout</span>
            </button>
          )}
        </div>

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden p-2 hover:bg-rose-500/30 rounded-lg transition-colors"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? (
            <FaTimes className="w-6 h-6 text-rose-100" />
          ) : (
            <FaBars className="w-6 h-6 text-rose-100" />
          )}
        </button>
      </div>

      {/* Mobile Menu - Complete implementation */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:hidden bg-rose-500/95 backdrop-blur-lg transition-all`}>
        <div className="px-4 pt-2 pb-4 space-y-2 text-sm">
          <Link
            to="/"
            onClick={closeMobileMenu}
            className="flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
          >
            <FaTachometerAlt />
            <span>Dashboard</span>
          </Link>
          <Link
            to="/Crowdfund"
            onClick={closeMobileMenu}
            className="flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
          >
            <FaHandHoldingHeart />
            <span>Crowdfund</span>
          </Link>
          <Link
            to="/wallet"
            onClick={closeMobileMenu}
            className="flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
          >
            <FaHandsHelping />
            <span>Volunteer</span>
          </Link>
          <Link
            to="/learn-more"
            onClick={closeMobileMenu}
            className="flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
          >
            <FaInfoCircle />
            <span>About</span>
          </Link>
          <Link
            to="/learn-more/contact-us"
            onClick={closeMobileMenu}
            className="flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
          >
            <FaPhoneAlt />
            <span>Contact</span>
          </Link>
          <Link
            to="/help"
            onClick={closeMobileMenu}
            className="flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
          >
            <FaQuestionCircle />
            <span>Help</span>
          </Link>
          {user && (
            <button
              onClick={() => { handleLogout(); closeMobileMenu(); }}
              className="w-full flex items-center space-x-2 px-3 py-3 rounded-lg hover:bg-rose-400/30 text-rose-50 transition-all"
            >
              <FaSignOutAlt />
              <span>Logout</span>
            </button>
          )}
        </div>
      </div>

      <style jsx>{`
        .heart-animate {
          animation: float 8s linear infinite;
        }
        @keyframes float {
          0% { transform: translateY(0) rotate(0deg); opacity: 1; }
          100% { transform: translateY(-100vh) rotate(360deg); opacity: 0; }
        }
      `}</style>
    </nav>
  );
};

export default Navbar;