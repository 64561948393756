// KycWizard.jsx

import React, { useState, useEffect } from "react";
import moment from "moment";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./context";

// Animations
import successAnimation from "./lottie/success-animation.json";
import successConfetti from "./lottie/success-confetti.json";

// Reusable steps
import {
  DisclaimerStep,
  PersonalInfoStep,
  AddressAndIdStep,
  ReviewStep,
} from "./KycSteps";

// API / Helpers
import api from "../api";

// ===================== COUNTRY KYC CONFIG =====================
const COUNTRY_CONFIG = {
  US: {
    label: "United States",
    fields: [
      {
        name: "ssn",
        label: "Social Security Number",
        placeholder: "e.g. 123-45-6789",
        explanation: "Used only for identity verification (required by U.S. law).",
      },
      { name: "streetAddress", label: "Street Address", placeholder: "e.g. 1234 Main St" },
      { name: "city", label: "City", placeholder: "e.g. Los Angeles" },
      { name: "state", label: "State", placeholder: "e.g. California" },
      { name: "postalCode", label: "ZIP Code", placeholder: "e.g. 90001" },
      // we skip firstName, lastName, dob, phone, email here because they're in Step 1
    ],
    required: ["ssn", "streetAddress", "city", "state", "postalCode"],
  },
  CA: {
    label: "Canada",
    fields: [
      {
        name: "sin",
        label: "Social Insurance Number",
        placeholder: "e.g. 123-456-789",
        explanation:
          "Required under Canadian regulations to verify identity and prevent fraud.",
      },
      { name: "streetAddress", label: "Street Address", placeholder: "e.g. 55 Wellington St" },
      { name: "city", label: "City", placeholder: "e.g. Toronto" },
      { name: "province", label: "Province", placeholder: "e.g. Ontario" },
      { name: "postalCode", label: "Postal Code", placeholder: "e.g. M5E 1C8" },
    ],
    required: ["sin", "streetAddress", "city", "province", "postalCode"],
  },
  AU: {
    label: "Australia",
    fields: [
      {
        name: "taxFileNumber",
        label: "Tax File Number (TFN)",
        placeholder: "e.g. 123 456 789",
        explanation:
          "Used only for identity verification under Australian regulations.",
      },
      { name: "streetAddress", label: "Street Address", placeholder: "e.g. 250 Pitt St" },
      { name: "city", label: "City", placeholder: "e.g. Sydney" },
      { name: "state", label: "State/Territory", placeholder: "e.g. New South Wales" },
      { name: "postalCode", label: "Postal Code", placeholder: "e.g. 2000" },
    ],
    required: [
      "taxFileNumber",
      "streetAddress",
      "city",
      "state",
      "postalCode",
    ],
  },
  UK: {
    label: "United Kingdom",
    fields: [
      {
        name: "nationalInsurance",
        label: "National Insurance Number",
        placeholder: "e.g. AB 12 34 56 C",
        explanation: "Required under UK law for identity verification.",
      },
      { name: "streetAddress", label: "Street Address", placeholder: "e.g. 221B Baker St" },
      { name: "city", label: "City/Town", placeholder: "e.g. London" },
      { name: "county", label: "County", placeholder: "e.g. Greater London" },
      { name: "postalCode", label: "Postal Code", placeholder: "e.g. NW1 6XE" },
    ],
    required: [
      "nationalInsurance",
      "streetAddress",
      "city",
      "county",
      "postalCode",
    ],
  },
  KE: {
    label: "Kenya",
    fields: [
      {
        name: "idNumber",
        label: "National ID Number",
        placeholder: "e.g. 12345678",
        explanation: "Required under Kenyan law to verify identity.",
      },
      { name: "town", label: "Town/City", placeholder: "e.g. Nairobi" },
      { name: "region", label: "County", placeholder: "e.g. Nairobi County" },
    ],
    required: ["idNumber", "town", "region"],
  },
  fallback: {
    label: "Your Country",
    fields: [
      {
        name: "identificationNumber",
        label: "Identification Number",
        placeholder: "e.g. Passport / Gov ID",
        explanation:
          "Needed to verify your identity as per international compliance.",
      },
      { name: "city", label: "City/Town", placeholder: "e.g. Berlin" },
      { name: "region", label: "Region", placeholder: "e.g. Berlin City" },
    ],
    required: ["identificationNumber", "city", "region"],
  },
};

// ===================== HELPER FUNCTIONS =====================
const isValidDate = (dob) => {
  return moment(dob, "YYYY-MM-DD", true).isValid();
};

const isOver18 = (dob) => {
  return (
    isValidDate(dob) &&
    moment().diff(moment(dob, "YYYY-MM-DD"), "years") >= 18
  );
};

const getLocationName = async (latitude, longitude) => {
  try {
    const apiKey = "AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE";
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    );
    const data = await response.json();

    if (data.status === "OK") {
      const locality = data.results.find(
        (r) =>
          r.types.includes("locality") ||
          r.types.includes("administrative_area_level_2")
      );
      return locality
        ? locality.formatted_address
        : data.results[0]?.formatted_address || "Unknown Location";
    }
    return "Unknown Location";
  } catch (error) {
    console.error("Error getting location name:", error);
    return "Unknown Location";
  }
};

const KycWizard = () => {
  const navigate = useNavigate();
  const { user, login } = useUser();

  const [step, setStep] = useState(0); // 0: disclaimer, 1: personal, 2: address, 3: review
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState("");

  // ===== KYC STATE =====
  const [kyc, setKyc] = useState({
    phone: user?.phoneNumber || "",
    email: user?.email || "",
    countryCode: user?.country || "",
    dob: "",
    ssn: "",
    sin: "",
    taxFileNumber: "",
    nationalInsurance: "",
    idNumber: "",
    identificationNumber: "",

    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    county: "",
    state: "",
    province: "",
    region: "",
    postalCode: "",
    town: "",

    location: null,
    timezone: "",
  });

  // ====== On mount, fetch IP info & location permission ======
  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
      return;
    }
    // IP-based location
    fetch("https://ipinfo.io/json?token=bff95b04d027fd")
      .then((res) => res.json())
      .then((data) => {
        const guessedCountry = data.country?.toUpperCase();
        setKyc((prev) => ({
          ...prev,
          city: data.city || "",
          region: data.region || "",
          countryCode: guessedCountry || "",
          timezone: data.timezone || "",
        }));
      })
      .catch((err) => {
        console.error("IP location failed:", err);
      });

    // Check geolocation permission
    if (navigator.geolocation) {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            if (permissionStatus.state === "granted") {
              getUserCoordinates();
            } else if (permissionStatus.state === "prompt") {
              // Show modal to ask user
              setShowLocationModal(true);
            }
          })
          .catch((err) => console.error("Permissions query error:", err));
      }
    }
    // eslint-disable-next-line
  }, []);

  const getUserCoordinates = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const { latitude, longitude } = pos.coords;
        setKyc((prev) => ({ ...prev, location: { latitude, longitude } }));

        try {
          const locationName = await getLocationName(latitude, longitude);
          setKyc((prev) => ({
            ...prev,
            town: locationName || prev.town,
          }));
        } catch (e) {
          console.error(e);
        }
        setShowLocationModal(false);
      },
      (err) => {
        console.warn("Location error:", err);
        setShowLocationModal(false);
      }
    );
  };

  const handleGrantLocation = () => {
    getUserCoordinates();
  };

  // figure out current country's config
  const getCurrentCountryConfig = () => {
    const cc = (kyc.countryCode || "").toUpperCase();
    if (cc === "US") return COUNTRY_CONFIG.US;
    if (cc === "UK" || cc === "GB") return COUNTRY_CONFIG.UK;
    if (cc === "KE") return COUNTRY_CONFIG.KE;
    if (cc === "CA") return COUNTRY_CONFIG.CA;
    if (cc === "AU") return COUNTRY_CONFIG.AU;
    return COUNTRY_CONFIG.fallback;
  };
  const countryConfig = getCurrentCountryConfig();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Dynamic formatting based on field name
    let formattedValue = value;
  
    // Handle email: always lowercase
    if (name === "email") {
      formattedValue = value.toLowerCase();
    } 
    // Handle name-like fields and regions: capitalize first letter of each word
    else if (
      [
        "firstName",
        "lastName",
        "city",
        "region",
        "county",
        "province",
        "town",
        "state",
        "streetAddress"
      ].includes(name)
    ) {
      formattedValue = value
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
    } 
    // Handle other fields (no special formatting)
    else {
      formattedValue = value;
    }
  
    // Update KYC state with the formatted value
    setKyc((prev) => ({ ...prev, [name]: formattedValue }));
  };
  
  // Step changes
  const nextStep = () => setStep((s) => s + 1);
  const prevStep = () => setStep((s) => s - 1);

  // Step validations
  const validateStep = () => {
    setError("");
    switch (step) {
      // Step 1 => personal info
      case 1:
        if (!kyc.firstName.trim()) return "Please enter your First Name.";
        if (!kyc.lastName.trim()) return "Please enter your Last Name.";
        if (!isValidDate(kyc.dob))
          return "Please provide a valid Date of Birth (YYYY-MM-DD).";
        if (!isOver18(kyc.dob))
          return "You must be at least 18 years old to proceed.";
        return "";
      // Step 2 => address + ID (we do partial check; final check in `handleSubmit`)
      case 2:
        // We can do a partial check here or wait for the final step. 
        // Let’s do a partial check for the mandatory address/ID fields
        const missing = countryConfig.required.filter(
          (fld) => !kyc[fld]?.trim()
        );
        if (missing.length > 0) {
          return `Please fill all required fields: ${missing.join(", ")}`;
        }
        return "";
      default:
        return "";
    }
  };

  const handleNext = () => {
    const msg = validateStep();
    if (msg) {
      setError(msg);
      return;
    }
    nextStep();
  };

  // Final submission
  const handleSubmit = async () => {
    setError("");
    setIsSubmitting(true);

    // Final check: all required fields + age
    const missing = countryConfig.required.filter((f) => !kyc[f]?.trim());
    if (missing.length > 0) {
      setError(`Please fill all required fields: ${missing.join(", ")}`);
      setIsSubmitting(false);
      return;
    }
    if (!isOver18(kyc.dob)) {
      setError("You must be at least 18 years old to submit KYC.");
      setIsSubmitting(false);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await api.post("/api/auth/submit-kyc", kyc, config);
      if (response.status === 200 || response.status === 201) {
        login({ ...user, primaryInfo: response.data.primaryInfo });
        setIsSuccessful(true);
      } else {
        setError("Unexpected response from the server. Please try again.");
      }
    } catch (err) {
      console.error(err);
      setError(
        err.response?.data?.message ||
          "Failed to submit KYC data. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // ====================== SUCCESS SCREEN ======================
  if (isSuccessful) {
    return (
      <div className="min-h-screen bg-gradient-to-r from-emerald-100 to-white flex flex-col items-center justify-center px-4">
        <div className="relative w-full max-w-md h-72">
          <Lottie
            animationData={successConfetti}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
          <Lottie
            animationData={successAnimation}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </div>
        <h2 className="text-xl font-bold text-emerald-700 mt-4">
          KYC Submitted Successfully!
        </h2>
        <button
          onClick={() => navigate("/wallet")}
          className="mt-6 bg-emerald-500 text-white px-6 py-2 rounded hover:bg-emerald-600 transition"
        >
          Go to Wallet
        </button>
      </div>
    );
  }

  // ====================== WIZARD STEPS ======================
  return (
    <div className="min-h-screen bg-gradient-to-r from-emerald-100 to-white py-10 px-4">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6">
        <h1 className="text-2xl font-bold text-center text-emerald-700 mb-6">
          Verdant Charity KYC
        </h1>

        {/* ERROR MESSAGE */}
        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-600 border border-red-300 rounded">
            {error}
          </div>
        )}

        {/* STEPS NAV INDICATOR */}
        <div className="flex items-center justify-center mb-6">
          {[0, 1, 2, 3].map((s) => (
            <div
              key={s}
              className={`w-8 h-8 rounded-full flex items-center justify-center mx-1 text-sm font-bold ${
                step === s
                  ? "bg-emerald-500 text-white border border-emerald-700"
                  : "bg-gray-200 text-gray-700 border border-gray-300"
              }`}
            >
              {s + 1}
            </div>
          ))}
        </div>

        {/* RENDER CURRENT STEP */}
        {step === 0 && <DisclaimerStep onAccept={() => setStep(1)} />}
        {step === 1 && (
          <PersonalInfoStep
            kyc={kyc}
            handleChange={handleChange}
            onNext={handleNext}
            onBack={() => setStep(0)}
            error={error}
            setError={setError}
          />
        )}
        {step === 2 && (
          <AddressAndIdStep
            kyc={kyc}
            handleChange={handleChange}
            onNext={handleNext}
            onBack={prevStep}
            error={error}
            setError={setError}
            countryConfig={countryConfig}
          />
        )}
        {step === 3 && (
          <ReviewStep
            kyc={kyc}
            onSubmit={handleSubmit}
            onBack={prevStep}
            isSubmitting={isSubmitting}
            error={error}
            countryConfig={countryConfig}
          />
        )}
      </div>

      {/* LOCATION PERMISSION MODAL */}
      {showLocationModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm text-center">
            <h2 className="text-lg font-semibold text-emerald-700 mb-2">
              Location Access Needed
            </h2>
            <p className="text-sm text-gray-600 mb-4">
              We’d like to auto-fill location data to enhance your experience
              and meet compliance requirements. Please grant permission or you
              can enter your address manually.
            </p>
            <button
              onClick={handleGrantLocation}
              className="bg-emerald-500 text-white px-6 py-2 rounded hover:bg-emerald-600 transition"
            >
              Grant Access
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KycWizard;
