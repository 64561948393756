// File: DonationCampaignContainer.jsx
import React, { useState } from 'react';
import DonationCampaignUserLanding from './DonationCampaignUserLanding';
import PublicDonationCampaignsGrid from './PublicDonationCampaignsGrid';
import MyDonationCampaignList from './MyDonationCampaignList';
import DonationCampaignDetails from './PublicDonationCampaignDetails';
import DonationCampaignWizard from './DonationCampaignWizard';
import MyDonationCampaignEdit from './MyDonationCampaignEdit';

const DonationCampaignContainer = () => {
  const [activeView, setActiveView] = useState('landing');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [editCampaignId, setEditCampaignId] = useState(null);

  // Debug: log current active view
  console.log("DonationCampaignContainer activeView:", activeView);

  // Switch to Edit mode
  const handleEdit = (campaignId) => {
    console.log("Switching to edit mode for campaign:", campaignId);
    setEditCampaignId(campaignId);
    setActiveView("edit");
  };

  // Cancel editing => return to My Campaigns list
  const handleCancelEdit = () => {
    console.log("Cancelling edit; returning to My Campaigns view");
    setEditCampaignId(null);
    setActiveView("myCampaigns");
  };

  return (
    <div className="min-h-[600px] bg-gradient-to-r from-emerald-50 to-emerald-100 rounded-md">
      {activeView === 'landing' && (
        <DonationCampaignUserLanding
          onExploreCampaigns={() => {
            console.log("Explore Campaigns clicked; setting active view to allCampaigns");
            setActiveView('allCampaigns');
          }}
          onGoToMyCampaigns={() => {
            console.log("My Campaigns clicked; setting active view to myCampaigns");
            setActiveView('myCampaigns');
          }}
        />
      )}

      {/* Public Campaigns */}
      {activeView === 'allCampaigns' && (
        <PublicDonationCampaignsGrid
          onBack={() => setActiveView('landing')}
          onViewCampaignDetails={(camp) => {
            console.log("Viewing public campaign details for campaign:", camp._id);
            setSelectedCampaign(camp);
            setActiveView('publicCampaignDetails');
          }}
        />
      )}

      {/* Public Campaign Details */}
      {activeView === 'publicCampaignDetails' && selectedCampaign && (
        <DonationCampaignDetails
          campaignId={selectedCampaign._id}
          onBack={() => setActiveView('allCampaigns')}
        />
      )}

      {/* My Campaigns */}
      {activeView === 'myCampaigns' && (
        <MyDonationCampaignList
          onBack={() => setActiveView('landing')}
          onCreateCampaign={() => {
            console.log("Create Campaign clicked; setting active view to campaignWizard");
            setActiveView('campaignWizard');
          }}
          onViewCampaign={(camp) => {
            console.log("Viewing my campaign details for campaign:", camp._id);
            setSelectedCampaign(camp);
            setActiveView('campaignDetails');
          }}
          onEditCampaign={handleEdit}
        />
      )}

      {/* My Campaign Edit */}
      {activeView === 'edit' && (
        <MyDonationCampaignEdit
          campaignId={editCampaignId}
          onCancel={handleCancelEdit}
        />
      )}

      {/* Campaign Details (My Campaigns) */}
      {activeView === 'campaignDetails' && selectedCampaign && (
        <DonationCampaignDetails
          campaignId={selectedCampaign._id}
          onBack={() => setActiveView('myCampaigns')}
          onEditCampaign={(camp) => {
            console.log("Editing my campaign:", camp._id);
            setSelectedCampaign(camp);
            setActiveView('campaignWizard');
          }}
          onDeleteCampaignSuccess={() => {
            console.log("Campaign deleted; returning to My Campaigns");
            setActiveView('myCampaigns');
          }}
        />
      )}

      {/* Campaign Wizard */}
      {activeView === 'campaignWizard' && (
        <DonationCampaignWizard
          existingCampaign={selectedCampaign}
          onCancel={() => {
            console.log("Cancelling campaign wizard; returning to My Campaigns");
            setActiveView('myCampaigns');
          }}
          onSuccess={(saved) => {
            console.log("Campaign wizard success; campaign saved:", saved._id);
            setSelectedCampaign(saved);
            setActiveView('myCampaigns');
          }}
        />
      )}
    </div>
  );
};

export default DonationCampaignContainer;
