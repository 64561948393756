// src/components/crowdfund/CampaignDetails.jsx
import React, { useEffect, useState } from 'react';
import {
  FaArrowLeft,
  FaTrash,
  FaEdit,
  FaRegCalendarAlt,
  FaDollarSign,
} from 'react-icons/fa';
import api from '../../api';
import { useUser } from '../context';

const CampaignDetails = ({
  campaignId,
  onBack,
  onEditCampaign,
  onDeleteCampaignSuccess,
}) => {
  const { user } = useUser();
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCampaignDetails = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/api/crowdfund/campaigns/${campaignId}`);
      if (res.data.success) {
        setCampaign(res.data.data);
      }
    } catch (err) {
      console.error('Error fetching campaign details:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this campaign?')) {
      return;
    }
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.delete(`/api/crowdfund/campaigns/${campaignId}`, config);
      if (res.data.success) {
        onDeleteCampaignSuccess?.(campaignId);
      }
    } catch (err) {
      console.error('Error deleting campaign:', err);
      alert('Failed to delete.');
    }
  };

  if (loading) {
    return (
      <div className="p-4 text-center">
        <p className="text-gray-500">Loading campaign details...</p>
      </div>
    );
  }

  if (!campaign) {
    return (
      <div className="p-4 text-center">
        <button
          onClick={onBack}
          className="text-sm text-gray-500 hover:text-gray-700 inline-flex items-center mb-4"
        >
          <FaArrowLeft className="mr-1" />
          Back
        </button>
        <p className="text-gray-500">Campaign not found.</p>
      </div>
    );
  }

  // Check ownership
  const isOwner = user?._id === campaign.owner?.toString();

  return (
    <div className="max-w-3xl mx-auto p-4">
      {/* Back Button */}
      <button
        onClick={onBack}
        className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
      >
        <FaArrowLeft className="mr-1" />
        Back
      </button>

      {/* Cover Image */}
      <div className="border border-gray-200 rounded-md overflow-hidden mb-4">
        {campaign.coverImage ? (
          <img
            src={campaign.coverImage}
            alt="Cover"
            className="w-full h-40 object-cover"
          />
        ) : (
          <div className="w-full h-40 bg-gray-200 flex items-center justify-center text-gray-400 text-sm">
            No Cover Image
          </div>
        )}
      </div>

      {/* Title + Actions */}
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl font-bold text-gray-800">
          {campaign.campaignTitle}
        </h2>
        {isOwner && (
          <div className="flex items-center space-x-3">
            <button
              onClick={() => onEditCampaign?.(campaign)}
              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
            >
              <FaEdit className="mr-1" />
              Edit
            </button>
            <button
              onClick={handleDelete}
              className="inline-flex items-center text-sm text-red-500 hover:text-red-700"
            >
              <FaTrash className="mr-1" />
              Delete
            </button>
          </div>
        )}
      </div>

      {/* Stats row */}
      <div className="flex flex-wrap gap-4 items-center text-sm text-gray-600 mb-4">
        <div className="flex items-center gap-1">
          <FaDollarSign />
          <span>Goal: {campaign.fundingGoal.toLocaleString()} {campaign.currency}</span>
        </div>
        <div className="flex items-center gap-1">
          <FaRegCalendarAlt />
          <span>Ends: {new Date(campaign.endDate).toLocaleDateString()}</span>
        </div>
        {campaign.fundingModel && (
          <span className="text-xs bg-gray-200 text-gray-700 px-2 py-1 rounded-full">
            {campaign.fundingModel.toUpperCase()}
          </span>
        )}
      </div>

      {/* Description */}
      <div className="border border-emerald-100 bg-emerald-50 rounded p-3 text-sm text-gray-700 mb-4">
        <strong className="block mb-1">Description:</strong>
        {campaign.campaignDescription ? (
          <div
            className="prose prose-sm max-w-none"
            dangerouslySetInnerHTML={{ __html: campaign.campaignDescription }}
          />
        ) : (
          <p className="italic text-gray-500">No description provided.</p>
        )}
      </div>

      {/* Additional fields... */}
      {campaign.pitchDeckUrl && (
        <p className="text-sm text-blue-600 underline">
          <a
            href={campaign.pitchDeckUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Pitch Deck
          </a>
        </p>
      )}
      {campaign.promoVideoUrl && (
        <p className="text-sm text-blue-600 underline">
          <a
            href={campaign.promoVideoUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Promo Video
          </a>
        </p>
      )}
      {campaign.tags?.length > 0 && (
        <div className="mt-2">
          <strong>Tags:</strong>{' '}
          {campaign.tags.map((t, i) => (
            <span
              key={i}
              className="inline-block bg-gray-100 text-gray-600 px-2 py-1 text-xs rounded mr-1"
            >
              {t}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default CampaignDetails;
