// File: PublicDonationLanding.jsx
import React from 'react';
import { FaTachometerAlt, FaUserPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PublicDonationCampaignsGrid from './PublicDonationCampaignsGrid';
import Testimonials from '../testimonials_component';
import { useUser } from '../context';

// Import SVG Components
import Heart from './svgs/Heart';
import Flower from './svgs/Flower';
import PaperPlane from './svgs/PaperPlane';
import Star from './svgs/Star';
import Wave from './svgs/Wave';
import SignUpSeedling from './svgs/SignUpSeedling';
import ProfileBadge from './svgs/ProfileBadge';
import CampaignDocument from './svgs/CampaignDocument';
import PromoteRocket from './svgs/PromoteRocket';


const steps = [
  {
    title: 'Sign Up',
    text: 'Create your free account & verify your details. We make it quick and simple.',
    icon: <SignUpSeedling className="w-16 h-16" />,
  },
  {
    title: 'Create Profile',
    text: 'Add business profile (required) or personal details for your campaign page.',
    icon: <ProfileBadge className="w-16 h-16" />,
  },
  {
    title: 'Publish Campaign',
    text: 'Set goals, upload media, and share your story with potential backers.',
    icon: <CampaignDocument className="w-16 h-16" />,
  },
  {
    title: 'Promote',
    text: 'Spread the word on social media and watch your community help you grow!',
    icon: <PromoteRocket className="w-16 h-16" />,
  },
];


const PublicDonationLanding = () => {
   const navigate = useNavigate();
   const { user } = useUser();

   
  return (
    <div className="w-full pb-20">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-emerald-100 to-white py-20 relative overflow-hidden">
        <div className="max-w-6xl mx-auto px-4 text-center relative z-10">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl sm:text-6xl font-bold text-gray-800 mb-6"
          >
            Create Change with <span className="text-emerald-600">Donations</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-gray-600 text-lg sm:text-xl mb-8 max-w-2xl mx-auto"
          >
            Join a community of changemakers and generous supporters. Browse donation campaigns or launch your own to drive real impact.
          </motion.p>
       <motion.div
               initial={{ opacity: 0, y: 20 }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: 0.8, delay: 0.4 }}
               className="flex flex-col sm:flex-row justify-center items-center gap-4"
             >
               {user?.token ? (
                 <button
                   onClick={() => navigate('/dashboard')}
                   className="bg-emerald-600 hover:bg-emerald-700 text-white px-8 py-3 rounded-lg font-semibold flex items-center gap-2 transition-transform transform hover:scale-105 shadow-lg"
                 >
                   <FaTachometerAlt />
                   Go to Dashboard
                 </button>
               ) : (
                 <button
                   onClick={() => navigate('/signup')}
                   className="bg-emerald-600 hover:bg-emerald-700 text-white px-8 py-3 rounded-lg font-semibold flex items-center gap-2 transition-transform transform hover:scale-105 shadow-lg"
                 >
                   <FaUserPlus />
                   Create an Account
                 </button>
               )}
             </motion.div>
        </div>

        {/* Decorative SVGs */}
        <div className="absolute inset-0 z-0">
          <motion.div
            className="absolute top-1/4 right-20"
            animate={{ x: [0, 100, 0], y: [0, 100, 0] }}
            transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
          >
            <Heart className="w-12 h-12" />
          </motion.div>
          <motion.div
            className="absolute top-1/3 left-20"
            animate={{ rotate: [0, 360], x: [0, 50, 0] }}
            transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
          >
            <Flower className="w-16 h-16" />
          </motion.div>
          <motion.div
            className="absolute bottom-20 left-1/4"
            animate={{ x: [0, 500, 0], y: [0, -100, 0] }}
            transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
          >
            <PaperPlane className="w-10 h-10" />
          </motion.div>
          <Wave className="absolute bottom-0" />
        </div>
      </section>

      {/* Campaigns Section */}
      <section className="bg-white py-16 relative">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8 text-center">
            Explore Donation Campaigns
          </h2>
          <PublicDonationCampaignsGrid />
        </div>

        {/* Floating Stars */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {[...Array(10)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
              }}
              animate={{ x: [0, 100, 0], y: [0, 100, 0] }}
              transition={{
                duration: Math.random() * 10 + 5,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <Star className="w-6 h-6" />
            </motion.div>
          ))}
        </div>
      </section>

      <section className="py-16 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-12">
            How to Launch Your Donation Campaign
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-2xl transition-all transform hover:-translate-y-2 border border-gray-100"
              >
                <motion.div
                  className="flex justify-center mb-6"
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <div className="p-4 bg-gradient-to-br from-gray-50 to-white rounded-full shadow-inner">
                    {item.icon}
                  </div>
                </motion.div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{item.title}</h3>
                <p className="text-gray-600 text-sm leading-relaxed">{item.text}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Floating Particles */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, 50, 0],
                scale: [1, 1.2, 1],
                rotate: [0, 360],
                opacity: [0.8, 1, 0.8],
              }}
              transition={{
                duration: Math.random() * 4 + 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <Flower className="w-6 h-6 text-pink-200 opacity-80" />
            </motion.div>
          ))}
        </div>

        {/* Gradient Background Elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute -top-20 -left-20 w-96 h-96 bg-gradient-to-r from-pink-100 to-purple-100 rounded-full opacity-30 blur-3xl" />
          <div className="absolute -bottom-20 -right-20 w-96 h-96 bg-gradient-to-r from-blue-100 to-green-100 rounded-full opacity-30 blur-3xl" />
        </div>
      </section>

      {/* Partner Logos */}
      <section className="py-8 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h3 className="text-center text-lg sm:text-xl font-semibold text-gray-700 mb-6">
            Our Supporters
          </h3>
          <div className="overflow-hidden bg-white">
            <div className="animate-scroll flex items-center space-x-10 p-4">
              {[
                "https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg",
                "https://website-cdn.ipinfo.io/_next/static/media/customers-group-2.1ea7d28d.svg",
                "https://website-cdn.ipinfo.io/_next/static/media/customers-group-3.4922153e.svg",
              ].map((logo, index) => (
                <img key={index} src={logo} alt="Company Logo" className="h-12 md:h-16 w-auto" />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-12 bg-emerald-50">
        <div className="max-w-6xl mx-auto px-4">
          <Testimonials />
        </div>
      </section>

      {/* Final CTA */}
      <section className="bg-gradient-to-r from-emerald-100 to-white py-12">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4">
            Ready to Make a Difference?
          </h2>
          <p className="max-w-xl mx-auto text-sm sm:text-base text-gray-700 mb-8">
            Join our community of changemakers. Sign up now and launch your donation campaign in minutes.
          </p>
          <button
            onClick={() => navigate("/signup")}
            className="bg-emerald-600 hover:bg-emerald-700 text-white px-6 py-3 rounded-md font-semibold shadow-md transition-transform transform hover:scale-105"
          >
            Get Started
          </button>
        </div>
      </section>
    </div>
  );
};

export default PublicDonationLanding;
