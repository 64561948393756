import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiHeart, FiClipboard, FiHome } from 'react-icons/fi'; // Added FiHome for the new button

const Hero = () => {
  const navigate = useNavigate();

  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat sm:h-full"
      style={{
        height: '70vh',
        backgroundImage:
          'url("https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80")',
      }}
    >
      {/* Dark overlay for better text visibility */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="relative h-full flex flex-col justify-center items-center text-white px-4 sm:px-6 lg:px-8">
        {/* Heading */}
        <motion.h1
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-4"
          style={{ fontFamily: '"Dancing Script", cursive' }}
        >
          Making a Difference Together
        </motion.h1>

        {/* Subheading */}
        <motion.p
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          className="mt-2 text-base sm:text-lg md:text-xl lg:text-2xl text-center max-w-2xl"
        >
          Join our community of donors, volunteers, and advocates to support those in need.
        </motion.p>

        {/* Call to Action Buttons */}
        <motion.div
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          className="mt-8 flex flex-col sm:flex-row gap-4"
        >
          {/* Get Donation Now Button */}
          <motion.button
            onClick={() => navigate('/dashboard')}
            className="bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-3 px-6 rounded-full flex items-center justify-center transition-transform duration-200"
            animate={{ y: [0, -10, 0] }}
            transition={{
              duration: 0.9,
              repeat: Infinity,
              repeatType: 'loop',
              ease: 'easeInOut',
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FiHeart className="mr-2" />
            Get Donation Now
          </motion.button>

          {/* Apply Grants Button */}
          <motion.button
            onClick={() => navigate('/how-to-apply')}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full flex items-center justify-center transition-transform duration-200"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1.5,
              delay: 0.3,
              ease: [0.6, -0.05, 0.01, 0.99],
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FiClipboard className="mr-2" />
            Apply Grants
          </motion.button>

          {/* New: Explore Crowdfund Button */}
          <motion.button
            onClick={() => navigate('/crowdfund')}
            className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-3 px-6 rounded-full flex items-center justify-center transition-transform duration-200"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1.5,
              delay: 0.6,
              ease: [0.6, -0.05, 0.01, 0.99],
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FiHome className="mr-2" />
            Explore Crowdfund
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
