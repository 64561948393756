import React, {
    useState,
    useEffect,
    useCallback,
    memo,
    useMemo,
  } from 'react';
  import ReactQuill from 'react-quill';
  import 'react-quill/dist/quill.snow.css';
  import {
    FaArrowLeft,
    FaArrowRight,
    FaSave,
    FaSpinner,
    FaInfoCircle,
    FaTimesCircle,
  } from 'react-icons/fa';
  
  import FileUploadWithPreview from './FileUploadWithPreview';
  import api from '../../api'; // or wherever your API helper lives
  import { useUser } from '../context'; // example: a custom hook for auth
  
  /** -----------------------------
   *   Fields from your Mongoose schema
   * -----------------------------
      numberOfEmployees: Number,
      annualRevenue: Number,
      registrationDocs: [String],
      etc.
  */
  
  // ---- Constants outside the component so they're not re-created on each render ----
  const initialForm = {
    _id: Math.random().toString(36).substring(2),
    // Basic Info
    businessName: '',
    hasRegistration: false,
    businessRegistrationNumber: '',
    businessIndustry: '',
    yearFounded: '',
    businessDescription: '',
    status: 'draft', // or 'active', etc.
  
    // Contact / Social
    email: '',
    phone: '',
    website: '',
    socialMediaLinks: {
      linkedin: '',
      facebook: '',
      instagram: '',
      twitter: '',
    },
    // Address
    addressLine1: '',
    city: '',
    country: 'silicon valley',
  
    // Additional Details
    numberOfEmployees: 0,
    annualRevenue: 0,
  
    // We'll store existing doc filenames in the DB. For uploading new docs, we'll use a File array in state:
    // registrationDocs: [],
  };
  
  // We now have 8 steps: 0..7
  const stepsTitles = [
    'Intro',            // step 0
    'Basic Info',       // step 1
    'Contact & Location', // step 2
    'Company Logo',     // step 3
    'Cover Image',      // step 4
    'Gallery Images',   // step 5
    'Additional Details', // step 6 (employees, revenue, docs)
    'Review & Submit',  // step 7
  ];
  
  /** -----------------------------------------------------
   *  Step Indicator (Memoized)
   * ---------------------------------------------------- */
  const StepIndicator = memo(function StepIndicator({ step, stepsTitles }) {
    return (
      <div className="mb-6 overflow-x-auto flex items-center justify-center">
        <div className="flex items-center justify-center min-w-[500px]">
          {stepsTitles.map((title, idx) => (
            <div key={idx} className="flex items-center">
              <div
                className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center text-xs sm:text-sm font-bold
                    ${
                      step === idx
                        ? 'bg-emerald-400 text-white border border-emerald-500'
                        : idx < step
                        ? 'bg-emerald-100 text-emerald-700 border border-emerald-200'
                        : 'bg-gray-200 text-gray-600 border border-gray-300'
                    }`}
              >
                {idx + 1}
              </div>
              {idx < stepsTitles.length - 1 && (
                <div className="w-6 sm:w-8 h-[2px] bg-gray-200 mx-1 sm:mx-2" />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  });
  
  /** -----------------------------------------------------
   *  Container (Memoized)
   * ---------------------------------------------------- */
  const Container = memo(function Container({ step, stepsTitles, children }) {
    return (
      <div className="max-w-3xl mx-auto mt-6 bg-white border border-emerald-200 rounded-md p-6">
        <StepIndicator step={step} stepsTitles={stepsTitles} />
        {children}
      </div>
    );
  });
  
  /** -----------------------------------------------------
   *  Main Wizard Component
   * ---------------------------------------------------- */
  const BusinessWizard = ({ existingBusiness, onSave, onCancel }) => {
    const { user } = useUser(); // if you have a user context
  
    // Manage the current step (0..7)
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState(initialForm);
  
    // Additional local states for file uploads
    const [companyLogoFiles, setCompanyLogoFiles] = useState([]);
    const [coverImageFiles, setCoverImageFiles] = useState([]);
    const [galleryImagesFiles, setGalleryImagesFiles] = useState([]);
    // NEW: registration docs
    const [registrationDocsFiles, setRegistrationDocsFiles] = useState([]);
  
    // For errors & submission
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
  
    /** -----------------------------------------------------
     *  Handle existingBusiness -> formData
     * ---------------------------------------------------- */
    useEffect(() => {
      if (existingBusiness) {
        setFormData((prev) => {
          const newForm = {
            ...prev,
            ...existingBusiness,
  
            // bool for "hasRegistration"
            hasRegistration: !!existingBusiness.businessRegistrationNumber,
  
            // reduce social links to object
            socialMediaLinks: (existingBusiness.socialMediaLinks || []).reduce(
              (acc, cur) => {
                acc[cur.platform.toLowerCase()] = cur.url;
                return acc;
              },
              {
                linkedin: '',
                facebook: '',
                instagram: '',
                twitter: '',
              }
            ),
  
            // parse yearFounded to yyyy-MM-dd if present
            yearFounded: existingBusiness.yearFounded
              ? new Date(existingBusiness.yearFounded).toISOString().split('T')[0]
              : '',
  
            // Additional details
            numberOfEmployees: existingBusiness.numberOfEmployees || 0,
            annualRevenue: existingBusiness.annualRevenue || 0,
            // We'll handle existing docs if we want to show them somehow.
          };
          return newForm;
        });
      }
    }, [existingBusiness]);
  
    /** -----------------------------------------------------
     *  Step validation
     * ---------------------------------------------------- */
    const validateStep = useCallback(
      (stepIndex) => {
        const newErrors = {};
  
        // Step 1: Basic Info
        if (stepIndex === 1) {
          if (!formData.businessName.trim()) {
            newErrors.businessName = 'Business Name is required';
          }
          if (!formData.businessIndustry.trim()) {
            newErrors.businessIndustry = 'Business Industry is required';
          }
          if (
            formData.hasRegistration &&
            !formData.businessRegistrationNumber.trim()
          ) {
            newErrors.businessRegistrationNumber =
              'Registration number is required if you select "Yes"';
          }
        }
  
        // Step 2: Contact & Location
        if (stepIndex === 2) {
          if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
          }
          if (!formData.addressLine1.trim()) {
            newErrors.addressLine1 = 'Address is required';
          }
          if (!formData.city.trim()) {
            newErrors.city = 'City is required';
          }
        }
  
        // Step 6: Additional Details - For example, you might want
        // to ensure that # of employees or revenue are non-negative, etc.
        if (stepIndex === 6) {
          if (formData.numberOfEmployees < 0) {
            newErrors.numberOfEmployees = 'Cannot be negative.';
          }
          if (formData.annualRevenue < 0) {
            newErrors.annualRevenue = 'Cannot be negative.';
          }
        }
  
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      },
      [formData]
    );
  
    /** -----------------------------------------------------
     *  Navigation
     * ---------------------------------------------------- */
    const handleNext = useCallback(() => {
      // Validate steps that need it
      if ([1, 2, 6].includes(step)) {
        if (!validateStep(step)) return;
      }
      setStep((s) => s + 1);
    }, [step, validateStep]);
  
    const handlePrev = useCallback(() => {
      setStep((s) => s - 1);
    }, []);
  
    /** -----------------------------------------------------
     *  Update Form Fields
     * ---------------------------------------------------- */
    const updateField = useCallback((field, value) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
    }, []);
  
    const updateSocialLink = useCallback((platform, url) => {
      setFormData((prev) => ({
        ...prev,
        socialMediaLinks: {
          ...prev.socialMediaLinks,
          [platform]: url,
        },
      }));
    }, []);
  
    /** -----------------------------------------------------
     *  Submit (Final)
     * ---------------------------------------------------- */
   // Updated handleSubmit function
const handleSubmit = useCallback(async () => {
  setSubmitting(true);
  setErrors({});

  try {
    // Build FormData to send to your API endpoint
    const toSend = new FormData();
    toSend.append('businessName', formData.businessName);
    toSend.append('businessIndustry', formData.businessIndustry);
    toSend.append('hasRegistration', formData.hasRegistration ? 'true' : 'false');
    toSend.append(
      'businessRegistrationNumber',
      formData.hasRegistration ? formData.businessRegistrationNumber : ''
    );
    toSend.append(
      'yearFounded',
      formData.yearFounded ? new Date(formData.yearFounded).toISOString() : ''
    );
    toSend.append('businessDescription', formData.businessDescription || '');
    toSend.append('status', formData.status || 'draft');
    toSend.append('email', formData.email);
    toSend.append('phone', formData.phone);
    toSend.append('website', formData.website);
    toSend.append('addressLine1', formData.addressLine1);
    toSend.append('city', formData.city);
    toSend.append('country', formData.country);

    // Additional details
    toSend.append('numberOfEmployees', formData.numberOfEmployees.toString());
    toSend.append('annualRevenue', formData.annualRevenue.toString());

    // Social links -> array
    const socialArr = [];
    Object.entries(formData.socialMediaLinks).forEach(([platform, url]) => {
      if (url) socialArr.push({ platform, url });
    });
    toSend.append('socialMediaLinks', JSON.stringify(socialArr));

    // Files
    if (companyLogoFiles.length > 0) {
      toSend.append('companyLogo', companyLogoFiles[0]);
    }
    if (coverImageFiles.length > 0) {
      toSend.append('coverImage', coverImageFiles[0]);
    }
    galleryImagesFiles.forEach((file) => {
      toSend.append('galleryImages', file);
    });
    // <== IMPORTANT: Change the key from 'registrationDocs' to 'docs'
    registrationDocsFiles.forEach((file) => {
      toSend.append('docs', file);
    });

    // Make the API request
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await api.post(
      '/api/crowdfund/business/create-business',
      toSend,
      config
    );

    if (response.status >= 200 && response.status < 300) {
      if (onSave && typeof onSave === 'function') {
        onSave();
      } else {
        console.log('Business profile saved successfully!');
      }
    } else {
      throw new Error(response.data?.message || 'Failed to create business');
    }
  } catch (err) {
    console.error('[handleSubmit] Error =>', err);
    setErrors({
      submitError:
        err.response?.data?.message ||
        err.message ||
        'Something went wrong.',
    });
  } finally {
    setSubmitting(false);
  }
}, [
  user,
  formData,
  companyLogoFiles,
  coverImageFiles,
  galleryImagesFiles,
  registrationDocsFiles,
  onSave,
]);


    // -----------------------------------
    // RENDER LOGIC FOR STEPS
    // -----------------------------------

     /** Step 0: Intro */
  if (step === 0) {
    return (
      <div className="max-w-2xl mx-auto mt-6 bg-gradient-to-r from-white to-green-100 border border-emerald-200 rounded-md p-6">
        <div className="flex items-center mb-4 text-emerald-700">
          <FaInfoCircle className="mr-2" />
          <h2 className="text-lg font-bold">Before You Start</h2>
        </div>
        <p className="text-sm text-gray-600 leading-relaxed mb-4">
          We’ll guide you through creating a new business profile. You may skip
          certain fields (like images or docs) and fill them later. Please have
          the following ready:
        </p>
        <ul className="list-disc list-inside text-sm text-gray-700 mb-6 space-y-1">
          <li>
            <strong>Business Name &amp; Industry</strong> (required)
          </li>
          <li>
            <strong>Registration Number</strong> (optional)
          </li>
          <li>
            <strong>Address &amp; Email</strong> (required)
          </li>
          <li>
            <strong>Description</strong> (recommended)
          </li>
          <li>
            <strong>Media Uploads</strong> (logo, cover, gallery) optional
          </li>
          <li>
            <strong>Employees &amp; Revenue</strong> (optional)
          </li>
          <li>
            <strong>Registration Docs</strong> (optional)
          </li>
        </ul>
        <div className="flex items-center gap-3">
          <button
            onClick={() => setStep(1)}
            className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
          >
            Next
            <FaArrowRight className="ml-2" />
          </button>
          <button
            onClick={onCancel}
            className="inline-flex items-center text-gray-500 hover:text-gray-700"
          >
            <FaTimesCircle className="mr-1" />
            Cancel
          </button>
        </div>
      </div>
    );
  }

    // Step 1: Basic Info
     /** Step 1: Basic Info */
  if (step === 1) {
    return (
      <Container step={step} stepsTitles={stepsTitles}>
        <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
          Step 1: Basic Info
        </h2>

        {/* Business Name */}
        <div className="mb-4">
          <label
            htmlFor="businessName"
            className="block mb-1 font-medium text-gray-700"
          >
            Business Name <span className="text-red-500">*</span>
          </label>
          <input
            id="businessName"
            name="businessName"
            type="text"
            placeholder="Your business name"
            value={formData.businessName}
            onChange={(e) => updateField('businessName', e.target.value)}
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
          />
          {errors.businessName && (
            <p className="text-red-600 text-sm mt-1">{errors.businessName}</p>
          )}
        </div>

        {/* Industry */}
        <div className="mb-4">
          <label
            htmlFor="businessIndustry"
            className="block mb-1 font-medium text-gray-700"
          >
            Industry <span className="text-red-500">*</span>
          </label>
          <input
            id="businessIndustry"
            name="businessIndustry"
            type="text"
            placeholder="e.g. Technology, Healthcare"
            value={formData.businessIndustry}
            onChange={(e) => updateField('businessIndustry', e.target.value)}
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
          />
          {errors.businessIndustry && (
            <p className="text-red-600 text-sm mt-1">
              {errors.businessIndustry}
            </p>
          )}
        </div>

        {/* Registration */}
        <div className="mb-4">
          <label
            htmlFor="hasRegistration"
            className="block mb-1 font-medium text-gray-700"
          >
            Is the business registered?
          </label>
          <div className="flex items-center space-x-4">
            <label
              htmlFor="hasRegistrationYes"
              className="inline-flex items-center"
            >
              <input
                id="hasRegistrationYes"
                name="hasRegistration"
                type="radio"
                checked={formData.hasRegistration === true}
                onChange={() => updateField('hasRegistration', true)}
                className="mr-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
              />
              Yes
            </label>
            <label
              htmlFor="hasRegistrationNo"
              className="inline-flex items-center"
            >
              <input
                id="hasRegistrationNo"
                name="hasRegistration"
                type="radio"
                checked={formData.hasRegistration === false}
                onChange={() => updateField('hasRegistration', false)}
                className="mr-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
              />
              No
            </label>
          </div>
        </div>

        {formData.hasRegistration && (
          <div className="mb-4">
            <label
              htmlFor="businessRegistrationNumber"
              className="block mb-1 font-medium text-gray-700"
            >
              Registration Number <span className="text-red-500">*</span>
            </label>
            <input
              id="businessRegistrationNumber"
              name="businessRegistrationNumber"
              type="text"
              placeholder="Enter registration number"
              value={formData.businessRegistrationNumber}
              onChange={(e) =>
                updateField('businessRegistrationNumber', e.target.value)
              }
              className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
            />
            {errors.businessRegistrationNumber && (
              <p className="text-red-600 text-sm mt-1">
                {errors.businessRegistrationNumber}
              </p>
            )}
          </div>
        )}

        {/* Year Founded */}
        <div className="mb-4">
          <label
            htmlFor="yearFounded"
            className="block mb-1 font-medium text-gray-700"
          >
            Year Founded (mm/dd/yyyy)
          </label>
          <input
            id="yearFounded"
            name="yearFounded"
            type="date"
            value={formData.yearFounded}
            onChange={(e) => updateField('yearFounded', e.target.value)}
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
          />
        </div>

        {/* Description with container to prevent overlap */}
        <div className="mb-4">
          <label
            htmlFor="businessDescription"
            className="block mb-1 font-medium text-gray-700"
          >
            Description (Recommended)
          </label>
          <div className="bg-emerald-50 border border-emerald-200 text-emerald-800 p-3 rounded-md mb-2 flex items-start space-x-3">
            <FaInfoCircle className="mt-1" />
            <p className="text-sm leading-snug">
              A compelling description helps attract potential investors. Share
              what makes your business unique and your vision for the future.
            </p>
          </div>

          {/* Containing Quill to avoid overlap */}
          <div className="border border-gray-200 rounded p-2 mb-10">
            <ReactQuill
              id="businessDescription"
              theme="snow"
              value={formData.businessDescription}
              onChange={(val) => updateField('businessDescription', val)}
              style={{ minHeight: '150px' }}
            />
          </div>
        </div>

        {/* Navigation */}
        <div className="flex justify-between mt-6">
          <button
            onClick={onCancel}
            className="inline-flex items-center text-gray-500 hover:text-gray-700"
          >
            <FaTimesCircle className="mr-1" />
            Cancel
          </button>
          <button
            onClick={handleNext}
            className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
          >
            Next
            <FaArrowRight className="ml-2" />
          </button>
        </div>
      </Container>
    );
  }

    // Step 2: Contact & Location
    if (step === 2) {
        console.log('%c[Step 2] Contact & Location', 'color: #9c27b0;');
        return (
            <Container step={step} stepsTitles={stepsTitles}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 2: Contact &amp; Location
                </h2>

                {/* Email */}
                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Email <span className="text-red-500">*</span>
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Your contact email"
                        value={formData.email}
                        onChange={(e) => updateField('email', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    {errors.email && (
                        <p className="text-red-600 text-sm mt-1">{errors.email}</p>
                    )}
                </div>

                {/* Phone */}
                <div className="mb-4">
                    <label
                        htmlFor="phone"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Phone
                    </label>
                    <input
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder="Optional phone number"
                        value={formData.phone}
                        onChange={(e) => updateField('phone', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                </div>

                {/* Website */}
                <div className="mb-4">
                    <label
                        htmlFor="website"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Website
                    </label>
                    <input
                        id="website"
                        name="website"
                        type="url"
                        placeholder="https://..."
                        value={formData.website}
                        onChange={(e) => updateField('website', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                </div>

                {/* Social Media Links */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label
                            htmlFor="linkedin"
                            className="block mb-1 font-medium text-gray-700"
                        >
                            LinkedIn
                        </label>
                        <input
                            id="linkedin"
                            name="linkedin"
                            type="url"
                            placeholder="https://linkedin.com/..."
                            value={formData.socialMediaLinks.linkedin}
                            onChange={(e) => updateSocialLink('linkedin', e.target.value)}
                            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="facebook"
                            className="block mb-1 font-medium text-gray-700"
                        >
                            Facebook
                        </label>
                        <input
                            id="facebook"
                            name="facebook"
                            type="url"
                            placeholder="https://facebook.com/..."
                            value={formData.socialMediaLinks.facebook}
                            onChange={(e) => updateSocialLink('facebook', e.target.value)}
                            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="instagram"
                            className="block mb-1 font-medium text-gray-700"
                        >
                            Instagram
                        </label>
                        <input
                            id="instagram"
                            name="instagram"
                            type="url"
                            placeholder="https://instagram.com/..."
                            value={formData.socialMediaLinks.instagram}
                            onChange={(e) => updateSocialLink('instagram', e.target.value)}
                            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="twitter"
                            className="block mb-1 font-medium text-gray-700"
                        >
                            Twitter (X)
                        </label>
                        <input
                            id="twitter"
                            name="twitter"
                            type="url"
                            placeholder="https://twitter.com/..."
                            value={formData.socialMediaLinks.twitter}
                            onChange={(e) => updateSocialLink('twitter', e.target.value)}
                            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                    </div>
                </div>

                <hr className="my-4 border-emerald-100" />

                {/* Address */}
                <div className="mb-4">
                    <label
                        htmlFor="addressLine1"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Address <span className="text-red-500">*</span>
                    </label>
                    <input
                        id="addressLine1"
                        name="addressLine1"
                        type="text"
                        placeholder="123 Main St"
                        value={formData.addressLine1}
                        onChange={(e) => updateField('addressLine1', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    {errors.addressLine1 && (
                        <p className="text-red-600 text-sm mt-1">{errors.addressLine1}</p>
                    )}
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="city"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        City <span className="text-red-500">*</span>
                    </label>
                    <input
                        id="city"
                        name="city"
                        type="text"
                        placeholder="e.g. San Francisco"
                        value={formData.city}
                        onChange={(e) => updateField('city', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    {errors.city && (
                        <p className="text-red-600 text-sm mt-1">{errors.city}</p>
                    )}
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="country"
                        className="block mb-1 font-medium text-gray-700"
                    >
                        Country
                    </label>
                    <input
                        id="country"
                        name="country"
                        type="text"
                        placeholder="e.g. USA"
                        value={formData.country}
                        onChange={(e) => updateField('country', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                </div>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 3: Company Logo
    if (step === 3) {
        console.log('%c[Step 3] Company Logo', 'color: #9c27b0;');
        return (
            <Container step={step} stepsTitles={stepsTitles}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 3: Company Logo
                </h2>
                <p className="text-sm text-gray-500 mb-4 text-center">
                    A recognizable logo helps establish brand identity. (Single image.)
                </p>
                <FileUploadWithPreview
                    label="Company Logo"
                    accept="image/*"
                    multiple={false}
                    files={companyLogoFiles}
                    setFiles={setCompanyLogoFiles}
                />
                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <div>
                        <button
                            onClick={handleNext}
                            className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                        >
                            Next
                            <FaArrowRight className="ml-2" />
                        </button>
                        <button
                            onClick={handleNext}
                            className="ml-3 text-sm text-blue-500 underline hover:text-blue-600"
                        >
                            Skip for now
                        </button>
                    </div>
                </div>
            </Container>
        );
    }

    // Step 4: Cover Image
    if (step === 4) {
        console.log('%c[Step 4] Cover Image', 'color: #9c27b0;');
        return (
            <Container step={step} stepsTitles={stepsTitles}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 4: Cover Image
                </h2>
                <p className="text-sm text-gray-500 mb-4 text-center">
                    A beautiful cover image can grab attention on your profile. (Single image.)
                </p>
                <FileUploadWithPreview
                    label="Cover Image"
                    accept="image/*"
                    multiple={false}
                    files={coverImageFiles}
                    setFiles={setCoverImageFiles}
                />
                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <div>
                        <button
                            onClick={handleNext}
                            className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                        >
                            Next
                            <FaArrowRight className="ml-2" />
                        </button>
                        <button
                            onClick={handleNext}
                            className="ml-3 text-sm text-blue-500 underline hover:text-blue-600"
                        >
                            Skip for now
                        </button>
                    </div>
                </div>
            </Container>
        );
    }

    // Step 5: Gallery Images
    if (step === 5) {
        console.log('%c[Step 5] Gallery Images', 'color: #9c27b0;');
        return (
            <Container step={step} stepsTitles={stepsTitles}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 5: Gallery Images
                </h2>
                <p className="text-sm text-gray-500 mb-4 text-center">
                    Showcase additional images. You can upload up to 5 images.
                </p>
                <FileUploadWithPreview
                    label="Gallery Images (up to 5)"
                    accept="image/*"
                    multiple={true}
                    maxFiles={5}
                    files={galleryImagesFiles}
                    setFiles={setGalleryImagesFiles}
                />
                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <div>
                        <button
                            onClick={handleNext}
                            className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                        >
                            Next
                            <FaArrowRight className="ml-2" />
                        </button>
                        <button
                            onClick={handleNext}
                            className="ml-3 text-sm text-blue-500 underline hover:text-blue-600"
                        >
                            Skip for now
                        </button>
                    </div>
                </div>
            </Container>
        );
    }

    /** -----------------------------------------------------------------
   *  Step 6: Additional Details (numberOfEmployees, annualRevenue, docs)
   * ----------------------------------------------------------------- */
  if (step === 6) {
    return (
      <Container step={step} stepsTitles={stepsTitles}>
        <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
          Step 6: Additional Details
        </h2>
        <p className="text-sm text-gray-500 mb-4 text-center">
          Provide more information about your business.
        </p>

        {/* Number of Employees */}
        <div className="mb-4">
          <label
            htmlFor="numberOfEmployees"
            className="block mb-1 font-medium text-gray-700"
          >
            Number of Employees
          </label>
          <input
            id="numberOfEmployees"
            name="numberOfEmployees"
            type="number"
            min="0"
            value={formData.numberOfEmployees}
            onChange={(e) =>
              updateField('numberOfEmployees', Number(e.target.value))
            }
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
          />
          {errors.numberOfEmployees && (
            <p className="text-red-600 text-sm mt-1">
              {errors.numberOfEmployees}
            </p>
          )}
        </div>

        {/* Annual Revenue */}
        <div className="mb-4">
          <label
            htmlFor="annualRevenue"
            className="block mb-1 font-medium text-gray-700"
          >
            Annual Revenue
          </label>
          <input
            id="annualRevenue"
            name="annualRevenue"
            type="number"
            min="0"
            value={formData.annualRevenue}
            onChange={(e) =>
              updateField('annualRevenue', Number(e.target.value))
            }
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
          />
          {errors.annualRevenue && (
            <p className="text-red-600 text-sm mt-1">{errors.annualRevenue}</p>
          )}
        </div>

        {/* Registration Docs */}
        <div className="mb-4">
          <label
            className="block mb-1 font-medium text-gray-700"
            htmlFor="registrationDocs"
          >
            Registration Docs (Optional)
          </label>
          <FileUploadWithPreview
            label="Registration Docs (PDF, Word, Excel, or image files)"
            accept=".pdf,image/*"
            multiple={true}
            maxFiles={10}
            files={registrationDocsFiles}
            setFiles={setRegistrationDocsFiles}
          />
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={handlePrev}
            className="inline-flex items-center text-gray-500 hover:text-gray-700"
          >
            <FaArrowLeft className="mr-2" />
            Back
          </button>
          <div>
            <button
              onClick={handleNext}
              className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
            >
              Next
              <FaArrowRight className="ml-2" />
            </button>
          </div>
        </div>
      </Container>
    );
  }

  /** Step 7: Review & Submit */
  if (step === 7) {
    return (
      <Container step={step} stepsTitles={stepsTitles}>
        <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
          Step 7: Review &amp; Submit
        </h2>
        <p className="text-gray-600 text-center mb-6">
          Please review your details below.
        </p>

        <div className="text-sm leading-relaxed space-y-2">
          <p>
            <strong>Business Name:</strong> {formData.businessName}
          </p>
          <p>
            <strong>Industry:</strong> {formData.businessIndustry}
          </p>
          {formData.hasRegistration && (
            <p>
              <strong>Registration #:</strong>{' '}
              {formData.businessRegistrationNumber}
            </p>
          )}
          {formData.yearFounded && (
            <p>
              <strong>Year Founded:</strong> {formData.yearFounded}
            </p>
          )}
          <p>
            <strong>Employees:</strong> {formData.numberOfEmployees}
          </p>
          <p>
            <strong>Annual Revenue:</strong> {formData.annualRevenue}
          </p>
          <p>
            <strong>Email:</strong> {formData.email}
          </p>
          <p>
            <strong>Address:</strong> {formData.addressLine1}, {formData.city},{' '}
            {formData.country}
          </p>
        </div>

        {/* Description Preview */}
        <div className="border border-emerald-100 bg-emerald-50 rounded p-3 my-4 text-sm text-gray-700 max-h-40 overflow-y-auto">
          <strong className="block mb-1">Description:</strong>
          {formData.businessDescription ? (
            <div
              className="prose prose-sm max-w-none"
              dangerouslySetInnerHTML={{ __html: formData.businessDescription }}
            />
          ) : (
            <p className="italic text-gray-500">No description entered</p>
          )}
        </div>

        {/* Error Message */}
        {errors.submitError && (
          <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md text-sm">
            <FaTimesCircle className="inline-block mr-2" />
            {errors.submitError}
          </div>
        )}

        {/* Navigation */}
        <div className="flex justify-between mt-8">
          <button
            onClick={handlePrev}
            className="inline-flex items-center text-gray-500 hover:text-gray-700"
          >
            <FaArrowLeft className="mr-2" />
            Back
          </button>
          <button
            onClick={handleSubmit}
            disabled={submitting}
            className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
          >
            {submitting ? (
              <>
                <FaSpinner className="mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              <>
                Submit
                <FaSave className="ml-2" />
              </>
            )}
          </button>
        </div>
      </Container>
    );
  }

  // Fallback
  return null;
};

export default BusinessWizard;