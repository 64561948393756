// CampaignDocument.jsx
import React from 'react';

const CampaignDocument = ({ className = 'w-6 h-6', fill = '#3B82F6' }) => (
  <svg className={className} viewBox="0 0 64 64" fill="none">
    <path
      d="M16 8H48V56H16V8Z"
      fill={fill}
      stroke="#1D4ED8"
      strokeWidth="2"
    />
    <path
      d="M24 20H40M24 28H40M24 36H32"
      stroke="#BFDBFE"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M44 48L52 56"
      stroke="#FDE047"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <circle cx="44" cy="56" r="4" fill="#FDE047" />
  </svg>
);

export default CampaignDocument;