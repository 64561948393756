// File: MyDonationCampaignList.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaPlus,
  FaPen,
  FaEye,
  FaHeart,
  FaArrowLeft,
  FaUserFriends,
  FaCheck,
  FaTimes,
  FaHourglassHalf,
  FaPlay,
  FaLock,
  FaTrophy
} from "react-icons/fa";  // <-- Added additional icons
import { AiOutlineArrowRight } from "react-icons/ai";
import api from "../../api";
import { useUser } from "../context";
import DeleteConfirmationModal from "./Modals/DeleteConfirmationModal";
import DonationCampaignLikesModal from "./Modals/DonationCampaignLikesModal";

const FOUNDER_PLACEHOLDER ="https://cdn-icons-png.flaticon.com/512/3990/3990850.png"

const MyDonationCampaignList = ({ onBack, onCreateCampaign, onEditCampaign }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // State for the Likes Modal
  const [showLikersModal, setShowLikersModal] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  // Fetch donation campaigns created by the logged-in user
  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.get(`/api/donationcampaigns/user/${user._id}`, config);
      if (res.data.success) {
        const campaignsData = res.data.data;
        // For each campaign, fetch the first 3 likers
        const campaignsWithLikers = await Promise.all(
          campaignsData.map(async (camp) => {
            try {
              const resLikes = await api.get(`/api/donationcampaigns/${camp._id}/likes`, {
                params: { page: 1, limit: 3 },
              });
              if (resLikes.data.success) {
                camp.likers = resLikes.data.data.likers;
                camp.likeCount = resLikes.data.data.totalLikes;
              } else {
                camp.likers = [];
                camp.likeCount = 0;
              }
            } catch (err) {
              console.error("Error fetching likers for campaign:", camp._id, err);
              camp.likers = [];
              camp.likeCount = 0;
            }
            return camp;
          })
        );
        setCampaigns(campaignsWithLikers);
      }
    } catch (err) {
      console.error("Error fetching my donation campaigns:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!campaignToDelete) return;
    setIsDeleting(true);
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      await api.delete(`/api/donationcampaigns/${campaignToDelete._id}`, config);
      setCampaigns((prev) => prev.filter((c) => c._id !== campaignToDelete._id));
    } catch (err) {
      console.error("Error deleting campaign:", err);
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setCampaignToDelete(null);
    }
  };

  const cancelDelete = () => {
    if (isDeleting) return;
    setShowDeleteModal(false);
    setCampaignToDelete(null);
  };

  const getDaysLeft = (endDate) => {
    if (!endDate) return 0;
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
  };

  const getProgress = (goal, current) => {
    if (!goal || goal <= 0) return 0;
    const ratio = (current / goal) * 100;
    return ratio > 100 ? 100 : ratio;
  };

  // Helper function to return CSS classes for the campaign status badge
  const getStatusBadgeClasses = (status) => {
    switch (status) {
      case "pending_approval":
        return "bg-yellow-100 text-yellow-800";
      case "approved":
        return "bg-blue-100 text-blue-800";
      case "rejected":
        return "bg-red-100 text-red-800";
      case "live":
        return "bg-green-100 text-green-800";
      case "closed":
        return "bg-gray-100 text-gray-800";
      case "successful":
        return "bg-purple-100 text-purple-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  // Helper function to return the corresponding icon for the campaign status
  const getStatusIcon = (status) => {
    switch (status) {
      case "pending_approval":
        return <FaHourglassHalf className="mr-1 inline-block" />;
      case "approved":
        return <FaCheck className="mr-1 inline-block" />;
      case "rejected":
        return <FaTimes className="mr-1 inline-block" />;
      case "live":
        return <FaPlay className="mr-1 inline-block" />;
      case "closed":
        return <FaLock className="mr-1 inline-block" />;
      case "successful":
        return <FaTrophy className="mr-1 inline-block" />;
      default:
        return null;
    }
  };

  // Use navigate from react-router-dom to view campaign details
  const handleViewDetails = (campSlug) => {
    navigate(`/donation/${campSlug}`);
  };

  const openLikersModal = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setShowLikersModal(true);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-emerald-50">
        <svg
          className="animate-spin h-10 w-10 text-emerald-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
        </svg>
        <p className="mt-4 text-lg text-emerald-600">Loading your donation campaigns...</p>
      </div>
    );
  }

  if (campaigns.length === 0) {
    return (
      <div className="max-w-xl mx-auto p-4 text-center">
        <button
          onClick={onBack}
          className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
        >
          <FaArrowLeft className="mr-1" />
          Back
        </button>
        <div className="bg-white p-6 rounded-md border border-gray-200">
          <h2 className="text-xl font-bold text-gray-800 mb-3">
            You haven't created any donation campaigns yet.
          </h2>
          <p className="text-sm text-gray-600 mb-4">
            Start by creating your very first campaign.
          </p>
          <button
            onClick={onCreateCampaign}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-md border border-gray-200 bg-white text-gray-700 hover:bg-gray-50"
          >
            <FaPlus />
            Create Campaign
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      {showDeleteModal && (
        <DeleteConfirmationModal
          message={`Are you sure you want to delete "${campaignToDelete?.campaignTitle || ""}"?`}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          isDeleting={isDeleting}
        />
      )}

      <button
        onClick={onBack}
        className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
      >
        <FaArrowLeft className="mr-1" />
        Back
      </button>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">My Donation Campaigns</h2>
        <button
          onClick={onCreateCampaign}
          className="inline-flex items-center gap-2 px-4 py-2 rounded-md border border-gray-200 bg-white text-gray-700 hover:bg-gray-50"
        >
          <FaPlus />
          Create Campaign
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {campaigns.map((camp) => {
          const daysLeft = getDaysLeft(camp.endDate);
          const progress = getProgress(camp.fundingGoal, camp.currentAmount);
          const shortDesc =
            camp.campaignDescription?.replace(/<[^>]*>?/gm, "") || "No description";
          const tagsArr = camp.tags ? camp.tags.split(",").map((t) => t.trim()) : [];

          return (
            <div
              key={camp._id}
              className="border border-gray-200 rounded-md bg-gradient-to-r from-emerald-50 to-blue-50 relative cursor-pointer shadow-sm hover:shadow-md transition"
              onClick={() => handleViewDetails(camp.slug)}
            >
              {/* Cover Image */}
              {camp.coverImage ? (
                <img
                  src={camp.coverImage}
                  alt={camp.campaignTitle}
                  className="w-full h-44 object-cover rounded-t-md"
                />
              ) : (
                <div className="w-full h-44 bg-gray-100 flex items-center justify-center text-gray-500 text-sm rounded-t-md">
                  No Image
                </div>
              )}

           
                <div className="absolute top-2 left-2 flex items-center space-x-2 bg-white bg-opacity-90 rounded-full px-2 py-1 shadow-sm">
                  <img
                    src={FOUNDER_PLACEHOLDER}
                    alt="Founder Avatar"
                    className="w-6 h-6 rounded-full object-cover"
                  />
                  <span className="text-xs text-gray-600">
                    {user?.primaryInfo?.firstName || "user"}
                  </span>
                </div>

              {/* Days Left Pill */}
              <div className="absolute top-2 right-2">
                <div
                  className={`h-10 w-10 flex items-center justify-center rounded-full text-xs font-bold shadow ${
                    daysLeft <= 0 ? "bg-red-100 text-red-600" : "bg-emerald-100 text-emerald-700"
                  }`}
                >
                  {daysLeft <= 0 ? "Ended" : `${daysLeft}d`}
                </div>
              </div>

              <div className="p-4 space-y-2">
                <h3 className="text-sm sm:text-base font-semibold text-gray-800 line-clamp-2">
                  {camp.campaignTitle}
                </h3>

                {/* Status Pill with Icon */}
                {camp.status && (
                  <div>
                    <span
                      className={`inline-block text-xs px-2 py-1 rounded-full font-medium ${getStatusBadgeClasses(
                        camp.status
                      )}`}
                    >
                      {getStatusIcon(camp.status)} {/* <-- Icon added here */}
                      {camp.status.replace(/_/g, " ")}
                    </span>
                  </div>
                )}

                <p className="text-xs text-gray-600 line-clamp-2">{shortDesc}</p>

                {tagsArr.length > 0 && (
                  <div className="flex flex-wrap gap-1 mt-1">
                    {tagsArr.map((tag, i) => (
                      <span
                        key={i}
                        className="text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                )}

                <div className="mt-3">
                  <div className="flex justify-between text-xs text-gray-500 mb-1">
                    <span>Raised: ${camp.currentAmount || 0}</span>
                    <span>Goal: ${camp.fundingGoal}</span>
                  </div>
                  <div className="w-full h-2 bg-gray-200 rounded">
                    <div
                      className="h-full bg-emerald-500 rounded"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-3 text-xs text-gray-400">
                  <div className="flex items-center space-x-2">
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        openLikersModal(camp._id);
                      }}
                    >
                      <FaHeart className="text-red-500" />
                      <span>{camp.likeCount || 0}</span>
                    </div>
                    <div className="flex items-center space-x-1">
                      <FaEye />
                      <span>{camp.views || 0}</span>
                    </div>
                  </div>
                  {daysLeft > 0 && (
                    <span className="text-emerald-600 font-semibold">{daysLeft} days left</span>
                  )}
                </div>

                {camp.likeCount > 0 && (
                  <div className="mt-2 bg-white p-2 rounded-lg">
                    <div className="flex -space-x-2 overflow-hidden">
                      {camp.likers &&
                        camp.likers.slice(0, 3).map((liker) => (
                          <img
                            key={liker._id}
                            src={`${process.env.REACT_APP_API_BASE_URL || ""}api/proxy?url=${encodeURIComponent(
                              liker.fakeUser.profilePicture
                            )}`}
                            alt={`${liker.fakeUser.firstName} ${liker.fakeUser.lastName}`}
                            className="inline-block h-6 w-6 rounded-full ring-2 ring-white object-cover"
                          />
                        ))}
                      {camp.likeCount > 3 && (
                        <span className="inline-block h-6 w-6 rounded-full ring-2 ring-white bg-gray-200 text-gray-600 flex items-center justify-center text-xs">
                          +{camp.likeCount - 3}
                        </span>
                      )}
                    </div>
                    <div className="mt-2 flex items-center">
                      <FaUserFriends className="text-emerald-600 mr-1" />
                      <span className="text-xs text-gray-600">
                        Liked by{" "}
                        {camp.likers &&
                          camp.likers.slice(0, 2).map((liker) => liker.fakeUser.firstName).join(", ")}
                        {camp.likeCount > 2 ? " and others" : ""}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openLikersModal(camp._id);
                        }}
                        className="ml-auto text-xs text-blue-500 hover:underline flex items-center"
                      >
                        See All <AiOutlineArrowRight className="ml-1" />
                      </button>
                    </div>
                  </div>
                )}

                {/* Action Buttons (Edit / Delete) */}
                <div className="flex justify-between items-center pt-3 border-t border-gray-100">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditCampaign(camp._id);
                    }}
                    className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
                  >
                    <FaPen className="mr-1" />
                    Edit
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(camp);
                    }}
                    className="text-sm text-red-500 hover:text-red-700 flex items-center"
                  >
                    <FaTrash className="mr-1" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Likers Modal */}
      <DonationCampaignLikesModal
        show={showLikersModal}
        campaignId={selectedCampaignId}
        onClose={() => {
          setShowLikersModal(false);
          setSelectedCampaignId(null);
        }}
      />
    </div>
  );
};

export default MyDonationCampaignList;
