// src/components/crowdfund/BusinessDetails.jsx
import React, { useEffect, useState } from 'react';
import {
  FaArrowLeft,
  FaEdit,
  FaCamera,
  FaFileAlt,
  FaInfoCircle,
  FaImages,
  FaFlag,
  FaMapMarkerAlt,
  FaGlobe,
  FaPhoneAlt,
  FaEnvelope,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';

import { useUser } from '../context';
import api from '../../api';
import DeleteConfirmationModal from './Modals/DeleteConfirmationModal';
import UploadSingleImageModal from './Modals/UploadSingleImageModal';
import UploadGalleryModal from './Modals/UploadGalleryModal';
import UploadDocsModal from './Modals/UploadDocsModal';

// TABS
const TABS = [
  { key: 'about', label: 'About', icon: <FaInfoCircle className="inline-block mr-1" /> },
  { key: 'gallery', label: 'Gallery', icon: <FaImages className="inline-block mr-1" /> },
  { key: 'campaigns', label: 'Campaigns', icon: <FaFlag className="inline-block mr-1" /> },
  { key: 'docs', label: 'Docs', icon: <FaFileAlt className="inline-block mr-1" /> },
];

const BusinessDetails = ({ business, onBack, onEdit, onOpenCampaigns }) => {
  const { user } = useUser();
  const [details, setDetails] = useState(business);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [activeTab, setActiveTab] = useState('about');

  // Cover & Logo modals
  const [showCoverModal, setShowCoverModal] = useState(false);
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [isUploadingCover, setIsUploadingCover] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);

  // Gallery modals
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [isUploadingGallery, setIsUploadingGallery] = useState(false);

  // Docs modals
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [isUploadingDocs, setIsUploadingDocs] = useState(false);

  // Delete confirmations (for gallery image or doc)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [toDeleteType, setToDeleteType] = useState(null); // 'gallery' | 'doc'
  const [toDeleteValue, setToDeleteValue] = useState(null); // imageUrl or docUrl

  useEffect(() => {
    fetchBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBusinessDetails = async () => {
    try {
      setLoadingDetails(true);
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.get(`/api/crowdfund/business/${business._id}`, config);
      setDetails(res.data.data);
    } catch (err) {
      console.error('Error loading business details:', err);
    } finally {
      setLoadingDetails(false);
    }
  };

  // Refresh the page data after successful upload/delete
  const reloadProfile = () => {
    fetchBusinessDetails();
  };

  // ----------------- Cover & Logo -----------------
  const handleEditCover = () => setShowCoverModal(true);
  const handleEditLogo = () => setShowLogoModal(true);

  const uploadCover = async (file) => {
    setIsUploadingCover(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      };
      // ?type=cover triggers resizing to "cover" dimension in the back-end
      await api.post(
        `/api/crowdfund/business/${details._id}/upload-single-image?type=cover`,
        formData,
        config
      );
      setShowCoverModal(false);
      reloadProfile();
    } catch (err) {
      console.error('Error uploading cover:', err);
    } finally {
      setIsUploadingCover(false);
    }
  };

  const uploadLogo = async (file) => {
    setIsUploadingLogo(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      };
      // ?type=logo triggers resizing to "logo" dimension in the back-end
      await api.post(
        `/api/crowdfund/business/${details._id}/upload-single-image?type=logo`,
        formData,
        config
      );
      setShowLogoModal(false);
      reloadProfile();
    } catch (err) {
      console.error('Error uploading logo:', err);
    } finally {
      setIsUploadingLogo(false);
    }
  };

  // ----------------- Gallery -----------------
  const openGalleryModal = () => setShowGalleryModal(true);

  const uploadGalleryImages = async (files) => {
    setIsUploadingGallery(true);
    try {
      const formData = new FormData();
      files.forEach((f) => formData.append('galleryImages', f));
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      };
      await api.post(
        `/api/crowdfund/business/${details._id}/upload-gallery-images`,
        formData,
        config
      );
      setShowGalleryModal(false);
      reloadProfile();
    } catch (err) {
      console.error('Error uploading gallery images:', err);
    } finally {
      setIsUploadingGallery(false);
    }
  };

  const confirmDeleteGalleryImage = (imageUrl) => {
    setToDeleteType('gallery');
    setToDeleteValue(imageUrl);
    setDeleteMessage('Are you sure you want to delete this gallery image?');
    setShowDeleteModal(true);
  };

  // ----------------- Docs -----------------
  const openDocsModal = () => setShowDocsModal(true);

  const uploadDocs = async (files) => {
    setIsUploadingDocs(true);
    try {
      const formData = new FormData();
      files.forEach((f) => formData.append('docs', f));
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      };
      await api.post(
        `/api/crowdfund/business/${details._id}/upload-docs`,
        formData,
        config
      );
      setShowDocsModal(false);
      reloadProfile();
    } catch (err) {
      console.error('Error uploading docs:', err);
    } finally {
      setIsUploadingDocs(false);
    }
  };

  const confirmDeleteDoc = (docUrl) => {
    setToDeleteType('doc');
    setToDeleteValue(docUrl);
    setDeleteMessage('Are you sure you want to delete this document?');
    setShowDeleteModal(true);
  };

  // ----------------- Deletion (Modal) -----------------
  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${user.token}` },
        data: {},
      };
      if (toDeleteType === 'gallery') {
        config.data = { imageUrl: toDeleteValue };
        await api.delete(
          `/api/crowdfund/business/${details._id}/gallery-image`,
          config
        );
      } else if (toDeleteType === 'doc') {
        config.data = { docUrl: toDeleteValue };
        await api.delete(
          `/api/crowdfund/business/${details._id}/doc`,
          config
        );
      }
      reloadProfile();
    } catch (error) {
      console.error('Error deleting item:', error);
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setDeleteMessage('');
      setToDeleteType(null);
      setToDeleteValue(null);
    }
  };

  const handleCancelDelete = () => {
    if (!isDeleting) {
      setShowDeleteModal(false);
      setDeleteMessage('');
      setToDeleteType(null);
      setToDeleteValue(null);
    }
  };

  // ----------------- Tabs / Rendering -----------------
  const handleTabClick = (tabKey) => {
    if (tabKey === 'campaigns') {
      onOpenCampaigns?.();
      return;
    }
    setActiveTab(tabKey);
  };

  const renderTabBar = () => (
    <div className="border-b border-gray-200 mb-2">
      <nav className="flex space-x-4 text-sm">
        {TABS.map((tab) => (
          <button
            key={tab.key}
            onClick={() => handleTabClick(tab.key)}
            className={`pb-2 ${
              activeTab === tab.key
                ? 'text-emerald-600 border-b-2 border-emerald-600'
                : 'text-gray-500 hover:text-gray-700'
            } flex items-center`}
          >
            {tab.icon}
            {tab.label}
          </button>
        ))}
      </nav>
    </div>
  );

  if (loadingDetails) {
    return (
      <div className="p-4 text-center">
        <p className="text-sm text-gray-500">Loading business details...</p>
      </div>
    );
  }

  if (!details) {
    return (
      <div className="text-center p-4">
        <button
          onClick={onBack}
          className="text-sm text-gray-600 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <FaArrowLeft className="mr-1" />
          Back
        </button>
        <p className="text-gray-500">Business not found.</p>
      </div>
    );
  }

  const {
    coverImage,
    companyLogo,
    businessName,
    businessIndustry,
    businessDescription,
    email,
    phone,
    website,
    addressLine1,
    city,
    country,
    status,
    yearFounded,
    numberOfEmployees,
    annualRevenue,
    galleryImages = [],
    registrationDocs = [],
  } = details;

  const renderStatusPill = () => {
    let bg = 'bg-gray-300 text-gray-700';
    let label = 'Draft';
    if (status === 'active') {
      bg = 'bg-green-200 text-green-800';
      label = 'Active';
    } else if (status === 'suspended') {
      bg = 'bg-red-200 text-red-800';
      label = 'Suspended';
    } else if (status === 'closed') {
      bg = 'bg-gray-200 text-gray-600';
      label = 'Closed';
    } else if (status === 'draft') {
      bg = 'bg-yellow-200 text-yellow-800';
      label = 'Draft';
    }
    return (
      <span className={`inline-block px-3 py-1 text-xs font-semibold rounded-full ${bg}`}>
        {label}
      </span>
    );
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-4">
      {/* Modals for cover / logo */}
      {showCoverModal && (
        <UploadSingleImageModal
          title="Upload New Cover Image"
          onUpload={uploadCover}
          onClose={() => setShowCoverModal(false)}
          isUploading={isUploadingCover}
        />
      )}
      {showLogoModal && (
        <UploadSingleImageModal
          title="Upload New Logo"
          onUpload={uploadLogo}
          onClose={() => setShowLogoModal(false)}
          isUploading={isUploadingLogo}
        />
      )}

      {/* Modals for gallery */}
      {showGalleryModal && (
        <UploadGalleryModal
          onUpload={uploadGalleryImages}
          onClose={() => setShowGalleryModal(false)}
          isUploading={isUploadingGallery}
        />
      )}

      {/* Modals for docs */}
      {showDocsModal && (
        <UploadDocsModal
          onUpload={uploadDocs}
          onClose={() => setShowDocsModal(false)}
          isUploading={isUploadingDocs}
        />
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          message={deleteMessage}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}

      {/* Back Button */}
      <button
        onClick={onBack}
        className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
      >
        <FaArrowLeft className="mr-1" />
        Back
      </button>

       {/* Top Alert about Campaigns */}
       <div className="bg-blue-50 border border-blue-200 text-blue-800 p-3 rounded mb-4 text-sm relative">
        <FaInfoCircle className="inline-block mr-2 text-blue-600" />
        <span>
  To view or manage campaigns for this business, click the <strong>"Campaigns" tab</strong> below or use this button .
</span>


        <div className="mt-2">
          <button
            onClick={() => onOpenCampaigns?.()}
            className="inline-flex items-center px-3 py-1 border border-gray-200 bg-white rounded text-gray-700 hover:text-gray-900 text-xs"
          >
            <FaFlag className="mr-1 text-emerald-500" />
            Go to My Campaigns
          </button>
        </div>
      </div>

      {/* Cover Card */}
      <div className="bg-white border border-gray-200 rounded-md mb-4">
        <div className="relative w-full h-40 bg-gray-100">
          {coverImage ? (
            <img src={coverImage} alt="cover" className="w-full h-full object-cover" />
          ) : (
            <div className="w-full h-full flex items-center justify-center text-gray-400 text-sm">
              No Cover
            </div>
          )}
          <button
            onClick={handleEditCover}
            className="absolute top-2 right-2 bg-white bg-opacity-70 p-1 rounded text-xs text-gray-700 hover:bg-opacity-90"
          >
            <FaCamera className="inline-block mr-1" />
            Edit Cover
          </button>
        </div>

        {/* Logo + Name + Status */}
        <div className="flex flex-col sm:flex-row items-center sm:items-start px-4 py-3">
          {/* Logo */}
          <div className="relative -mt-12 sm:-mt-8">
            {companyLogo ? (
              <img
                src={companyLogo}
                alt="Logo"
                className="w-24 h-24 object-cover rounded-full border-4 border-white shadow-md"
              />
            ) : (
              <div className="w-24 h-24 rounded-full border-4 border-white shadow-md bg-gray-300 flex items-center justify-center text-xs text-gray-500">
                No Logo
              </div>
            )}
            <button
              onClick={handleEditLogo}
              className="absolute bottom-1 right-1 bg-white bg-opacity-90 text-xs p-1 rounded hover:bg-opacity-100 shadow"
            >
              <FaEdit />
            </button>
          </div>

          <div className="sm:ml-4 mt-4 sm:mt-0 text-center sm:text-left">
            <h1 className="text-xl font-bold text-gray-800">{businessName}</h1>
            {(city || country) && (
              <div className="flex items-center space-x-2 justify-center sm:justify-start">
                <FaMapMarkerAlt className="text-xs text-gray-500" />
                <span className="text-xs">
                  {addressLine1 && `${addressLine1}, `}{city}, {country}
                </span>
              </div>
            )}
            <div className="mt-1 text-xs">{renderStatusPill()}</div>
          </div>
        </div>
      </div>

      {/* Tab Bar */}
      {renderTabBar()}

      <div className="bg-white border border-gray-200 rounded-md p-4">
        {activeTab === 'about' && (
          <AboutTab
            businessIndustry={businessIndustry}
            email={email}
            phone={phone}
            website={website}
            addressLine1={addressLine1}
            city={city}
            country={country}
            yearFounded={yearFounded}
            numberOfEmployees={numberOfEmployees}
            annualRevenue={annualRevenue}
            businessDescription={businessDescription}
            onEdit={onEdit}
          />
        )}

        {activeTab === 'gallery' && (
          <GalleryTab
            galleryImages={galleryImages}
            onUploadGallery={openGalleryModal}
            onDeleteImage={confirmDeleteGalleryImage}
          />
        )}

        {activeTab === 'docs' && (
          <DocsTab
            registrationDocs={registrationDocs}
            onUploadDoc={openDocsModal}
            onDeleteDoc={confirmDeleteDoc}
          />
        )}

        {/* If user clicked "campaigns", we navigated away via onOpenCampaigns */}
      </div>
    </div>
  );
};

/** --- ABOUT TAB --- */
const AboutTab = ({
  businessIndustry,
  email,
  phone,
  website,
  addressLine1,
  city,
  country,
  yearFounded,
  numberOfEmployees,
  annualRevenue,
  businessDescription,
  onEdit,
}) => {
  return (
    <div className="space-y-4">
      <div className="flex justify-end mb-2">
        <button
          onClick={onEdit}
          className="inline-flex items-center text-gray-500 hover:text-gray-700 text-sm border border-gray-200 px-3 py-1 rounded"
        >
          <FaEdit className="mr-1" />
          Edit All
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-sm">
        <div>
          <p className="font-semibold text-gray-700">Industry</p>
          <p className="text-gray-600">{businessIndustry || '—'}</p>
        </div>
        <div>
          <p className="font-semibold text-gray-700">Year Founded</p>
          <p className="text-gray-600">
            {yearFounded ? new Date(yearFounded).getFullYear() : '—'}
          </p>
        </div>
        <div>
          <p className="font-semibold text-gray-700">Employees</p>
          <p className="text-gray-600">
            {numberOfEmployees || numberOfEmployees === 0
              ? numberOfEmployees
              : '—'}
          </p>
        </div>
        <div>
          <p className="font-semibold text-gray-700">Annual Revenue</p>
          <p className="text-gray-600">
            {annualRevenue ? `$${annualRevenue.toLocaleString()}` : '—'}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <FaEnvelope className="text-gray-500" />
          <span className="text-gray-600">{email || 'No email'}</span>
        </div>
        {phone && (
          <div className="flex items-center space-x-2">
            <FaPhoneAlt className="text-gray-500" />
            <span className="text-gray-600">{phone}</span>
          </div>
        )}
        {website && (
          <div className="flex items-center space-x-2">
            <FaGlobe className="text-gray-500" />
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-blue-600 hover:text-blue-800"
            >
              {website}
            </a>
          </div>
        )}
      </div>

      <div>
        <p className="font-semibold text-gray-700 mb-1">Description</p>
        {businessDescription ? (
          <div
            className="prose prose-sm text-gray-700"
            dangerouslySetInnerHTML={{ __html: businessDescription }}
          />
        ) : (
          <p className="italic text-gray-400">No description</p>
        )}
      </div>
    </div>
  );
};

/** --- GALLERY TAB --- */
const GalleryTab = ({ galleryImages, onUploadGallery, onDeleteImage }) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-lg font-semibold text-gray-800">
          Gallery ({galleryImages.length})
        </h2>
        <button
          onClick={onUploadGallery}
          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
        >
          <FaPlus className="mr-1" />
          Upload +
        </button>
      </div>

      {galleryImages.length === 0 ? (
        <p className="text-gray-500">No gallery images yet.</p>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
          {galleryImages.map((url, idx) => (
            <div key={idx} className="relative group">
              <img
                src={url}
                alt="gallery"
                className="w-full h-32 object-cover rounded"
              />
              <button
                onClick={() => onDeleteImage(url)}
                className="absolute top-1 right-1 text-white bg-black bg-opacity-50 p-1 rounded hidden group-hover:block"
                title="Delete image"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/** --- DOCS TAB --- */
const DocsTab = ({ registrationDocs, onUploadDoc, onDeleteDoc }) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center">
          <FaFileAlt className="mr-2" />
          Documents
        </h2>
        <button
          onClick={onUploadDoc}
          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
        >
          <FaPlus className="mr-1" />
          Upload +
        </button>
      </div>

      {registrationDocs.length === 0 ? (
        <p className="text-gray-500">No documents yet.</p>
      ) : (
        <ul className="list-none text-sm space-y-2">
          {registrationDocs.map((docUrl, idx) => (
            <li key={idx} className="flex items-center justify-between p-2 border-b">
              <a
                href={docUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-blue-600 hover:text-blue-800 truncate max-w-xs"
              >
                Document {idx + 1}
              </a>
              <button
                onClick={() => onDeleteDoc(docUrl)}
                className="inline-flex items-center text-red-500 hover:text-red-700 text-xs"
              >
                <FaTrash className="mr-1" />
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BusinessDetails;
