import React, { useState, useEffect } from 'react';
import { FaSearch, FaFilter, FaUserCheck, FaMapMarkerAlt } from 'react-icons/fa';

const API_KEY = 'pk_live_f485e6385a863246773eefe3babbd65f';

// You can add more causes for variety:
const RANDOM_CAUSE_OPTIONS = [
  'animals',
  'environment',
  'mental-health',
  'arts',
  'education',
  'women',
  'climate',
];

const CommunityDirectory = ({ onUserSelect }) => {
  const [nonprofits, setNonprofits] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCause, setFilterCause] = useState('');
  const [followingState, setFollowingState] = useState({});

  // Pagination / Loading states
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  // Are we searching or browsing?
  const isSearching = searchTerm.trim().length > 0;

  // 1) PICK A RANDOM CAUSE ONCE (when component first mounts)
  //    We'll only use this if filterCause is still empty.
  const [randomInitialCause] = useState(() => {
    const randomIndex = Math.floor(Math.random() * RANDOM_CAUSE_OPTIONS.length);
    return RANDOM_CAUSE_OPTIONS[randomIndex];
  });

  // 2) Fetch nonprofits
  useEffect(() => {
    const fetchNonprofits = async () => {
      const maxRetries = 3; // Maximum number of retry attempts
      const retryDelay = 2000; // Delay between retries in milliseconds
      let attempt = 0;
  
      const fetchData = async () => {
        try {
          let url = '';
  
          if (isSearching) {
            // SEARCH MODE: /search/:searchTerm
            url = `https://partners.every.org/v0.2/search/${encodeURIComponent(
              searchTerm
            )}?apiKey=${API_KEY}&take=10`;
  
            // If user also chose a cause, add it:
            if (filterCause) {
              url += `&causes=${filterCause}`;
            }
  
            // Force page to 1, ignoring pagination
            setPage(1);
            setTotalPages(1);
          } else {
            // BROWSE MODE: /browse/:cause
            // If user hasn't chosen a cause yet, use randomInitialCause
            const causeToUse = filterCause || randomInitialCause;
            url = `https://partners.every.org/v0.2/browse/mental-health?apiKey=${API_KEY}&take=5&page=${page}`;
          }
  
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
  
          if (!isSearching && data.pagination) {
            // BROWSE mode with pagination details
            setTotalPages(data.pagination.pages || 1);
            let fetchedNonprofits = data.nonprofits ?? [];
  
            // OPTIONAL SHUFFLE: if this is the VERY first load
            const isFirstEverLoad = page === 1 && !filterCause && !searchTerm;
            if (isFirstEverLoad) {
              fetchedNonprofits = fetchedNonprofits.sort(() => 0.5 - Math.random());
            }
  
            // If page=1, replace nonprofits; else append
            if (page === 1) {
              setNonprofits(fetchedNonprofits);
            } else {
              setNonprofits((prev) => [...prev, ...fetchedNonprofits]);
            }
          } else {
            // SEARCH mode or fallback
            setNonprofits(data.nonprofits ?? []);
          }
        } catch (error) {
          if (attempt < maxRetries) {
            attempt++;
            console.error(
              `Attempt ${attempt} failed. Retrying in ${retryDelay / 1000} seconds...`,
              error
            );
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            return fetchData(); // Retry
          } else {
            console.error('Max retry attempts reached. Unable to fetch nonprofits:', error);
            setNonprofits([]);
          }
        } finally {
          setLoading(false);
        }
      };
  
      setLoading(true);
      await fetchData();
    };
  
    fetchNonprofits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filterCause, page]);

  // Follow/unfollow (local demonstration only)
  const handleFollowToggle = (ein) => {
    setFollowingState((prev) => ({
      ...prev,
      [ein]: !prev[ein],
    }));
  };

  // Tag color styling
  const getTagStyles = (tag) => {
    const colors = {
      animals: 'bg-yellow-100 text-yellow-800',
      environment: 'bg-green-100 text-green-800',
      'mental-health': 'bg-blue-100 text-blue-800',
      arts: 'bg-pink-100 text-pink-800',
      education: 'bg-purple-100 text-purple-800',
      women: 'bg-red-100 text-red-800',
      climate: 'bg-teal-100 text-teal-800',
    };
    const key = tag.toLowerCase();
    return colors[key] || 'bg-gray-100 text-gray-800';
  };

  // Cover image fallback
  const getCoverImage = (np) => {
    return np.coverImageUrl || 'https://source.unsplash.com/600x200/?charity';
  };

  // Logo fallback
  const getLogoImage = (np) => {
    return np.logoUrl || 'https://via.placeholder.com/48?text=No+Logo';
  };

  // Load more nonprofits (browse mode only)
  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-white px-4 py-6 text-sm">
      <div className="mx-auto max-w-3xl">
      

        {/* Search & Filter */}
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between mb-6">
          {/* Search input */}
          <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden w-full">
            <span className="px-3 text-gray-400">
              <FaSearch />
            </span>
            <input
              type="text"
              className="w-full px-2 py-2 focus:outline-none placeholder-gray-500"
              placeholder="Search nonprofits by name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Cause filter */}
          <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden w-full sm:w-1/2">
            <span className="px-3 text-gray-400">
              <FaFilter />
            </span>
            <select
              className="w-full border-none focus:outline-none px-2 py-2"
              value={filterCause}
              onChange={(e) => setFilterCause(e.target.value)}
            >
              <option value="">All Causes</option>
              <option value="animals">Animals</option>
              <option value="environment">Environment</option>
              <option value="mental-health">Mental Health</option>
              <option value="arts">Arts</option>
              <option value="education">Education</option>
              <option value="women">Women</option>
              <option value="climate">Climate</option>
            </select>
          </div>
        </div>

        {/* Loading Indicator */}
        {loading && <p className="text-center text-gray-500">Loading...</p>}

        {/* Directory List */}
        {!loading && nonprofits.length === 0 ? (
          <p className="text-gray-500 text-center">No nonprofits found.Try another search</p>
        ) : (
          <div className="space-y-6 mt-4">
            {nonprofits.map((np) => {
              const isFollowing = !!followingState[np.ein];
              const coverUrl = getCoverImage(np);
              const logoUrl = getLogoImage(np);

              return (
                <div
                key={np.ein || Math.random()} // Fallback key if no EIN
                onClick={() => onUserSelect && onUserSelect(np)}
                className="cursor-pointer rounded-lg shadow bg-white hover:shadow-lg transition"
              >
                {/* Cover Section */}
                <div className="relative w-full h-32 sm:h-40">
                  <img
                    src={coverUrl}
                    alt="Cover"
                    className="object-cover w-full h-full"
                    onError={(e) => {
                      e.target.src = 'https://via.placeholder.com/600x200?text=No+Cover';
                    }}
                  />
                  {/* Overlaid Profile Picture */}
                  <div className="absolute bottom-0 left-4 transform translate-y-1/2 z-10">
                    <img
                      src={logoUrl}
                      alt={np.name}
                      className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-4 border-white shadow-lg object-cover"
                      onError={(e) => {
                        e.target.src = 'https://randomuser.me/api/portraits/lego/1.jpg';
                      }}
                    />
                  </div>
                </div>
              
                {/* Content Section */}
                <div className="pt-10 px-4 pb-4">
                  {/* Name, Location, Follow */}
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <div className="min-w-0">
                      <h3 className="text-base font-semibold text-gray-800 truncate">
                        {np.name}
                      </h3>
                      {np.location && (
                        <p className="text-xs text-gray-500 flex items-center truncate">
                          <FaMapMarkerAlt className="mr-1 text-gray-400" />
                          {np.location}
                        </p>
                      )}
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFollowToggle(np.ein);
                      }}
                      disabled={isFollowing}
                      className={`mt-2 sm:mt-0 px-3 py-1 rounded text-xs font-medium ${
                        isFollowing
                          ? 'bg-emerald-500 text-white cursor-not-allowed'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      {isFollowing ? (
                        <>
                          <FaUserCheck className="mr-1 inline" />
                          Following
                        </>
                      ) : (
                        'Follow'
                      )}
                    </button>
                  </div>
              
                  {/* Description */}
                  <p className="text-gray-600 text-sm line-clamp-2 mt-3">
                    {np.description}
                  </p>
              
                  {/* Cause Tags */}
                  <div className="flex flex-wrap gap-2 mt-2">
                    {np.tags?.map((tag) => (
                      <span
                        key={tag}
                        className={`text-xs font-medium px-2 py-1 rounded ${getTagStyles(tag)}`}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              
              );
            })}

            {/* "Load More" button – only for browse mode with pagination */}
            {!isSearching && page < totalPages && (
              <div className="text-center mt-4">
                <button
                  onClick={handleLoadMore}
                  className="bg-emerald-500 hover:bg-emerald-600 text-white px-4 py-2 rounded"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityDirectory;
