// src/components/crowdfund/CrowdfundContainer.jsx
import React, { useState } from 'react';
import CrowdfundLanding from './CrowdfundLanding';
import PublicCampaignsGrid from './AllCampaignsList';
import MyBusinessesList from './MyBusinessesList';
import BusinessDetails from './BusinessDetails';
import BusinessWizard from './BusinessWizard';
import MyCampaignList from './MyCampaignList';
import CampaignDetails from './CampaignDetails';
import CampaignWizard from './CampaignWizard';
import PublicCampaignDetails from './PublicCampaignDetails'; 
import MyCampaignEdit from './MyCampaignEdit'; 

const CrowdfundContainer = () => {
  const [activeView, setActiveView] = useState('landing');
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [editCampaignId, setEditCampaignId] = useState(null);

  // Switch to Edit mode
  const handleEdit = (campaignId) => {
    setEditCampaignId(campaignId);
    setActiveView("edit");
  };

  // Cancel editing => return to list
  const handleCancelEdit = () => {
    setEditCampaignId(null);
    setActiveView("myBusinessCampaigns");
  };

  return (
    <div className="min-h-[600px] bg-gradient-to-r from-emerald-50 to-emerald-100  rounded-md">
      {activeView === 'landing' && (
        <CrowdfundLanding
          onExploreCampaigns={() => setActiveView('allCampaigns')}
          onGoToMyCampaigns={() => setActiveView('myBusinesses')}
        />
      )}

      {/* Public Campaigns */}
      {activeView === 'allCampaigns' && (
        <PublicCampaignsGrid
          onBack={() => setActiveView('landing')}
          onViewCampaignDetails={(camp) => {
            setSelectedCampaign(camp);
            setActiveView('publicCampaignDetails');
          }}
        />
      )}

      {/* Public Campaign Details */}
      {activeView === 'publicCampaignDetails' && selectedCampaign && (
        <PublicCampaignDetails
          campaignId={selectedCampaign._id}
          onBack={() => setActiveView('allCampaigns')}
        />
      )}

      {/* My Businesses */}
      {activeView === 'myBusinesses' && (
        <MyBusinessesList
          onBackToLanding={() => setActiveView('landing')}
          onSelectBusiness={(biz) => {
            setSelectedBusiness(biz);
            setActiveView('businessDetails');
          }}
          onCreateNewBusiness={() => {
            setSelectedBusiness(null);
            setActiveView('businessWizard');
          }}
        />
      )}

      {/* Business Details */}
      {activeView === 'businessDetails' && selectedBusiness && (
        <BusinessDetails
          business={selectedBusiness}
          onBack={() => setActiveView('myBusinesses')}
          onEdit={() => setActiveView('businessWizard')}
          onOpenCampaigns={() => setActiveView('myBusinessCampaigns')}
        />
      )}

      {/* My Business Campaigns */}
      {activeView === 'myBusinessCampaigns' && selectedBusiness && (
        <MyCampaignList
          businessId={selectedBusiness._id}
          onBack={() => setActiveView('businessDetails')}
          onCreateCampaign={() => setActiveView('campaignWizard')}
          onViewCampaign={(camp) => {
            setSelectedCampaign(camp);
          }}
          onEditCampaign={handleEdit}  // ✅ Corrected edit function
        />
      )}

      {/* My Campaign Edit (Fixed the rendering issue) */}
      {activeView === 'edit' ? (
        <MyCampaignEdit
          campaignId={editCampaignId}
          onCancel={handleCancelEdit}
        />
      ) : null}

      {/* Business Wizard */}
      {activeView === 'businessWizard' && (
        <BusinessWizard
          existingBusiness={selectedBusiness}
          onCancel={() => setActiveView('myBusinesses')}
          onSave={() => setActiveView('myBusinesses')}
        />
      )}

      {/* My Campaign Details */}
      {activeView === 'campaignDetails' && selectedCampaign && (
        <CampaignDetails
          campaignId={selectedCampaign._id}
          onBack={() => setActiveView('myBusinessCampaigns')}
          onEditCampaign={(camp) => {
            setSelectedCampaign(camp);
            setActiveView('campaignWizard');
          }}
          onDeleteCampaignSuccess={() => setActiveView('myBusinessCampaigns')}
        />
      )}

      {/* Campaign Wizard */}
      {activeView === 'campaignWizard' && (
        <CampaignWizard
          existingCampaign={selectedCampaign}
          onCancel={() => setActiveView('myBusinessCampaigns')}
          onSuccess={(saved) => {
            setSelectedCampaign(saved);
            setActiveView('myBusinessCampaigns');
          }}
        />
      )}
    </div>
  );
};

export default CrowdfundContainer;
