/**
 * PurchasePage.js
 * Main container with tab navigation & state for the "Offer" tab.
 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import {
  FaSpinner,
  FaShieldAlt,
  FaRegStar,
  FaComments,
  FaInfoCircle
} from 'react-icons/fa';

import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';
import { useUser } from "../context";

// Import each tab component
import TradeOffer from './TradeOffer';
import TradeReviews from './TradeReviews';
import TradeDiscussion from './TradeDiscussion';
import TradeAbout from './TradeAbout';

const PurchasePage = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  // States for the Offer tab
  const [points, setPoints] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingExchangeRate, setLoadingExchangeRate] = useState(true);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [exchangeRateError, setExchangeRateError] = useState(false);

  // Tab state
  const [activeTab, setActiveTab] = useState('offer');

  // On mount, fetch exchange rate
  useEffect(() => {
    const fetchRate = async () => {
      setLoadingExchangeRate(true);
      setExchangeRateError(false);
      try {
        const response = await api.get('https://api.fastforex.io/fetch-one', {
          params: {
            from: 'USD',
            to: 'KES',
            api_key: '487cebeb50-43c24a368b-scb4hf'
          },
          headers: { accept: 'application/json' }
        });

        if (response.data?.result) {
          setExchangeRate(response.data.result.KES);
        }
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
        setExchangeRateError(true);
        setExchangeRate(1); // fallback
      } finally {
        setLoadingExchangeRate(false);
      }
    };
    fetchRate();
  }, []);

  // Handler for starting trade
  const handleBuyNowClick = async () => {
    if (!points || points <= 0) {
      toast.error("Please enter a valid amount of points to buy.");
      return;
    }
    setLoading(true);

    try {
      const amount = Math.round(points * exchangeRate); // points -> KES
      const response = await api.post(
        '/api/trade/start',
        { points, amount },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success('Trade started successfully! Redirecting...');
        const tradeId = response.data.tradeId;
        setTimeout(() => {
          navigate(`/chat/${tradeId}`);
        }, 5000);
      } else {
        throw new Error('Failed to initiate trade. Please try again.');
      }
    } catch (error) {
      if (error.response?.status === 409) {
        // Ongoing trade conflict
        const tradeId = error.response.data.tradeId;
        toast.info('You have an ongoing trade. Redirecting...');
        setTimeout(() => {
          navigate(`/chat/${tradeId}`);
        }, 5000);
      } else {
        toast.error(
          'Error starting trade: ' +
          (error.response?.data?.message || error.message)
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // The list of tabs
  const tabs = [
    { label: 'Offer', value: 'offer', icon: <FaShieldAlt /> },
    { label: 'Reviews', value: 'reviews', icon: <FaRegStar /> },
    //{ label: 'Discussion', value: 'discussion', icon: <FaComments /> },
    { label: 'About', value: 'about', icon: <FaInfoCircle /> }
  ];

  // Decide which tab to render
  const renderTabContent = () => {
    switch (activeTab) {
      case 'offer':
        return (
          <TradeOffer
            points={points}
            setPoints={setPoints}
            loading={loading}
            loadingExchangeRate={loadingExchangeRate}
            exchangeRate={exchangeRate}
            exchangeRateError={exchangeRateError}
            handleBuyNowClick={handleBuyNowClick}
            navigate={navigate}
          />
        );
      case 'reviews':
        return <TradeReviews />;
      case 'discussion':
        return <TradeDiscussion />;
      case 'about':
        return <TradeAbout />;
      default:
        return <TradeOffer />;
    }
  };

  return (
    <div className="bg-emerald-50 min-h-screen flex flex-col items-center pt-4 px-2 sm:px-4 pb-20">
      {/* Toast notifications */}
      <ToastContainer position="top-center" />

     {/* Alert Box - Important Notice About Sellers */}
{/* Combined Alert Section */}
<div className="max-w-4xl w-full my-3">
  <div
    className="bg-yellow-50 border border-yellow-300 text-yellow-800 px-4 py-3 rounded text-xs"
    role="alert"
  >
    {/* Important Notice Section */}
    <p className="font-semibold mb-1">⚠️ Important Notice: Stay Safe When Trading!</p>
    <ul className="list-disc ml-4 space-y-1">
      <li>
        Always trade with <strong>verified sellers</strong> to ensure your transactions are protected by their <strong>security deposit</strong>.
      </li>
      <li>
        Be cautious when dealing with <strong>non-verified sellers</strong>. Verdant Charity <strong>cannot guarantee</strong> the safety of funds in such cases.
      </li>
      <li>
        Double-check that the seller’s name matches the account receiving the payment. If there’s a mismatch, <strong>cancel the transaction</strong> immediately and <strong>reach out to support</strong> for assistance.
      </li>
      <li>
        Please note that we <strong>do not endorse</strong> or support trades conducted <strong>outside</strong> our platform.
      </li>
    </ul>

    {/* Dotted Separator */}
    <div className="border border-dotted border-gray-300 my-4 p-3">

    <ul className="list-disc ml-4 space-y-1">
      <li>
        Scammers will offer you deals that are significantly <strong>cheaper than the current market rates</strong> to lure you in.
      </li>
      <li>
        Scammers often contact you via <strong>WhatsApp</strong> and try to convince you <strong>not to trade with verified sellers</strong>, claiming they have cheaper deals.
      </li>
      <li>
        Scammers may <strong>pretend to be Verdant Charity support agents</strong> to gain your trust.
      </li>
      <li>
        Scammers will apply pressure tactics to make you <strong>send money quickly</strong> without verifying their authenticity.
      </li>
    </ul>

    <p className="font-bold mt-3 text-center">STAY AWAY FROM SCAMS! BE VIGILANT.</p>
    </div>
  </div>
</div>


      {/* Page Title */}
      <div className="max-w-4xl w-full text-center">
        <h1 className="text-2xl font-extrabold text-emerald-600 mb-2">
          Purchase Points
        </h1>
        <p className="text-xs sm:text-sm text-gray-600">
          Get the best deals on your points from verified sellers
        </p>
      </div>

      {/* Tab Navigation */}
      <div className="max-w-4xl w-full mt-4 border-b border-gray-300 px-4">
        <div className="flex space-x-4 overflow-x-auto pb-1">
          {tabs.map((tab) => (
            <button
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              className={`relative text-sm font-semibold px-3 py-2 transition whitespace-nowrap ${activeTab === tab.value
                  ? 'text-emerald-600 border-b-2 border-emerald-500'
                  : 'text-gray-600 hover:text-emerald-600'
                }`}
            >
              <span className="inline-flex items-center">
                {tab.icon}
                <span className="ml-1">{tab.label}</span>
              </span>
            </button>
          ))}
        </div>
      </div>

      {/* Render the selected tab content */}
      {renderTabContent()}
    </div>
  );
};

export default PurchasePage;
