import React, { useState, useCallback } from "react";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

/* ======================= Helper Formatting Functions ======================= */
const formatSsn = (val = "") => {
  const digits = val.replace(/\D/g, "").slice(0, 9);
  if (digits.length > 5) return digits.replace(/^(\d{3})(\d{2})(\d+)/, "$1-$2-$3");
  if (digits.length > 3) return digits.replace(/^(\d{3})(\d+)/, "$1-$2");
  return digits;
};
const formatSin = (val = "") => {
  const digits = val.replace(/\D/g, "").slice(0, 9);
  if (digits.length > 6) return digits.replace(/^(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
  if (digits.length > 3) return digits.replace(/^(\d{3})(\d+)/, "$1-$2");
  return digits;
};
const formatTfn = (val = "") => {
  let digits = val.replace(/\D/g, "");
  digits = digits.slice(0, 9);
  if (digits.length > 6) return digits.replace(/^(\d{3})(\d{3})(\d+)/, "$1 $2 $3");
  if (digits.length > 3) return digits.replace(/^(\d{3})(\d+)/, "$1 $2");
  return digits;
};
const formatNi = (val = "") => {
  let cleaned = val.toUpperCase().replace(/\s+/g, "");
  cleaned = cleaned.slice(0, 9);
  if (cleaned.length > 2) {
    return cleaned.slice(0, 2) + " " + cleaned.slice(2);
  }
  return cleaned;
};

/* ======================= Universal TextSearch Autocomplete =======================
   For any field that is "address-like" or "state" or "city" or "postalCode" etc., 
   we can replace a normal <input> with <TextSearchAutocomplete>. 
   It calls Google's Places Text Search endpoint with an API key.
*/
const TextSearchAutocomplete = ({
  name,
  value,
  onChange,
  placeholder,
 
}) => {
  const [query, setQuery] = useState(value || "");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const  googleApiKey = 'AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE';

  // Fire a search on each keystroke (with some minor debounce in a real app)
  const handleInputChange = async (e) => {
    const inputVal = e.target.value;
    setQuery(inputVal);
    onChange({ target: { name, value: inputVal } }); // update parent state

    // Only search if 2+ characters
    if (inputVal.trim().length < 2) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const resp = await axios.get(
        "https://maps.googleapis.com/maps/api/place/textsearch/json",
        {
          params: {
            query: inputVal,
            key: googleApiKey,
          },
        }
      );
      if (resp.data && resp.data.results) {
        // We'll store each result's "name" or "formatted_address"
        // Text Search might have "name" + "formatted_address"
        const nextSuggestions = resp.data.results.map((item) => ({
          id: item.place_id,
          primaryText: item.name || "",
          secondaryText: item.formatted_address || "",
        }));
        setSuggestions(nextSuggestions);
        setShowDropdown(true);
      }
    } catch (err) {
      console.error("Text Search error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = useCallback(
    (suggestion) => {
      // We'll combine name + address for final, or just use one or the other
      const finalVal =
        suggestion.primaryText && suggestion.secondaryText
          ? `${suggestion.primaryText}, ${suggestion.secondaryText}`
          : suggestion.primaryText || suggestion.secondaryText;

      setQuery(finalVal);
      setSuggestions([]);
      setShowDropdown(false);
      onChange({ target: { name, value: finalVal } });
    },
    [name, onChange]
  );

  return (
    <div className="relative">
      <input
        type="text"
        name={name}
        className="w-full text-sm p-2 border border-emerald-300/50 rounded bg-transparent focus:border-emerald-300 focus:ring-1 focus:ring-emerald-200"
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
        onFocus={() => {
          if (suggestions.length > 0) setShowDropdown(true);
        }}
        onBlur={() => {
          // small delay to allow click
          setTimeout(() => setShowDropdown(false), 150);
        }}
      />
      {loading && (
        <div className="absolute top-0 right-1 h-full flex items-center text-emerald-600">
          <FaSpinner className="animate-spin mr-2" />
        </div>
      )}
      {showDropdown && suggestions.length > 0 && (
        <div className="absolute z-10 bg-emerald-50/80 border border-emerald-300/50 rounded mt-1 w-full overflow-hidden text-emerald-800 text-xs">
          {suggestions.map((sugg) => (
            <div
              key={sugg.id}
              onClick={() => handleSelect(sugg)}
              className="cursor-pointer p-2 hover:bg-emerald-200/50 break-words"
            >
              {sugg.primaryText ? (
                <div className="font-medium">{sugg.primaryText}</div>
              ) : null}
              <div>{sugg.secondaryText}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/* =========================== STEP 0: DISCLAIMER =========================== */
export const DisclaimerStep = ({ onAccept }) => {
  return (
    <div className="p-4 flex flex-col items-center bg-emerald-50/40 rounded border border-dotted border-emerald-400/40 text-sm">
      <h2 className="text-md font-bold mb-3 text-center text-emerald-700">
        Welcome to Verdant Charity’s Global KYC
      </h2>
      <p className="text-emerald-800 text-xs text-center max-w-2xl mb-6 leading-relaxed">
        We take your privacy seriously. Your personal details are kept secure
        using high-grade encryption and used{" "}
        <strong className="text-emerald-700">only</strong> for identity
        verification and compliance with local regulations.
        <br />
        By continuing, you acknowledge and consent to our data protection
        practices and the necessity of identity documents.
      </p>
      <button
        onClick={onAccept}
        className="border border-emerald-500/50 text-emerald-700 bg-emerald-100/20 px-6 py-1.5 rounded-md hover:bg-emerald-100/40 transition text-sm"
      >
        I Accept &amp; Continue
      </button>
    </div>
  );
};

/* =========================== STEP 1: PERSONAL INFO =========================== */
export const PersonalInfoStep = ({
  kyc,
  handleChange,
  onNext,
  onBack,
  error,
  setError,
  googleApiKey,
}) => {
  return (
    <div className="max-w-xl w-full mx-auto bg-emerald-50/20 p-4 rounded border border-emerald-400/40 text-sm">
      <h2 className="text-md font-semibold mb-2 text-emerald-700">
        Personal Information
      </h2>
      <p className="text-xs text-emerald-700 mb-4 leading-normal">
        We only need your basic personal details here. You’ll provide address and
        ID in the next step.
      </p>

      {/* First Name */}
      <div className="mb-3">
        <label className="block text-xs font-medium text-emerald-800 mb-1">
          First Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="firstName"
          value={kyc.firstName}
          onChange={handleChange}
          placeholder="e.g. John"
          className="w-full text-sm p-2 border border-emerald-300/50 rounded bg-transparent focus:border-emerald-300 focus:ring-1 focus:ring-emerald-200"
        />
      </div>

      {/* Last Name */}
      <div className="mb-3">
        <label className="block text-xs font-medium text-emerald-800 mb-1">
          Last Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="lastName"
          value={kyc.lastName}
          onChange={handleChange}
          placeholder="e.g. Doe"
          className="w-full text-sm p-2 border border-emerald-300/50 rounded bg-transparent focus:border-emerald-300 focus:ring-1 focus:ring-emerald-200"
        />
      </div>

      {/* Date of Birth */}
      <div className="mb-3">
        <label className="block text-xs font-medium text-emerald-800 mb-1">
          Date of Birth <span className="text-red-500">*</span>
        </label>
        <input
          type="date"
          name="dob"
          value={kyc.dob}
          onChange={handleChange}
          className="w-full text-sm p-2 border border-emerald-300/50 rounded bg-transparent focus:border-emerald-300 focus:ring-1 focus:ring-emerald-200"
        />
      </div>

      {/* Phone (READ-ONLY) */}
      <div className="mb-3">
        <label className="block text-xs font-medium text-emerald-800 mb-1">
          Phone Number (auto-filled)
        </label>
        <input
          type="text"
          name="phone"
          value={kyc.phone}
          readOnly
          className="w-full text-sm p-2 border border-emerald-300/30 rounded bg-emerald-100/30 text-emerald-600 cursor-not-allowed"
        />
      </div>

      {/* Email (READ-ONLY) */}
      <div className="mb-3">
        <label className="block text-xs font-medium text-emerald-800 mb-1">
          Email (auto-filled)
        </label>
        <input
          type="text"
          name="email"
          value={kyc.email}
          readOnly
          className="w-full text-sm p-2 border border-emerald-300/30 rounded bg-emerald-100/30 text-emerald-600 cursor-not-allowed"
        />
      </div>

      <div className="flex items-center justify-between mt-5">
        <button
          onClick={onBack}
          className="border border-emerald-500/50 text-emerald-700 bg-emerald-100/20 px-4 py-1.5 rounded hover:bg-emerald-100/40 transition text-sm"
        >
          Back
        </button>
        <button
          onClick={() => {
            setError("");
            onNext();
          }}
          className="border border-emerald-600/60 text-emerald-800 bg-emerald-50/30 px-5 py-1.5 rounded hover:bg-emerald-100/40 transition text-sm"
        >
          Next
        </button>
      </div>

      {/* Inline error if desired */}
      {error && (
        <div className="mt-3 p-2 text-xs text-red-600 bg-red-50 border border-red-300 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

/* =========================== STEP 2: ADDRESS & ID =========================== */
export const AddressAndIdStep = ({
  kyc,
  handleChange,
  onNext,
  onBack,
  error,
  setError,
  countryConfig,
  googleApiKey,
}) => {
  const step1Fields = ["firstName", "lastName", "dob", "phone", "email"];
  const step2Fields = countryConfig.fields.filter(
    (field) => !step1Fields.includes(field.name)
  );

  // ID-specific onChange
  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    if (name === "ssn") value = formatSsn(value);
    if (name === "sin") value = formatSin(value);
    if (name === "taxFileNumber") value = formatTfn(value);
    if (name === "nationalInsurance") value = formatNi(value);
    handleChange({ target: { name, value } });
  };

  return (
    <div className="max-w-xl w-full mx-auto bg-emerald-50/20 p-4 rounded border border-emerald-400/40 text-sm">
      <h2 className="text-md font-semibold mb-2 text-emerald-700">
        KYC for {countryConfig.label}
      </h2>
      <p className="text-xs text-emerald-700 mb-4 leading-normal">
        Provide your <strong>address</strong> and necessary <strong>ID</strong> details.
        We use Google <strong>Text Search</strong> so you can type states, addresses,
        or partial city names, and we’ll show suggestions.
      </p>

      {step2Fields.map((field) => {
        const readOnly = ["phone", "email", "countryCode"].includes(field.name);
        const required = countryConfig.required.includes(field.name);

        // We'll treat any of these as "location-like" inputs that get text search
        // (streetAddress, city, region, state, postalCode, etc.)
        const isLocationInput = [
          "streetAddress",
          "city",
          "state",
          "region",
          "postalCode",
        ].includes(field.name);

        // If it's "location-like," we show our custom <TextSearchAutocomplete />
        if (isLocationInput) {
          return (
            <div key={field.name} className="mb-3">
              <label className="block text-xs font-medium text-emerald-800 mb-1">
                {field.label}
                {required && <span className="text-red-500"> *</span>}
              </label>
              <TextSearchAutocomplete
                name={field.name}
                value={kyc[field.name] || ""}
                onChange={(e) => {
                  // pass changes up
                  handleChange(e);
                }}
                placeholder={field.placeholder || ""}
                googleApiKey={googleApiKey}
              />
              {field.explanation && (
                <p className="text-emerald-700 text-xs mt-1">{field.explanation}</p>
              )}
            </div>
          );
        }

        // If it's an ID field that needs formatting
        if (
          ["ssn", "sin", "taxFileNumber", "nationalInsurance"].includes(field.name)
        ) {
          return (
            <div key={field.name} className="mb-3">
              <label className="block text-xs font-medium text-emerald-800 mb-1">
                {field.label}
                {required && <span className="text-red-500"> *</span>}
              </label>
              <input
                type="text"
                name={field.name}
                placeholder={field.placeholder || ""}
                value={kyc[field.name] || ""}
                onChange={handleFieldChange}
                readOnly={readOnly}
                className={`w-full text-sm p-2 border rounded bg-transparent focus:border-emerald-300 focus:ring-1 focus:ring-emerald-200 ${
                  readOnly
                    ? "border-emerald-300/30 text-emerald-600 cursor-not-allowed"
                    : "border-emerald-300/50"
                }`}
              />
              {field.explanation && (
                <p className="text-emerald-700 text-xs mt-1">{field.explanation}</p>
              )}
            </div>
          );
        }

        // Otherwise normal text input
        return (
          <div key={field.name} className="mb-3">
            <label className="block text-xs font-medium text-emerald-800 mb-1">
              {field.label}
              {required && <span className="text-red-500"> *</span>}
            </label>
            <input
              type={field.name === "dob" ? "date" : "text"}
              name={field.name}
              placeholder={field.placeholder || ""}
              value={kyc[field.name] || ""}
              onChange={handleChange}
              readOnly={readOnly}
              className={`w-full text-sm p-2 border rounded bg-transparent focus:border-emerald-300 focus:ring-1 focus:ring-emerald-200 ${
                readOnly
                  ? "border-emerald-300/30 text-emerald-600 cursor-not-allowed"
                  : "border-emerald-300/50"
              }`}
            />
            {field.explanation && (
              <p className="text-emerald-700 text-xs mt-1">{field.explanation}</p>
            )}
          </div>
        );
      })}

      <div className="flex items-center justify-between mt-5">
        <button
          onClick={onBack}
          className="border border-emerald-500/50 text-emerald-700 bg-emerald-100/20 px-4 py-1.5 rounded hover:bg-emerald-100/40 transition text-sm"
        >
          Back
        </button>
        <button
          onClick={() => {
            setError("");
            onNext();
          }}
          className="border border-emerald-600/60 text-emerald-800 bg-emerald-50/30 px-5 py-1.5 rounded hover:bg-emerald-100/40 transition text-sm"
        >
          Next
        </button>
      </div>

      {error && (
        <div className="mt-3 p-2 text-xs text-red-600 bg-red-50 border border-red-300 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

/* =========================== STEP 3: REVIEW =========================== */
export const ReviewStep = ({
  kyc,
  onSubmit,
  onBack,
  isSubmitting,
  error,
  countryConfig,
}) => {
  const handleSubmitClick = () => onSubmit();

  const priorityOrder = ["firstName", "lastName", "dob", "phone", "email"];
  const relevantFields = [...countryConfig.fields.map((f) => f.name), ...priorityOrder];
  const uniqueFields = Array.from(new Set(relevantFields)).sort((a, b) => {
    const iA = priorityOrder.indexOf(a);
    const iB = priorityOrder.indexOf(b);
    if (iA !== -1 && iB !== -1) return iA - iB;
    if (iA !== -1) return -1;
    if (iB !== -1) return 1;
    return 0;
  });

  return (
    <div className="max-w-xl w-full mx-auto bg-emerald-50/20 p-4 rounded border border-emerald-400/40 text-sm">
      <h2 className="text-md font-semibold mb-2 text-emerald-700">
        Review Your Details
      </h2>
      <p className="text-xs text-emerald-700 mb-4 leading-normal">
        Please confirm all information below is accurate before submitting.
      </p>
      <div className="border border-dotted border-emerald-400/50 p-3 rounded bg-emerald-50/10 text-xs">
        {uniqueFields.map((fieldName) => {
          if (!kyc[fieldName]) return null;
          return (
            <p key={fieldName} className="mb-1 text-emerald-900">
              <strong className="text-emerald-700">{fieldName}:</strong> {kyc[fieldName]}
            </p>
          );
        })}
      </div>

      <div className="flex items-center justify-between mt-5">
        <button
          onClick={onBack}
          className="border border-emerald-500/50 text-emerald-700 bg-emerald-100/20 px-4 py-1.5 rounded hover:bg-emerald-100/40 transition text-sm"
        >
          Back
        </button>
        <button
          onClick={handleSubmitClick}
          disabled={isSubmitting}
          className="border border-emerald-600/60 text-emerald-800 bg-emerald-50/30 px-5 py-1.5 rounded hover:bg-emerald-100/40 transition text-sm flex items-center justify-center"
        >
          {isSubmitting && <FaSpinner className="animate-spin mr-2 text-emerald-600" />}
          Submit
        </button>
      </div>

      {error && (
        <div className="mt-3 p-2 text-xs text-red-600 bg-red-50 border border-red-300 rounded">
          {error}
        </div>
      )}
    </div>
  );
};
