import React, { useEffect } from 'react';
import './App.css';
import { UserProvider } from "./components/context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from './components/AppLayout';
import { SocketProvider } from './SocketContext';

function App() {
  const CURRENT_UI_VERSION = "1.0.39"; // Update this version when releasing a new UI

  useEffect(() => {
    const storedVersion = localStorage.getItem("ui_version");

    if (storedVersion !== CURRENT_UI_VERSION) {
      // Update the stored version to the new one
      localStorage.setItem("ui_version", CURRENT_UI_VERSION);

      // Reload the page to load the new UI
      window.location.reload();
    }
    // If versions match, no action is taken
  }, []); // Runs once when the app loads

  return (
    <UserProvider>
      <SocketProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<AppLayout />} />
          </Routes>
        </BrowserRouter>
      </SocketProvider>
    </UserProvider>
  );
}

export default App;
