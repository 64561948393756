// File: DonationCampaignUserLanding.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHandsHelping, FaDonate } from 'react-icons/fa';

const DonationCampaignUserLanding = ({ onGoToMyCampaigns }) => {
  const navigate = useNavigate();

  // Debugging: wrap the onGoToMyCampaigns callback
  const handleMyCampaigns = () => {
    console.log("My Campaigns button clicked");
    if (typeof onGoToMyCampaigns === 'function') {
      onGoToMyCampaigns();
    } else {
      console.error("onGoToMyCampaigns prop is not provided or is not a function");
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-10 px-4 text-center">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        Welcome to Our Donation Platform
      </h1>
      <p className="text-gray-600 max-w-2xl mx-auto mb-8 leading-relaxed">
        Where compassion meets action. Whether you want to launch your own donation campaign or explore impactful causes, we’re here to help.
      </p>

      <div className="flex flex-col sm:flex-row justify-center gap-4">
        <button
          onClick={handleMyCampaigns}
          className="inline-flex items-center justify-center px-6 py-3 rounded-md border border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow-md transition-transform transform hover:translate-y-[-2px]"
        >
          <FaHandsHelping className="mr-2 text-emerald-500" />
          My Campaigns
        </button>

        <button
          onClick={() => {
            console.log("Explore Campaigns button clicked");
            navigate('/donations');
          }}
          className="inline-flex items-center justify-center px-6 py-3 rounded-md border border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow-md transition-transform transform hover:translate-y-[-2px]"
        >
          <FaDonate className="mr-2 text-emerald-500" />
          Explore Campaigns
        </button>
      </div>
    </div>
  );
};

export default DonationCampaignUserLanding;
