// Sidebar.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AiOutlineCopy,
  AiOutlineDashboard,
  AiOutlineWallet,
  AiOutlineGift,
  AiOutlineTeam,
  AiOutlineIdcard,
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineAppstore,
  AiOutlineFund,
  AiOutlineDollarCircle,
} from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { useUser } from './context';

const Sidebar = ({ changeComponent }) => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('donationsSummary');
  const [copied, setCopied] = useState(false);

  const payId = user?.payId;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(payId).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleClick = (componentName) => {
    setActiveComponent(componentName);
    changeComponent(componentName);
  };

  const baseClassName = 'flex items-center space-x-3 cursor-pointer';

  return (
    <div
      className="bg-white bg-opacity-20 backdrop-blur-lg p-4 rounded-lg mx-4 lg:mx-8 lg:h-full lg:w-64 mt-4 md:mt-8 lg:mt-16 shadow-xl font-harmattan font-12"
      style={{
        backgroundImage:
          'url(https://res.cloudinary.com/dx6jw8k0m/image/upload/v1722085487/world_moving_with_effects_xxlkuu.gif)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundBlendMode: 'overlay',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      }}
    >
      {/* Pay ID Display */}
      <div className="mb-6 p-4 bg-green-50 rounded-lg shadow-sm border border-green-200 text-green-700 flex justify-between items-center">
        <div className="flex-grow">
          <div className="text-xs font-semibold uppercase tracking-wide">
            Pay ID: <span className="ml-2 font-normal">{payId}</span>
          </div>
          {copied && <div className="text-xs text-blue-800">Copied!</div>}
        </div>
        <button
          onClick={copyToClipboard}
          className="ml-4 flex-shrink-0 flex items-center justify-center text-green-600 hover:text-green-800 transition-colors duration-150"
        >
          <AiOutlineCopy className="text-lg" />
        </button>
      </div>

      <nav className="flex flex-col space-y-3">
        {/* Dashboard */}
        <div
          onClick={() => handleClick('donationsSummary')}
          className={`${baseClassName} ${activeComponent === 'donationsSummary'
            ? 'p-2 rounded-md text-emerald-700 bg-emerald-100'
            : 'text-gray-700'
            }`}
        >
          <AiOutlineDashboard className="text-emerald-500 text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Dashboard</span>
        </div>
        {/* Wallet */}
        <Link
          to="/wallet"
          onClick={() => handleClick('wallet')}
          className={`${baseClassName} ${activeComponent === 'wallet' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineWallet className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Wallet</span>
        </Link>

        {/* Update Profile */}
        <div
          onClick={() => handleClick('profile')}
          className={`${baseClassName} ${activeComponent === 'profile' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineUser className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Profile</span>
       
        </div>
        {/* My Donations */}
        <div
          onClick={() => handleClick('myDonations')}
          className={`${baseClassName} ${activeComponent === 'myDonations' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineGift className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Donations</span>
          <span
            className="bg-blue-500 text-white text-[8px] px-1 py-0.1 rounded-full relative"
            style={{ top: '-4px' }}
          >
            updated
          </span>
        </div>
  

        <div
          onClick={() => handleClick('crowdfunding')}
          className={`${baseClassName} ${activeComponent === 'crowdfunding' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineFund className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Crowdfunding</span>
          <span
            className="bg-red-500 text-white text-[8px] px-1 py-0.1 rounded-full relative"
            style={{ top: '-4px' }}
          >
            New
          </span>
        </div>
        {/* Grants 
        <div
          onClick={() => handleClick('grants')}
          className={`${baseClassName} ${activeComponent === 'grants' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineDollarCircle className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Grants</span>
        </div>
        */}

        {/* Volunteer Activities */}
        <div
          onClick={() => handleClick('volunteerActivities')}
          className={`${baseClassName} ${activeComponent === 'volunteerActivities' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineTeam className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Volunteer Activities</span>
        
        </div>
        {/* Membership */}
        <div
          onClick={() => handleClick('membership')}
          className={`${baseClassName} ${activeComponent === 'membership' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineIdcard className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Membership</span>
        </div>


        {/* community */}
        <div
          onClick={() => handleClick('community')}
          className={`${baseClassName} ${activeComponent === 'community' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <FaUsers className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Community</span>
        
        </div>
        {/* Campaigns */}
        <div
          onClick={() => handleClick('campaigns')}
          className={`${baseClassName} ${activeComponent === 'campaigns' ? 'text-emerald-500' : 'text-gray-700'
            }`}
        >
          <AiOutlineAppstore className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Campaigns</span>

        </div>

        {/* Logout */}
        <div onClick={handleLogout} className={baseClassName}>
          <AiOutlineLogout className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Logout</span>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
