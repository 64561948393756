// CrowdfundPaymentModal.jsx
import React, { useState } from 'react';
import {
  FaCreditCard,
  FaMobileAlt,
  FaShieldAlt,
  FaMoneyCheckAlt,
  FaSpinner,
} from 'react-icons/fa';

const CrowdfundPaymentModal = ({
  show,
  onClose,
  campaign,
  amount,
  setAmount,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  // Extra payment details
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvv, setCvv] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  // Error states for each field
  const [amountError, setAmountError] = useState('');
  const [cardNameError, setCardNameError] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [globalError, setGlobalError] = useState('');

  // Payment status: "idle" | "processing" | "error"
  const [paymentStatus, setPaymentStatus] = useState('idle');

  if (!show) return null;

  const resetPayment = () => {
    setPaymentStatus('idle');
    setGlobalError('');
  };

  const handleSubmitPayment = () => {
    // Clear previous errors
    setAmountError('');
    setCardNameError('');
    setCardNumberError('');
    setExpiryError('');
    setCvvError('');
    setMobileNumberError('');
    setGlobalError('');

    let hasError = false;

    // Validate amount
    if (!amount || parseFloat(amount) <= 0) {
      setAmountError('Please enter a valid amount.');
      hasError = true;
    }

    // Validate payment method selection
    if (!selectedPaymentMethod) {
      setGlobalError('Please select a payment method.');
      hasError = true;
    }

    // Validate fields based on selected payment method
    if (selectedPaymentMethod === 'mpesa') {
      if (!mobileNumber) {
        setMobileNumberError('Mobile number is required.');
        hasError = true;
      }
      // You could add a regex check for a proper phone format here.
    } else if (
      selectedPaymentMethod === 'stripe' ||
      selectedPaymentMethod === 'flutterwave'
    ) {
      if (!cardName) {
        setCardNameError('Cardholder name is required.');
        hasError = true;
      }
      if (!cardNumber) {
        setCardNumberError('Card number is required.');
        hasError = true;
      } else if (!/^\d{12,19}$/.test(cardNumber.replace(/\s+/g, ''))) {
        setCardNumberError('Invalid card number.');
        hasError = true;
      }
      if (!expiry) {
        setExpiryError('Expiry date is required.');
        hasError = true;
      } else if (!/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(expiry)) {
        setExpiryError('Invalid expiry date. Use MM/YY format.');
        hasError = true;
      }
      if (!cvv) {
        setCvvError('CVV is required.');
        hasError = true;
      } else if (!/^\d{3,4}$/.test(cvv)) {
        setCvvError('Invalid CVV.');
        hasError = true;
      }
    }

    if (hasError) return;

    // Start processing payment
    setPaymentStatus('processing');

    // Simulate a network delay (e.g., 3 seconds) then fail the transaction
    setTimeout(() => {
      setPaymentStatus('error');
    }, 3000);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      {/* Modal container with max-height and scrollable content */}
      <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-6 relative max-h-[90vh] overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl"
          aria-label="Close payment modal"
        >
          &times;
        </button>
        <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
          Complete your Payment
        </h2>
        {/* Amount Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Amount (USD)
          </label>
          <input
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
            autoComplete="off"
          />
          {amountError && (
            <span className="text-red-500 text-xs mt-1 block">{amountError}</span>
          )}
        </div>
        {/* Payment Method Selection */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Choose Payment Method
          </label>
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setSelectedPaymentMethod('stripe')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg border ${
                selectedPaymentMethod === 'stripe'
                  ? 'border-emerald-500 bg-emerald-50'
                  : 'border-gray-200'
              } hover:border-emerald-500 transition`}
            >
              <FaCreditCard className="text-emerald-600" />
              Card
            </button>
            <button
              type="button"
              onClick={() => setSelectedPaymentMethod('mpesa')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg border ${
                selectedPaymentMethod === 'mpesa'
                  ? 'border-emerald-500 bg-emerald-50'
                  : 'border-gray-200'
              } hover:border-emerald-500 transition`}
            >
              <FaMobileAlt className="text-emerald-600" />
              M‑Pesa
            </button>
            <button
              type="button"
              onClick={() => setSelectedPaymentMethod('flutterwave')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg border ${
                selectedPaymentMethod === 'flutterwave'
                  ? 'border-emerald-500 bg-emerald-50'
                  : 'border-gray-200'
              } hover:border-emerald-500 transition`}
            >
              <FaMoneyCheckAlt className="text-emerald-600" />
              Flutterwave
            </button>
          </div>
          {globalError && (
            <span className="text-red-500 text-xs mt-1 block">{globalError}</span>
          )}
        </div>
        {/* Payment Details (Conditional) */}
        {selectedPaymentMethod && (
          <div className="mb-4">
            {selectedPaymentMethod === 'mpesa' ? (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Mobile Number
                </label>
                <input
                  type="text"
                  placeholder="e.g., +2547XXXXXXXX"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className="w-full border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  autoComplete="tel"
                />
                {mobileNumberError && (
                  <span className="text-red-500 text-xs mt-1 block">
                    {mobileNumberError}
                  </span>
                )}
              </div>
            ) : (
              <>
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Cardholder Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name on card"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    className="w-full border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    autoComplete="cc-name"
                  />
                  {cardNameError && (
                    <span className="text-red-500 text-xs mt-1 block">
                      {cardNameError}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Card Number
                  </label>
                  <input
                    type="text"
                    placeholder="XXXX XXXX XXXX XXXX"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                    className="w-full border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    autoComplete="cc-number"
                  />
                  {cardNumberError && (
                    <span className="text-red-500 text-xs mt-1 block">
                      {cardNumberError}
                    </span>
                  )}
                </div>
                <div className="flex space-x-2">
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Expiry Date
                    </label>
                    <input
                      type="text"
                      placeholder="MM/YY"
                      value={expiry}
                      onChange={(e) => setExpiry(e.target.value)}
                      className="w-full border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                      autoComplete="cc-exp"
                    />
                    {expiryError && (
                      <span className="text-red-500 text-xs mt-1 block">
                        {expiryError}
                      </span>
                    )}
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      CVV
                    </label>
                    <input
                      type="text"
                      placeholder="CVV"
                      value={cvv}
                      onChange={(e) => setCvv(e.target.value)}
                      className="w-full border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                      autoComplete="cc-csc"
                    />
                    {cvvError && (
                      <span className="text-red-500 text-xs mt-1 block">
                        {cvvError}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {/* Anonymous Option */}
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="anonymous"
            checked={isAnonymous}
            onChange={(e) => setIsAnonymous(e.target.checked)}
            className="h-4 w-4 text-emerald-600 border-gray-300 rounded focus:ring-emerald-500"
          />
          <label htmlFor="anonymous" className="ml-2 text-sm text-gray-700">
            Donate anonymously
          </label>
        </div>
        {/* Security Information */}
        <div className="mb-4 p-3 bg-gray-50 border border-gray-200 rounded-lg flex items-center">
          <FaShieldAlt className="text-green-600 mr-2" />
          <p className="text-xs text-gray-600">
            Your payment details are secure. We do not store your card details.
          </p>
        </div>
        {/* Global Payment Error Message */}
        {paymentStatus === 'error' && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600">
            We couldn&apos;t charge you at the moment. Please try again later.
          </div>
        )}
        {/* Submit Button */}
        <button
          onClick={handleSubmitPayment}
          disabled={paymentStatus === 'processing'}
          className="w-full flex justify-center items-center gap-2 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold py-2 rounded-lg transition disabled:opacity-50"
        >
          {paymentStatus === 'processing' && (
            <FaSpinner className="animate-spin h-5 w-5 text-white" />
          )}
          {paymentStatus === 'processing' ? 'Processing...' : `Pay $${amount}`}
        </button>
        {/* Try Again Button */}
        {paymentStatus === 'error' && (
          <button
            onClick={resetPayment}
            className="mt-2 w-full bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 rounded-lg transition"
          >
            Try Again
          </button>
        )}
      </div>
    </div>
  );
};

export default CrowdfundPaymentModal;
