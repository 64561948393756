// SignUpSeedling.jsx
import React from 'react';

const SignUpSeedling = ({ className = 'w-6 h-6', fill = '#22C55E' }) => (
  <svg className={className} viewBox="0 0 64 64" fill="none">
    <path
      d="M32 58C46 58 56 48 56 34C56 16 32 6 32 6C32 6 8 16 8 34C8 48 18 58 32 58Z"
      fill={fill}
    />
    <path
      d="M32 28L36 24L40 28L36 32L32 28Z"
      fill="#FDE047"
    />
    <path
      d="M24 34L28 30L32 34L28 38L24 34Z"
      fill="#86EFAC"
    />
    <path
      d="M40 34L44 30L48 34L44 38L40 34Z"
      fill="#86EFAC"
    />
  </svg>
);

export default SignUpSeedling;