import React from 'react';
import { FaBars } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdAttachMoney,
  MdFavorite,
  MdSecurity,
  MdStore,
  MdPeople,
  MdCampaign,
  MdPeopleOutline,
  MdPayment,
  MdGavel,
  MdInfo,
  MdTrendingUp,  // Icon for Crowdfund category
  MdSchool       // Icon for Grants category
} from 'react-icons/md';

const CategoriesOverview = ({ categories, onSelectCategory, sidebarOpen, setSidebarOpen, loading }) => {
  // Icon mapping for categories including the new "crowdfund" and "grants" entries
  const categoryIconMapping = {
    "accounts-and-settings": MdAccountCircle,
    "funds-and-transactions": MdAttachMoney,
    "donations-and-giving": MdFavorite,
    "security-and-fraud": MdSecurity,
    "marketplace-and-listings": MdStore,
    "volunteering-and-activities": MdPeople,
    "campaigns-and-fundraising": MdCampaign,
    "community-and-connections": MdPeopleOutline,
    "transfers-and-payments": MdPayment,
    "taxes-and-compliance": MdGavel,
    "about-us": MdInfo,
    "crowdfund": MdTrendingUp,
    "grants": MdSchool,
  };

  // Show a loading spinner if the categories are still being fetched.
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-emerald-50">
        <svg
          className="animate-spin h-10 w-10 text-emerald-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z" />
        </svg>
        <p className="mt-4 text-lg text-emerald-600">Loading categories...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 to-white flex flex-col pb-20">
      {/* Header */}
      <header className="sticky top-0 left-0 right-0 bg-white/70 backdrop-blur-sm w-full px-4 md:px-6 py-8 flex items-center justify-between shadow">
        {/* Hamburger menu (mobile) */}
        <button
          className="md:hidden text-gray-700 flex items-center"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <FaBars />
        </button>
        <h1 className="text-lg font-extrabold text-green-800">All Categories</h1>
      </header>

      {/* Categories grid or "Not Found" UI */}
      <main className="flex-1 p-4 md:p-6 lg:p-8">
        {categories.length === 0 ? (
          <div className="flex flex-col items-center justify-center min-h-[50vh] bg-emerald-50 rounded-md p-8">
            <svg
              className="w-16 h-16 text-emerald-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13l3 3L22 4M2 12a10 10 0 0110-10h1a10 10 0 010 20h-1a10 10 0 01-10-10z" />
            </svg>
            <h2 className="mt-4 text-2xl font-bold text-emerald-700">No Categories Found</h2>
            <p className="mt-2 text-gray-600">
              We could not find any help categories. Please try again later.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {categories.map((category) => {
              // Get the icon for the category or fallback to FaBars
              const IconComponent = categoryIconMapping[category.slug] || FaBars;
              return (
                <div
                  key={category._id}
                  onClick={() => onSelectCategory(category._id)}
                  className="border border-green-200 bg-white/70 backdrop-blur-md rounded-xl p-5 shadow-md transition transform hover:scale-105 hover:shadow-lg cursor-pointer"
                >
                  <div className="flex items-center mb-3">
                    <div className="flex items-center justify-center w-10 h-10 bg-green-100/50 rounded-full">
                      <IconComponent className="text-green-700 text-xl" />
                    </div>
                    <h2 className="ml-4 text-lg font-semibold text-gray-800">
                      {category.name}
                    </h2>
                  </div>
                  <p className="text-sm text-gray-600">
                    Explore topics related to <b>{category.name}</b> and find answers quickly.
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </main>
    </div>
  );
};

export default CategoriesOverview;
