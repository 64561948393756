// src/components/crowdfund/CrowdfundLanding.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLightbulb, FaHandHoldingUsd } from 'react-icons/fa';

const CrowdfundLanding = ({ onGoToMyCampaigns }) => {
  const navigate = useNavigate();

  return (
    <div className="max-w-4xl mx-auto py-10 px-4 text-center">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        Welcome to Our Crowdfunding Space
      </h1>
      <p className="text-gray-600 max-w-2xl mx-auto mb-8 leading-relaxed">
        Where ideas turn into reality through the power of community! 
        Whether you want to launch your own campaign or explore 
        innovative projects from others, we’ve got you covered.
      </p>

      <div className="flex flex-col sm:flex-row justify-center gap-4">
        <button
          onClick={onGoToMyCampaigns}
          className="inline-flex items-center justify-center px-6 py-3 rounded-md border border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow-md transition-transform transform hover:translate-y-[-2px]"
        >
          <FaLightbulb className="mr-2 text-emerald-500" />
          My Campaigns
        </button>

        <button
          onClick={() => navigate('/crowdfund')} // Navigate to /crowdfund
          className="inline-flex items-center justify-center px-6 py-3 rounded-md border border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow-md transition-transform transform hover:translate-y-[-2px]"
        >
          <FaHandHoldingUsd className="mr-2 text-emerald-500" />
          Explore Campaigns
        </button>
      </div>
    </div>
  );
};

export default CrowdfundLanding;
